.season-chart {
    position: relative;
    margin-bottom: rem-calc($gutter-medium);

    @media #{$small-only} {
        margin-left: rem-calc(-16px);
        margin-right: rem-calc(-16px);
    }

    // Overwrite default bootstrap behaviour
    .row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    // Overwrite default bootstrap behaviour
    [class^="col-"],
    [class*=" col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.season-chart__loader {
    padding: rem-calc($gutter-medium);
    background-color: $color-lighter-grey;
}

.season-chart__chart {
    display: none;

    .col-xs-3 {
        @media #{$small-only} {
            width: 25vw;
        }
    }

    &.is-loaded {
        display: block;
    }

    &.has-scroll-left::before,
    &.has-scroll-right::after {
        opacity: 1;
    }

    &::before,
    &::after {
        @media #{$small-only} {
            z-index: 20;
            background: linear-gradient(90deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%);
            mix-blend-mode: multiply;
            content: '';
            width: rem-calc(8px);
            height: 100%;
            position: absolute;
            top: 0;
            opacity: 0;
            transition: opacity $animation-time-default $animation-easing-default;
        }
    }

    &:before {
        @media #{$small-only} {
            left: 25vw;
        }
    }

    &::after {
        @media #{$small-only} {
            right: 0;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
    }
}

.season-chart__months-header,
.season-chart__vertical-grid,
.season-chart__list {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none;
    }
}

.season-chart__months-header {
    position: sticky;
    top: rem-calc(50px); // Small to Large header height
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0;
    z-index: 18;

    .no-header &,
    .foobyapp & {
        top: 0;
    }

    .row {
        @media #{$small-only} {
            width: rem-calc(64px * 12);
        }
    }

    @media #{$xlarge-up} {
        top: rem-calc(60px); // Xlarge header height
    }
}

.season-chart__month-label,
.season-chart__months-header-left-cell {
    @extend %small-monterrat;
    display: flex;
    height: rem-calc(50px);
    align-items: center;
}

.season-chart__months-header-left-cell,
.season-chart__months-header-right-cell,
.season-chart__vertical-grid-left,
.season-chart__vertical-grid-right {
    flex-shrink: 0;
}

.season-chart__months-header-right-cell {
    @media #{$small-only} {
        width: auto;
    }
}

.season-chart__month-label {
    justify-content: center;

    @media #{$small-only} {
        width: rem-calc(64px);
    }
}

.season-chart__list-item-title,
.season-chart__months-header-left-cell {
    position: sticky;
    z-index: 1;
    left: 0;
}

.season-chart__months-header-left-cell,
.season-chart__months-header-right-cell {
    background-color: $color-lighter-grey;
}

.season-chart__months-header-right-cell .row,
.season-chart__vertical-grid-right {
    @media #{$small-only} {
        display: grid;
        grid-auto-flow: column;
    }
}

.season-chart__vertical-grid,
.season-chart__list-item .row,
.season-chart__months-header .row {
    display: flex;
}

.season-chart__vertical-grid {
    position:  absolute;
    top: 0;
    left:  0;
    right:  0;
    bottom:  0;

    .vertical-grid {
        @media #{$small-only} {
            width: rem-calc(64px);
        }
    }
}

$list-item-padding: rem-calc(4px);

.season-chart .season-chart__list-item-title,
.season-chart .season-chart__months-header-left-cell {
    padding-left: rem-calc(16px);
}

.season-chart__list-item-title {
    @extend %small-monterrat;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: rem-calc(48px);
    padding-right: $list-item-padding;
    flex-shrink: 0;
    background-color: $color-white;
    z-index: 7;

    @media #{$medium-up} {
        min-height: rem-calc(60px);
    }

    // Fixate height on large displaye, this fixes an IE11 Bug
    @media #{$large-up} {
        height: rem-calc(60px);
    }
}

.season-chart__list-item {
    @media #{$small-only} {
        width: calc(25vw + #{rem-calc(64px * 12)});
    }
}

.season-chart__list-item {
    background-color: $color-white;

    &:nth-child(2n),
    &:nth-child(2n) .bar-grid,
    &:nth-child(2n) .season-chart__list-item-title {
        background-color: $color-light-grey;
    }
}

.season-chart__link {
    display: block;
    color: $color-dark-stone-grey;
    transition: color $animation-time-default $animation-easing-default;

    &:hover {
        color: $color-desaturated-green;
    }
}

.season-chart__color-bar {
    border-radius: rem-calc(30px);
    height: rem-calc(10px);
    background-position: top left;
}
