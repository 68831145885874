/* HELPERS
##################################################################################################################### */

// http://stackoverflow.com/questions/17045132/scrollbar-overlay-in-ie10-how-do-you-stop-that
// Should prevent IE from overlapping transparent scrollbar
// sass-lint:disable-all
@-ms-viewport{ width: auto !important; }
/* scss-lint:enable all */


// disable outline when navigating with mouse. (used by  what-input.js)
/* mouse */
[data-whatinput='mouse'] :focus {
    outline: none;
}

// Disables scrolling to the attached element
// @group helper-general
.h-no-scroll {
    overflow: hidden;
}

// Bottom Space
// @group helper-general
.h-0space {
    margin-bottom: 0;
}

// Bottom Space
// @group helper-general
.h-halfspace {
    margin-bottom: .5em;
}

// Bottom Space
// @group helper-general
.h-space-1 {
    margin-bottom: 1em;
}

// Bottom Space
// @group helper-general
.h-space-2 {
    margin-bottom: 2em;
}

// Bottom Space
// @group helper-general
.h-space-3 {
    margin-bottom: 3em;
}

// Bottom Space
// @group helper-general
.h-space-4 {
    margin-bottom: 4em;
}

// Alignment
// @group helper-general
.h-table {
    display: table;
}

// Alignment
// @group helper-general
.h-table-cell {
    display: table-cell;
}

// Alignment
// @group helper-general
// sass-lint:disable-all
.h-align-bottom {
    vertical-align: bottom !important;
}
/* scss-lint:enable all */

// Alignment
// @group helper-general
.h-tx-center {
    text-align: center;
}

// Alignment
// @group helper-general
.h-tx-right {
    text-align: right;
}

// Visability
// @group helper-general
.h-hide {
    display: none;
}

.h-hide-small {
    @media #{$small-only} {
        display: none;
    }
}

.h-hide-medium-up {
    @media #{$medium-up} {
        display: none;
    }
}

// Grid Helpers
.h-horizontal-guttered {
    margin-bottom: rem-calc($gutter-small);

    > *:last-child {
        margin-bottom: 0;
    }

    @media #{$medium-up} {
        margin-bottom: rem-calc($gutter-medium);
    }
}

// Colors
.bg-dark-stone-grey {
    background-color: $color-dark-stone-grey;
}

.bg-dark-stone-grey--faded {
    background-color: rgba($color-dark-stone-grey, .8);
}

.color-white {
    color: $color-white;
}

// Width
.full-width {
    width: 100%;
}

// Lists
.no-bullets {
    ul {
        list-style: none;
        padding: 0;

        li {
            border-bottom: 1px solid $color-light-grey;
        }
    }
}

// Media Object
//.media {
//    //margin: 10px;
//}

.media,
.bd {
    overflow: hidden;
    padding-top: rem-calc(1px);
}

.media .img {
    float: left;
    margin-right: 10px;

    img {
        display: block;
    }
}
