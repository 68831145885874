/* COMPONENT Functions Group
##################################################################################################################### */

.functions-group {
    @extend %inline-list;
    text-align: center;
    line-height: 1;
    padding-bottom: 0;

    @media #{$large-up} {
        padding-bottom: rem-calc(18px);
    }

    /* has to be here because of extend */
    .functions-group__item {
        display: block;
        float: none;
        line-height: rem-calc(18px);

        &:not(:last-child) {
            margin-right: 0;
            padding-bottom: rem-calc(10px);

            @media #{$medium-up} {
                margin-right: rem-calc(35px);
                padding-bottom: 0;
            }
        }

        @media #{$medium-up} {
            line-height: rem-calc(24px);
            display: inline-block;
            padding-bottom: 0;

            &:not(:last-child) {
                margin-right: rem-calc(40px);
            }
        }
    }
}
