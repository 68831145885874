/* COMPONENT Newsletter Overlay
##################################################################################################################### */

.newsletter-form {
    width: 100%;

    .newsletter-selection {
        margin-bottom: rem-calc(24px);
    }
}
