.filter-select {
    margin-bottom: rem-calc($gutter-medium);
}

.filter-select__label {
    white-space: nowrap;
    vertical-align: top;
    color: $color-stone-grey;
    font-size: rem-calc(11px);
    line-height: (15 / 11);
    margin-bottom: rem-calc(10px);
    font-weight: 300;
    text-align: left;
    font-family: $font-family-open-sans;
    text-transform: uppercase;
}

.filter-select__list {
    @extend %unstyled-list;

    &.is-collapsed {
        .filter-select__item:not(.is-active) {
            height: 0;
        }
    }
}

.filter-select__item {
    @extend %filter-select-item;
    display: flex;
    justify-content: space-between;

    &.is-active {
        color: $color-desaturated-green;

        .filter-select__icon {
            opacity: 1;
        }
    }
}

.filter-select__icon {
    opacity: 0;

    .svg-fill {
        fill: $color-desaturated-green;
    }
}

.filter-select__line {
    height: 1px; // Substitutes border, so no rem value
    background-color: $color-light-grey;
    margin-left: rem-calc(-$container-padding-small);
    margin-right: rem-calc(-$container-padding-small);
    margin-top: rem-calc($gutter-medium / 2);

}
