/* COMPONENT Quote
##################################################################################################################### */

.quote {
    overflow: hidden;
}

.quote_copy {
    font-size: rem-calc(28px);
    line-height: rem-calc(36px);
    margin-bottom: rem-calc(10px);
    font-family: $font-family-roboto;
    position: relative;
    display: block;

    &::before {
        content: "«";
        position: absolute;
        left: rem-calc(3px);
    }

    &::after {
        content: "»";
    }

    @media #{$medium-up} {
        font-size: rem-calc(36px);
        line-height: rem-calc(48px);

        &::before {
            left: 0;
        }
    }
}

.quote__author {
    margin-bottom: 0;
}

.quote_copy,
.quote__author {
    padding-left: rem-calc(18px);
}

@media #{$medium-up} {
    .quote--big {
        max-width: 83.3%;
    }
}
