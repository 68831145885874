.meat-section {
    margin-top: rem-calc(50px);

    @media #{$medium-up} {
        margin-top: rem-calc(70px);
    }

    &__separator {
        position: relative;
        width: calc(100% - 32px);
        margin: 0 rem-calc(16px);
        margin-bottom: rem-calc(42px);

        @media #{$medium-up} {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__separator-line {
        width: 100%;
        height: 1px;
        background-color: $color-dark-offwhite;
    }

    &__separator-image {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 0;
        background-color: $color-white;
        padding: 0 rem-calc(14px);
    }

    &__header {
        text-align: center;

        @media #{$medium-up} {
            width: 60%;
            margin: 0 auto;
        }
    }

    &__title {
        font-size: rem-calc(45px);
        line-height: 1.1;
        margin: 0;

        @media #{$medium-up} {
            font-size: rem-calc(55px);
        }
    }

    &__subtitle {
        font-family: $font-family-roboto;
        font-size: rem-calc(18px);
        line-height: 1.35;
        margin-top: rem-calc(13px);
    }

    &__parts {
        margin-top: rem-calc(47px);

        @media #{$medium-up} {
            margin-top: rem-calc(82px);
        }
    }

    .meat:not(:first-child) {
        margin-top: rem-calc(65px);

        @media #{$medium-up} {
            margin-top: rem-calc(100px);
        }
    }

    .meat:last-child {
        margin-bottom: rem-calc(65px);

        @media #{$medium-up} {
            margin-bottom: rem-calc(95px);
        }
    }

    .meat-section-wrapper:last-child & .meat:last-child {
        margin-bottom: 0;
    }
}

.meat-section-wrapper:first-child {
    margin-top: rem-calc(30px);
}
