/* MIXINS
##################################################################################################################### */

// https://lukyvj.github.io/family.scss/

// Basic breakpoint
// @group helper-general
// @param {string} $point - minimum px - i.e. '600px'
// @return {string}
// @output @media screen and (min-width: '600px') {}

@mixin breakpoint-min($point) {
    @media screen and (min-width: $point) {
        @content;
    }
}

@function rem-calc($size) {
    $remsize: $size / 16px;
    @return #{$remsize}rem;
}

@function calc-em($size) {
    $remsize: $size / 16px;
    @return #{$remsize}em;
}

@function calc-ratio($width, $height) {
    $ratio: 100 / $width * $height;
    @return $ratio + 0%;    // https://css-tricks.com/snippets/sass/correctly-adding-unit-number/
}

@mixin appearance($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

@mixin round-img-container {
    overflow: hidden;
    border-radius: 100%;

    img {
        width: 100%;
    }
}

@mixin clearfix {
    &::after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
    }
}

//- Mixin: Fluid Property
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $prop [font-size] - Property to make fluid
/// @param {variable} $min [12px] - Minimum size
/// @param {variable} $max [24px] - Maximum size
/// @param {variable} $lower-range [420px] - Stop scaling size smaller at this screen resolution
/// @param {variable} $upper-range [900px] - Stop scaling size larger at this screen resolution
/// @example
///   h1 {
///     @include fluid-pro(20px, 48px);
///   }

@mixin fluid-prop($prop: 'font-size', $min: 12px, $max: 21px, $lower-range: $screen-sm, $upper-range: $screen-lg) {
    #{$prop}: calc(#{$min} + #{(($max / ($max * 0 + 1)) - ($min / ($min * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));

    @media screen and (max-width: $lower-range) {
        #{$prop}: $min;
    }

    @media screen and (min-width: $upper-range){
        #{$prop}: $max;
    }
}

@mixin ratio-config($config, $element) {
    @if $config == "teaser" {

    } @else if $config == "image" {
        #{$element}--initial-columns-3,
        #{$element}--initial-columns-4,
        #{$element}--initial-columns-6,
        #{$element}--initial-columns-8,
        #{$element}--initial-columns-12 {
            #{$element}__placeholder {
                padding-top: calc-ratio(430, 555);
            }
        }

        // ratios tablet
        @media #{$medium-up} {
            #{$element}--initial-columns-3 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(411, 362);
                }
            }

            #{$element}--initial-columns-4 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(262, 362);
                }
            }

            #{$element}--initial-columns-6 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(411, 362);
                }
            }

            #{$element}--initial-columns-8 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(562, 362);
                }
            }

            #{$element}--initial-columns-12 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(862, 550);
                }
            }
        }

        // ratios desktop
        @media #{$large-up} {
            #{$element}--initial-columns-3 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(262, 420);
                }
            }

            #{$element}--initial-columns-4 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(360, 420);
                }
            }

            #{$element}--initial-columns-6 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(555, 420);
                }
            }

            #{$element}--initial-columns-8 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(748, 420);
                }
            }

            #{$element}--initial-columns-12 {
                #{$element}__placeholder {
                    padding-top: calc-ratio(1138, 638);
                }
            }
        }
    }
}

@mixin banner {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: inline-block;
    color: $color-white;
    font-family: "Roboto Slab";
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 14px;
    padding: 8px 15px;
    background-color: $color-fooby-orange;
    text-transform: uppercase;
    white-space: nowrap;
}


@mixin floating-title($position: absolute, $top: 0, $left: 50%, $transform: translate(-50%, -50%), $margin: 0 auto) {
    position: $position;
    top: $top;
    left: $left;
    transform: $transform;
    margin: $margin;
}


@keyframes icon-fill-up {
    0% {
        transform: translateY(0%);
    }

    40% {
        transform: translateY(-58%);
    }

    62% {
        transform: translateY(-68%);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes icon-anim {
    0% {
        transform: scale(1);
    }

    60% {
        transform: scale(1);
    }

    80% {
        transform: scale(1.15);
    }

    92% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}

@mixin icon-fill-anim($animate-icon-itself:true, $anim-duration: .7s) {

    @if $animate-icon-itself {
        animation-name: icon-anim;
        animation-duration: $anim-duration;
        animation-timing-function: $animation-easing-default;
    }
}

@mixin visuallyhidden() {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }