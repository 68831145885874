/* COMPONENT accordion
##################################################################################################################### */

.accordion {
    position: relative;
}

.accordion__section {
    position: relative;
}

.accordion__trigger {
    position: relative;
    cursor: pointer;

    // sass-lint:disable-all
    > svg {
        display: block !important;  // TODO: must be a better solution...
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) scale(1, 1);
        transition: all .2s ease-in-out;
    }
    /* scss-lint:enable all */
}

.accordion__trigger--active {
    > svg {
        transform: translateY(-50%) scale(1, -1);
    }
}

.accordion__content {
    position: relative;
    height: 0;
    z-index: -1;
    //transition: all .3s ease-in-out, z-index .01s linear .26s, height .01s linear .26s;
    opacity: 0;
    //transform: translateY(-2%) scale(.999);
}

.accordion__content--open {
    height: 100%;
    z-index: auto;
    opacity: 1;
    //transform: translateY(0) scale(1);
    //transition: all .3s ease-in-out .05s, height .01s linear;
}
