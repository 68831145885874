/* COMPONENT Newsletter
##################################################################################################################### */

.newsletter__title {
    @extend %footer-section-title;
    margin-bottom: rem-calc(18px);
    //font-size: rem-calc(20px);
    //line-height: 1.25;
    //font-weight: bold;
    //letter-spacing: -.04rem;

    @media #{$xlarge-up} {
        margin-bottom: rem-calc(12px);
        //font-size: rem-calc(24px);
    }
}

.newsletter__text {
    margin-bottom: rem-calc(20px);
    font-size: rem-calc(14px);
    line-height: 1.475;

    @media #{$medium-up} {
        width: (100% / 12 * 11);
    }

    @media #{$xlarge-up} {
        //font-size: rem-calc(14px);
        line-height: 1.475;
    }
}

// Newsletter form mini grid
.newsletter__form {
    @include clearfix();
}

.newsletter__form-input-cell {
    margin-bottom: rem-calc(10px);

    > input {
        width: 100%;
        border-color: $color-white;
    }

    @media #{$medium-up}{
        width: (100% / 12 * 5);
        margin-right: rem-calc(10px);
        float: left;
    }

    @media #{$xlarge-up}{
        margin-right: 0;
        width: 100%;
        float: none;
    }
}

.newsletter__form-submit-cell {
    .btn {
        width: 100%;
    }

    @media #{$medium-up} {
        float: left;
    }

    @media #{$xlarge-up} {
        display: inline-block;
        float: none;
    }
}
