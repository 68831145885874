.notes {
    position: relative;
    background-color: $color-light-grey;
    border-top: 1px solid $color-dark-offwhite;
    border-bottom: 1px solid $color-dark-offwhite;
    margin-left: rem-calc(-$container-padding-small);
    margin-right: rem-calc(-$container-padding-small);
    margin-bottom: rem-calc(25px);
    padding: rem-calc(17px) rem-calc($container-padding-small);

    @media #{$medium-up} {
        margin-left: 0;
        margin-right: 0;
        border-left: 1px solid $color-dark-offwhite;
        border-right: 1px solid $color-dark-offwhite;
    }

    &__title {
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-bottom: rem-calc(7px);
        padding-right: rem-calc(32px);
    }

    &__title-text {
        margin-left: rem-calc(7px);
    }

    &__actions {
        position: absolute;
        top: rem-calc(14px);
        right: rem-calc(14px);
    }

    &__action {
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem-calc(12px);
        }
    }
}

.notes-open-button {
    &__exists {
        display: none;
    }

    &__missing {
        display: inline;
    }

    &.has-note &__exists {
        display: inline;
    }

    &.has-note &__missing {
        display: none;
    }
}
