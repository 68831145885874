/* TEMPLATE C4.3 Teaser Group Feed
##################################################################################################################### */

.teaser-group-feed {
    [data-button] {
        display: none;
    }

    .filter-bar__filter-l2 {
        @media #{$medium-up} {
            margin-bottom: rem-calc(22px);
        }
    }

    &__intro-title {
        margin-bottom: rem-calc(22px);
    }

    &__intro-text {
        margin: 0 rem-calc(20px) rem-calc(24px);

        @media #{$medium-up} {
            margin-bottom: rem-calc(38px);
        }
    }

    &__ingredient-list {
        display: flex;
        margin: 0;
        padding: 0;
        margin: 0 rem-calc(-5px);
    }

    &__ingredient-item {
        position: relative;
        height: rem-calc(50px);
        display: flex;
        flex-grow: 1;
        flex-basis: 0;
        background-color: $color-lighter-grey;
        border: 2px dashed #D7D7D7;
        justify-content: space-between;
        align-items: center;
        margin: 0 5px;
        padding: 0 rem-calc(20px);
        font-size: rem-calc(14px);
        line-height: (24 / 14);
        font-weight: bold;
        text-align: center;

        @media #{$medium-up} {
            padding: 0 rem-calc(26px);
        }

        &.active {
            background-color: $color-green;
            border-style: normal;
            border-color: $color-green;
            color: $color-white;
            font-weight: bold;
            font-size: rem-calc(12px);
            line-height: (15 / 12);
            letter-spacing: 1.2px;
            font-family: $font-family-montserrat;
            text-transform: uppercase;
        }
    }

    &__ingredient-item.active &__ingredient-remove {
        display: flex;
    }

    &__ingredient-text {
        flex-grow: 1;
    }

    &__ingredient-remove {
        display: none;
        justify-content: center;
        align-items: center;
        margin-left: rem-calc(13px);
        appearance: none;
        border: none;
        background-color: transparent;
    }

    &__teaser-result-wrapper {
        margin-top: rem-calc(30px);
        margin-bottom: rem-calc(30px);

        @media #{$medium-up} {
            margin-top: rem-calc(40px);
            margin-bottom: rem-calc(40px);
        }
    }

    &__token-wrapper {
        margin-top: rem-calc(20px);
        margin-bottom: rem-calc(-15px);

        @media #{$medium-up} {
            margin-top: 0;
        }
    }

    &__result-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-top: rem-calc(24px);
        margin-bottom: rem-calc(12px);
    }

    &__result-count {
        // margin-top: rem-calc(25px);

        // @media #{$medium-up} {
        //     margin-top: 0;
        // }

        margin-top: 0;
        margin-bottom: 0;

        @media #{$small-only} {
            font-size: rem-calc(13px);
        }
    }

    &__sort-wrapper {
        display: flex;
        align-items: center;
    }

    &__sort-label {
        font-size: rem-calc(13px);
        line-height: 1.36;
        letter-spacing: -1%;
        margin-right: rem-calc(8px);
        font-weight: bold;
    }
}
