$switch-width: 52px;
$switch-height: 27px;
$toggler-inset: 3px;
$switch-text-margin: 10px;

.switch {
    display: inline-flex;
    align-items: center;

    &--reverse {
        flex-direction: row-reverse;
    }

    &--reverse &__text {
        margin-left: rem-calc($switch-text-margin);
    }
}

.switch__text {
    @extend %copy-small;
    margin-right: rem-calc($switch-text-margin);
}

.switch__switch {
    position: relative;
    display: inline-block;
    width: rem-calc($switch-width);
    height: rem-calc($switch-height);
}

.switch__input {
    display: none !important; // Ewww, but needed to overwrite selector in _elements.scss

    &:checked + .switch__slider {
        background-color: $color-desaturated-green;
    }

    &:focus + .switch__slider {
        box-shadow: 0 0 1px $color-light-green;
    }

    &:checked + .switch__slider:before {
        transform: translateX($switch-width - $switch-height);
    }
}

.switch__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: rem-calc($switch-height);
    background-color: $color-light-grey;
    transition: all $animation-time-default $animation-easing-default;

    &::before {
        position: absolute;
        content: "";
        height: rem-calc($switch-height - ($toggler-inset * 2));
        width: rem-calc($switch-height - ($toggler-inset * 2));
        top: rem-calc($toggler-inset);
        left: rem-calc($toggler-inset);
        border-radius: 50%;
        background-color: $color-white;
        transition: all $animation-time-default $animation-easing-default;
    }
}
