/* COMPONENT Alert Message
##################################################################################################################### */

.alert-message {
    // empty rule
}

.alert-message__confirm-wrapper {
    padding-top: rem-calc(5px);
    margin-bottom: rem-calc(26px);
    text-align: center;
}

.alert-message__confirm-label {
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
}
