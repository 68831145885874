/* COMPONENT C3.1 Hauptsuche (main search)
##################################################################################################################### */

.main-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-57%);
    padding: 0 rem-calc(4px);
    z-index: 2;
}

.main-search__inner {
    background-color: $color-white;
    position: relative;
    padding: rem-calc(22px) rem-calc(10px) rem-calc(15px);

    body:not(.search-overlay-active--home):not(.search-overlay-active) & {
        @media #{$small-only} {
            padding: rem-calc(0px);
        }
    }

    .floating-title {
        @include floating-title();
        z-index: 1;
    }

    @media #{$medium-up} {
        padding: rem-calc(40px);
    }

    @media #{$large-up} {
        padding: rem-calc(30px) rem-calc(100px) rem-calc(20px);
    }
}

.main-search__h2 {
    margin-bottom: rem-calc(12px);
    display: none;

    @media #{$large-up} {
        display: block;
    }

    @if ($little-fooby == false) {
        letter-spacing: calc-em(-1px);
    }

    @media #{$medium-up} {
        margin-bottom: rem-calc(20px);
    }
}

.main-search__search-wrapper {
    position: relative;

    input {
        font-family: $font-family-roboto;

        @media #{$medium-up} {
            //text-align: center;
        }

        @media #{$small-only} {
            font-size: rem-calc(14px);
        }
    }
}

.main-search__swipelink {
    align-items: center;
    font-size: rem-calc(13px);
    line-height: 1.45;
    display: none;
    margin-top: rem-calc(6px);
    margin-bottom: rem-calc(4px);
    transition: color $animation-time-default $animation-easing-default;
    font-weight: 600;

    svg {
        margin-right: rem-calc(4px);
    }

    @media #{$medium-up} {
        display: inline-flex;
        font-size: rem-calc(14px);
    }

    @media #{$large-up} {
        margin-bottom: rem-calc(12px);
    }

    .search-overlay-active--home &,
    .search-overlay-active & {
        display: inline-flex;
    }
}

.main-search__tag-wrapper {
    display: table;
    min-height: rem-calc(50px);
    padding-bottom: rem-calc(10px);

    @media #{$small-only} {
        display: none;
    }
}

.main-search__tag-label,
.main-search__inner-tag-wrapper {
    display: table-cell;
    vertical-align: top;
}

.main-search__tag-label {
    white-space: nowrap;
    padding-right: rem-calc(10px);
    padding-top: rem-calc(12px);
}


/* Modifiers
##################################################################################################################### */

// .main-search--in-overlay {
//     position: relative;
//     opacity: 0;
//     top: rem-calc(6px);
//     padding: 0;
//     transform: translate(0, 0);
//     background-color: transparent;

//     // reset grid
//     .container {
//         padding: 0;
//     }

//     .main-search__inner {
//         padding-left: 0;
//         padding-right: 0;
//         background-color: transparent;
//     }

//     .main-search__h2 {
//         display: none;
//     }

//     .main-search__tag-label,
//     .main-search__inner-tag-wrapper {
//         display: block;
//     }

//     .main-search__tag-wrapper {
//         display: block;
//     }

//     @media #{$medium-up} {
//         top: rem-calc(-15px);

//         .main-search__h2 {
//             display: block;
//         }

//         .main-search__tag-label,
//         .main-search__inner-tag-wrapper {
//             display: table-cell;
//         }

//         // restore grid
//         .container {
//             padding-left: rem-calc($container-padding-medium);
//             padding-right: rem-calc($container-padding-medium);
//         }
//     }

//     @media #{$large-up} {
//         .main-search__inner {
//             padding-left: rem-calc(100px);
//             padding-right: rem-calc(100px);
//         }

//         .container {
//             padding-left: rem-calc($container-padding-large);
//             padding-right: rem-calc($container-padding-large);
//         }
//     }
// }
