.recipe-utils {
    position: relative;
    margin: 0 rem-calc($container-padding-small / -1);

    @media #{$medium-up} {
        margin: 0 rem-calc($container-padding-medium / -1);
    }

    @media #{$large-up} {
        margin: 0 rem-calc($container-padding-large / -1);
    }

    &::before,
    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: 0;
        width: rem-calc(60px);
        height: 100%;
        background-image: linear-gradient(270deg, #FFFFFF 3%, rgba(255,255,255,0.00) 100%);
        opacity: 0;
        transition: opacity .2s ease-in;
    }

    &::after {
        right: 0;
    }

    &::before {
        left: 0;
        transform: rotate(180deg);
    }

    &__wrapper {
        overflow: auto;
        overflow-y: hidden;
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-flex;
        text-align: center;

        &::before,
        &::after {
            content: '';
            top: 0;
            width: rem-calc($container-padding-small);
            height: rem-calc(145px);
            flex-shrink: 0;

            @media #{$medium-up} {
                width: rem-calc($container-padding-medium);
            }

            @media #{$large-up} {
                width: rem-calc($container-padding-large);
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        width: rem-calc(100px);
        min-height: rem-calc(145px);
        background-color: $color-light-grey2;
        padding: rem-calc(14px);
        border-radius: rem-calc(5px);
        flex-shrink: 0;

        &:not(:last-child) {
            margin-right: rem-calc(4px);
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem-calc(60px);
    }

    &__text {
        display: block;
        margin-top: rem-calc(12px);
        font-size: rem-calc(14px);
        line-height: 1.428;

        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: normal;
        word-break: normal;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }


    &.has-left-scroll::before {
        opacity: 1;
    }

    &.has-right-scroll::after {
        opacity: 1;
    }
}
