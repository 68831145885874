.app-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;

    &__btn {
        cursor: pointer;
        float: left;
        margin-bottom: rem-calc(8px);
        max-width: rem-calc(120px);

        @media #{$medium-up} {
            max-width: rem-calc(148px);
        }

        &:not(:last-child) {
            margin-right: rem-calc(14px);

            @media #{$medium-up} {
                margin-right: rem-calc(16px);
            }
        }
    }

    &__image {
        width: 100%;
        height: auto;
    }
}
