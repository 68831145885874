/* COMPONENT bookmark-tooltip
##################################################################################################################### */


// cookbook-select

.cookbook-select {
    // overflow: hidden;
    margin-bottom: rem-calc($gutter-medium / 2);
}

// .cookbook-select__item-group {
//     display: flex;
//     flex-direction: column;
// }

/* scss-lint:disable QualifyingElement */
// input.cookbook-select__item-checkbox {
//     height: rem-calc(15px);
//     width: rem-calc(15px);
//     border: 1px solid $color-dark-grey;
//     position: relative;
//     transition: border-color $animation-time-fast $animation-easing-default,
//                 background-color $animation-time-fast $animation-easing-default;
//     vertical-align: middle;
//     float: left;
//     top: rem-calc(4px);
// }
/* scss-lint:enable QualifyingElement */

// .cookbook-select__item-label {
    // font-size: rem-calc(14px);
    // display: block;
    // font-weight: bold;
    // line-height: 2.2;
    // margin: 0;
    // transition: color $animation-time-fast $animation-easing-default;
    // cursor: pointer;
    // float: left;
    // $temp: rem-calc(26px);
    // width: calc(100% - #{$temp});
    // padding-right: rem-calc(10px);
// }

/* scss-lint:disable QualifyingElement */
// input.cookbook-select__item-checkbox:checked {
//     + .cookbook-select__item-label {
//         color: $color-desaturated-green;
//     }
// }
/* scss-lint:enable QualifyingElement */

.cookbook-select__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: rem-calc(9px) 0;
    user-select: none;

    &:not(:last-child) {
        border-bottom: 1px solid $color-dark-offwhite;
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    // &[data-cookbook-item-state="2"] {
    //     .cookbook-select__item-checkbox {
    //         border-color: $color-desaturated-green;
    //         background-color: $color-desaturated-green;
    //     }

    //     .cookbook-select__item-label {
    //         color: $color-desaturated-green;
    //     }
    // }

    .cookbook-select__item-checkbox + .cookbook-select__item-label {
        margin-top: 0;
    }
}

.cookbook-select__item-checkbox + .cookbook-select__item-label {
    flex-grow: 1;
}

.cookbook-select__item-icon {
    display: flex;
    color: $color-green;
}

@keyframes flash-new-item {
    0% {
        background-color: $color-light-pale-green;
    }

    100% {
        background-color: transparent;
    }
}

.cookbook-select__item--new {
    animation: flash-new-item 1.5s;
    animation-timing-function: $animation-easing-default;
}


// cookbook-add

.cookbook-add {
    overflow: hidden;
    font-family: $font-family-open-sans;
}

.cookbook-add__form {
    display: block;
    position: relative;
    width: 100%;
}

// sass-lint:disable-all
input.cookbook-add__input {
    @extend %form-element-input;

    &.error + .cookbook-add__submit {
        .loading-add__plus,
        .loading-add__circle {
            stroke: $color-error;
        }
    }
}
/* scss-lint:enable all */

.cookbook-add__submit {
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    width: rem-calc(42px);
    border: 0 none;
    height: calc(100% - 2px);
    text-align: center;
    background-color: #fff;
}

.cookbook-add__error {
    display: none;
    cursor: auto;
    margin-top: rem-calc(2px);
    color: $color-error;
    line-height: 1.3;
    font-weight: normal;
    font-size: rem-calc(11px);

}

// sass-lint:disable-all
.cookbook-add--small {
    input.cookbook-add__input {
        font-size: rem-calc(12px);
        padding: rem-calc(7px) rem-calc(10px);
    }

    .cookbook-add__submit {
        width: rem-calc(34px);
        height: rem-calc(38px);
    }
}
/* scss-lint:enable all */


// save-delete-bar

// .save-delete-bar {
//     overflow: hidden;
// }

// .save-delete-bar__delete,
// .save-delete-bar__save {
//     font-size: rem-calc(10px);
//     line-height: (14 / 10);
//     display: block;
//     border: 0 none;
//     background: none transparent;
//     font-family: $font-family-montserrat;
//     font-weight: bold;
//     text-transform: uppercase;
//     height: rem-calc(20px);
//     padding: 0;

//     .icon,
//     .svg-document {
//         float: none;
//         display: inline-block;
//         margin-right: 4px;
//         width: 20px;
//         height: 20px;
//         vertical-align: middle;
//         position: relative;
//         top: rem-calc(-1px);
//     }
// }

.save-delete-bar__delete {
    &[disabled] {
        visibility: hidden;
    }
}

// .save-delete-bar__save {
//     float: right;
//     color: $color-desaturated-green;

//     .icon,
//     .svg-document {
//         fill: $color-desaturated-green;
//     }

//     &[disabled] {
//         opacity: .5;
//     }
// }

// .save-delete-bar__delete--neutral,
// .save-delete-bar__save--neutral {
//     color: $color-stone-grey;

//     .icon {
//         fill: $color-stone-grey;
//     }
// }
