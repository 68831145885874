@if ($little-fooby) {
    .little-fooby-info-section {
        text-align: center;

        .heading--h2 {
            margin-bottom: 0;
        }

        .heading--h3 {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: rem-calc(36px);
            letter-spacing: 1.45px;
            line-height: 1.2;
            margin-bottom: rem-calc(10px);

            @media #{$medium-up} {
                font-size: rem-calc(40px);
                margin-bottom: rem-calc(20px);
            }
        }

        .decoration-line {
            margin-top: rem-calc(45px);
            margin-bottom: rem-calc(60px);
        }

        .svg-group {
            @media #{$medium-up} {
                margin-bottom: rem-calc(10px);
            }

            & > svg {
                width: rem-calc(18px);
                height: rem-calc(19px);
            }
        }

        .h-horizontal-guttered {
            margin-bottom: rem-calc(43px);

            @media #{$medium-up} {
                margin-bottom: rem-calc(30px);
            }
        }

        &__description {
            margin-top: rem-calc(16px);
            margin-bottom: 0;
        }

        &__levels {
            margin-top: rem-calc(45px);
        }

        &__infos {
            margin-top: rem-calc(40px);
        }

        &__level-description {

            @media #{$medium-up} {
                margin-bottom: rem-calc(38px);
            }
        }

        &__image-wrapper {
            position: relative;
            width: 65%;
            height: rem-calc(115px);
            margin-bottom: rem-calc(18px);
            margin-left: auto;
            margin-right: auto;
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
