/* COMPONENT C64 Kategorieeinstieg
##################################################################################################################### */

.category-entrance {
    // no styles

    &:not(.is-open) &__list .row > * {
        &:nth-child(n+5) {
            @include visuallyhidden;
        }
    }

    &--small &__text {
        @media #{$medium-up} {
            @if ($little-fooby) {
                // padding: 0 rem-calc(45px);
                font-size: rem-calc(34px);
            } @else {
                // padding: 0 rem-calc(40px);
                font-size: rem-calc(13px);
                line-height: rem-calc(18px);
            }
        }
    }
}

.category-entrance__item {
    position: relative;
    display: block;
    overflow: hidden;

    &::before {
        content: " ";
        left: rem-calc(10px);
        top: rem-calc(10px);
        right: rem-calc(10px);
        bottom: rem-calc(10px);
        position: absolute;
        z-index: 4;

        @if ($little-fooby) {
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-image: url('../assets/images/little-fooby/image-dots.svg');
        } @else {
            border: 2px solid $color-white;
        }

        @media #{$medium-up} {
            @if ($little-fooby) {
                background-image: url('../assets/images/little-fooby/image-dots-medium.svg');
            }
        }

        @media #{$large-up} {
            left: rem-calc(20px);
            top: rem-calc(20px);
            right: rem-calc(20px);
            bottom: rem-calc(20px);
        }
    }

    &,
    &:hover {
        color: inherit;
    }

    > picture::after {
        content: " ";
        display: block;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-dark-grey;
        z-index: 2;
        opacity: .2;
    }

    &:hover {
        .category-entrance__image {
            transform: $image-zoom-scale;
        }
    }
}

.category-entrance__placeholder {
    @extend %fancyload-image-placeholder;
    background-color: $color-desaturated-green;
    padding-top: calc-ratio(430, 188);

    @media #{$medium-up} {
        padding-top: calc-ratio(262, 184);
    }

    @media #{$large-up} {
        padding-top: calc-ratio(358, 254);
    }
}

.category-entrance__image {
    @extend %fancyload-image;
}

.category-entrance__image--loaded {
    @extend %fancyload-image--loaded;
}


.category-entrance__foreground {
    z-index: 5;
}

.category-entrance__text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    padding: 0 rem-calc(20px);
    z-index: 5;
    color: $color-white;
    text-align: center;
    text-transform: uppercase;

    @if ($little-fooby) {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size: rem-calc(30px);
        line-height: 1.333;
        letter-spacing: rem-calc(1.5px);
        transform: translateY(-50%);
    } @else {
        font-family: $font-family-montserrat;
        font-weight: bold;
        font-size: rem-calc(12px);
        line-height: rem-calc(18px);
        letter-spacing: rem-calc(.5px);
        transform: translateY(-25%); // only -25% due to only uppercase chars
    }

    @media #{$large-up} {
        letter-spacing: rem-calc(1px);

        @if ($little-fooby) {
            padding: 0 rem-calc(45px);
            font-size: rem-calc(40px);
            line-height: 1.05;
        } @else {
            padding: 0 rem-calc(40px);
            font-size: rem-calc(18px);
            line-height: rem-calc(24px);
        }
    }
}

// modifier
.category-entrance__item--pictures {
    .category-entrance__placeholder {
        background-color: $color-dark-grey;
    }
}
