/* COMPONENT Page Header event
##################################################################################################################### */

$border-space-mobile: rem-calc(7px);
$border-space-tablet: rem-calc(16px);
$border-space-desktop: rem-calc(20px);

.page-header-event {
    @media #{$medium-up} {
        background-color: $color-light-grey;
    }

    @media #{$large-up} {
        position: relative;
        max-width: 2000px;
        margin: 0 auto;
    }
}

.page-header-event__image-floater {
    background-color: $color-white;

    @media #{$medium-up} {
        padding: 0 0 rem-calc(20px);
    }

    @media #{$large-up} {
        padding: 0 rem-calc(20px) rem-calc(20px);
    }
}

.page-header-event__image-container {
    width: 100%;

    @media #{$large-up} {
        width: 83%;
    }

    @media #{$xlarge-up} {
        width: 77%;
    }
}

.page-header-event__image-container-inner {
    padding-top: 66.666%;
    position: relative;
    width: 100%;
}

.page-header-event__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.page-header-event__image-container-inner::before {
    position: absolute;
    content: " ";
    border: 2px solid $color-white;
    top: $border-space-mobile;
    right: $border-space-mobile;
    bottom: $border-space-mobile;
    left: $border-space-mobile;
    z-index: 3;

    @media #{$medium-up} {
        top: $border-space-tablet;
        right: $border-space-tablet;
        bottom: $border-space-tablet;
        left: $border-space-tablet;
    }

    @media #{$large-up} {
        top: $border-space-desktop;
        right: $border-space-desktop;
        bottom: $border-space-desktop;
        left: $border-space-desktop;
    }
}

@media #{$large-up} {
    .page-header-event__panel {
        margin-top: rem-calc(20px);
    }

    .page-header-event__special-col {
        position: static;
    }
}

.page-header-event__panel-detail,
.page-header-event__panel-inner {
    background-color: $color-white;
    overflow: hidden;
}

.page-header-event__panel-inner {
    padding-top: rem-calc(18px);
    padding-bottom: rem-calc(30px);

    @media #{$medium-up} {
        padding: rem-calc(28px) rem-calc(30px);
    }

    @media #{$large-up} {
        width: 100%;
        padding: rem-calc(28px) rem-calc(30px);
        //display: table;
        width: 100%;
        overflow: visible;
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            background-color: $color-light-grey;
            height: calc(100% + 40px);
            width: 4000px;
            left: 50%;
            top: -20px;
            transform: translateX(-50%);
            z-index: 0;
        }

        &::after {
            content: " ";
            position: absolute;
            background-color: $color-white;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;
        }

        > * {
            position: relative;
            z-index: 2;
        }
    }
}


$authorboxwidth: 250px;
$temp: rem-calc($authorboxwidth);

.page-header-event__intro {
    padding-bottom: rem-calc(32px);

    > p {
        @extend %paragraph--copy-intro;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media #{$medium-up} {
        padding-bottom: rem-calc(20px);
    }
}

.page-header-event__panel-table-inner {
    @media #{$medium-up} {
        display: table;
        width: 100%;
    }
}

.page-header-event__author {

    margin-top: 0;
    padding-bottom: rem-calc(32px);

    @media #{$medium-up} {
        display: table-cell;
        margin-top: 0;
        padding-bottom: 0;
        vertical-align: bottom;
    }
}

.page-header-event__share {
    margin-top: 0;

    @media #{$medium-up} {
        display: table-cell;
        vertical-align: bottom;
        text-align: right;

        .share-group {
            text-align: right;
        }
    }
}


@media #{$medium-up} {
    @-moz-document url-prefix() {
        .page-header-event__panel-table {
            width: 100%;
            -moz-box-sizing: border-box;
        }
    }
}

.page-header-event__grey-space {
    height: rem-calc(20px);
    background-color: $color-light-grey;

    &--enlarged {
        margin-left: rem-calc($container-padding-small * -1);
        margin-right: rem-calc($container-padding-small * -1);
    }
}
