/* scss-lint:disable NestingDepth SelectorDepth  */
.icon-link {
    display: inline-block;
    color: $color-stone-grey;
    font-size: rem-calc(12px);
    transition: color $animation-time-default $animation-easing-default;
    text-decoration: none;
    line-height: 1.27;
    overflow: hidden;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: $color-green;

        .icon-link__icon {
            > svg path {
                fill: $color-green;
            }
        }
    }
}
/* scss-lint:enable NestingDepth SelectorDepth  */

.icon-link__icon {
    display: block;
    float: left;
    margin-right: rem-calc(6px);
    width: 20px;
    height: 20px;

    > svg path {
        fill: $color-stone-grey;
        transition: fill $animation-time-default $animation-easing-default;
    }
}

.icon-link__label {
    display: block;
    margin-top: rem-calc(4px);
    font-weight: 700;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    letter-spacing: .08em;
    overflow: hidden;
}

.icon-link--small {
    font-size: rem-calc(9px);

    .icon-link__label {
        margin-top: rem-calc(6px);
        letter-spacing: .1em;
    }
}
