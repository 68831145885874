$filter-bar-border: 1px solid $color-white;
$mobile-gradient-size: 17px;

// The very basic filter bar:

.filter-bar {
    background-color: $filter-bar-background-color;

    &.filter-bar--whitespaced {
        margin-bottom: rem-calc($gutter-medium);
    }

    @media #{$small-only} {
        margin-left: 0;
        margin-right: 0;
    }
}

.filter-bar__list {
    @extend %unstyled-list;
    display: flex;
}

.filter-bar__item {
    color: $filter-bar-text-color;
    text-transform: uppercase;
    height: rem-calc(50px);
    padding: 0 rem-calc(15px);
    cursor: pointer;

    font-family: $font-family-montserrat;
    font-size: rem-calc(12px);
    line-height: (14 / 12);
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    &:not(:last-child) {
        border-right: $filter-bar-border;
    }

    &.is-active {
        color: $color-white;
        background-color: $filter-bar-text-color;
    }
}

@media #{$large-up} {
    .filter-bar {
        margin-left: 0;
        margin-right: 0;
    }

    .filter-bar__item {
        padding: 0 rem-calc(30px);
        border-right: $filter-bar-border;
        flex: 0 0 auto;

        &:first-child {
            border-left: $filter-bar-border;
        }
    }
}

// The following styles are used in the advanced filter bar (having level 2 options):

.filter-bar__level-two-overlay-open-trigger {
    @media #{$medium-up} {
        display: none;
    }
}

.filter-bar__filter-l2 {
    margin-top: rem-calc(13px);
    transition: height $animation-time-default $animation-easing-default;

    @media #{$medium-up} {
        margin-top: 0;
        margin-bottom: rem-calc(16px);
        background-color: $filter-bar-background-color;
        height: auto;
    }

    // sass-lint:disable-all
    @media #{$large-only} {
        > .container {
            max-width: inherit;
            padding-left: 0;
            padding-right: 0;

            > .row {
                margin-left: 0;
                margin-right: 0;

                > .col-xs-12 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    /* scss-lint:enable all */
}

.filter-bar__filter-l2-inner-cross {
    position: absolute;
    top: rem-calc(22px);
    right: rem-calc(16px);
}

.filter-bar__filter-l2-inner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 400;
    background-color: $panel-background-color;
    padding: rem-calc(16px);

    display: none;
    opacity: 0;



    @media #{$medium-up} {
        min-height: rem-calc(50px);
        position: relative;
        z-index: auto;
        background-color: transparent;
        padding: 0;
        display: block;
        opacity: 1;
    }
}

.filter-bar__filter-l2-group {
    display: none;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    position: absolute;
    $temp: rem-calc(16px * 2);
    width: calc(100% - #{$temp});
    top: rem-calc(56px);
    bottom: rem-calc(80px);

    @media #{$small-only} {
        &::before {
            background-color: $panel-background-color;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    @media #{$medium-up} {
        margin-left: rem-calc(-$container-padding-medium);
        margin-right: rem-calc(-$container-padding-medium);
        margin-bottom: 0;
        overflow: visible;
        width: inherit;
        position: relative;
        left: 0;
        top: 0;
        bottom: inherit;
    }

    @media #{$large-up} {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    &[data-filter-state="2"] {
        display: block;

        @media #{$large-up} {
            display: table;
            width: 100%;
        }
    }
}

[data-filter-level-two-active-title] {
    text-transform: capitalize;
}

.filter-bar__filter-l2-group-item {
    height: rem-calc(50px);
    line-height: rem-calc(50px);
    font-family: $font-family-montserrat;
    font-weight: bold;
    font-size: rem-calc(11px);
    text-transform: uppercase;
    padding: 0 rem-calc(16px);
    color: $color-dark-grey;
    background-color: $color-white;
    letter-spacing: rem-calc(.5px);
    border-bottom: 1px solid $color-light-grey;
    overflow: hidden;

    @media #{$small-only} {
        @if ($little-fooby) {
            &:first-child {
                border-top-left-radius: rem-calc(6px);
                border-top-right-radius: rem-calc(6px);
            }

            &:last-child {
                border-bottom-left-radius: rem-calc(6px);
                border-bottom-right-radius: rem-calc(6px);
            }
        }
    }

    @media #{$medium-up} {
        float: left;
        border-left: 1px solid $color-white;
        font-size: rem-calc(12px);
        color: $filter-bar-text-color;
        background-color: transparent;
        padding: 0 rem-calc(30px);
        border-bottom: 0 none;
        position: relative;
        overflow: visible;
    }

    &:last-child {
        @media #{$large-up} {
            border-right: 1px solid $color-white;

            .filter-bar__filter-l2-group-item-optiongroup {
                min-width: calc(100% + 2px);
            }
        }
    }

    &[data-filter-type="radio"] {
        cursor: pointer;
        display: block;
    }

    &[data-filter-state="0"] {
        color: rgba($filter-bar-text-color, .25);
        cursor: default;

        .svg-fill {
            fill: rgba($filter-bar-text-color, .25);
        }
    }

    @media #{$small-only} {
        &[data-filter-state="2"] {
            color: $filter-bar-text-color;
        }
    }

    @media #{$medium-up} and (max-width: #{$screen-lg - 1px}) {
        &[data-filter-type="radio"] {
            width: 25%;
            text-align: center;
            border-bottom: 1px solid $color-white;

            &:nth-child(4n+1) {
                border-left: 0 none;
            }

            &:nth-child(4n) {
                border-right: 0 none;
            }
        }
    }

    @media #{$medium-up} {
        &[data-filter-type="radio"] {
            float: left;
        }

        &[data-filter-type="radio"][data-filter-state="2"] {
            background-color: $filter-bar-text-color;
            color: $color-white;
        }
    }

    @media #{$large-up} {
        display: table-cell;
        float: none;
        //width: 100%;
        transition: color $animation-time-fast $animation-easing-default,
                    background-color $animation-time-fast $animation-easing-default,
                    transform $animation-time-slow $animation-easing-default,
                    opacity $animation-time-slow $animation-easing-default;
    }

    &[data-filter-type="dropdown"] {

        &::after {
            content: " ";
            position: absolute;
            left: 0;
            bottom: rem-calc(.5px); // subpixel render fix for IE11
            right: rem-calc(.5px); // subpixel render fix for IE11
            height: rem-calc(5px);
            z-index: 6; // needs to be higher than .filter-bar__filter-l2-group-item-optiongroup

            @media #{$large-up} {
                background-color: transparent;
                transition: background-color $animation-time-fast $animation-easing-default;
            }
        }

        @media #{$small-only} {
            &[data-filter-state-open] .filter-bar__filter-l2-group-item-optiongroup {
                transform: translateX(0);
                display: block;
            }
        }

        @media #{$medium-up} {
            text-align: center;

            &:hover:not([data-filter-state="0"]) {
                background-color: $color-white;
                color: $filter-bar-text-color;
                box-shadow: 0 0 rem-calc(3px) rgba(0, 0, 0, .2);
                z-index: 10;

                @if ($little-fooby) {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }
            }

            &:hover:not([data-filter-state="0"])::after {
                background-color: $color-white;
            }

            &:hover:not([data-filter-state="0"]) .filter-bar__filter-l2-group-item-optiongroup {
                display: block;
            }
        }

        @media #{$medium-only} {
            width: 33.33333%;
            text-align: center;
            border-bottom: 1px solid $color-white;

            &:nth-child(3n+1) {
                border-left: 0 none;
            }

            &:nth-child(3n) {
                border-right: 0 none;
            }
        }
    }
}

.filter-bar__float-right-mobile {
    @media #{$small-only} {
        float: right;
    }
}

.filter-bar__filter-l2-group-item-angle-mobile {
    float: right;

    .svg-fill {
        fill: $color-dark-grey;
    }
}

.filter-bar__filter-l2-group-item-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;

    @media #{$medium-up} {
        width: auto;
    }
}

.filter-bar__filter-l2-group-item-title {
    padding-right: 20px;
    line-height: 20px;
    display: inline-block;
    position: relative;
    min-width: 80px;
    hyphens: manual;

    @media #{$medium-up} {
        vertical-align: middle;
    }
}

.filter-bar__filter-l2-group-item-angle {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 0;

    svg {
        transition: transform $animation-time-default $animation-easing-default;
    }

    .svg-fill {
        fill: $filter-bar-text-color;
    }
}

.filter-bar__filter-overlay-count-button {
    position: absolute;
    bottom: 20px;
    left: rem-calc(16px);
    right: rem-calc(16px);
    $temp: rem-calc(16px * 2);
    width: calc(100% - #{$temp});
    display: block;
}

.filter-bar__filter-l2-group-item-optiongroup {
    position: absolute;
    z-index: 11;
    top: 0;
    bottom: 0;
    left: -1px;
    width: calc(100% + 1px);
    transform: translateX(101%);
    transition: transform $animation-time-default $animation-easing-default;
    border-left: 1px solid $color-light-grey;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @if ($little-fooby) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        @media #{$large-up} {
            border-top-right-radius: 5px;
        }
    }

    @media #{$small-only} {
        &::before {
            background-color: $panel-background-color;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 15;
        }
    }

    @media #{$medium-up} {
        left: -1px; // no rem value due to not having remvalues for borders and htis is compensation for the border
        padding: rem-calc(15px) rem-calc(30px);
        min-width: calc(100% + 1px); // +1 to compensate for left -1
        z-index: 5;
        box-shadow: 0 0 rem-calc(3px) rgba(0, 0, 0, .15);
        display: none;
        top: 100%;
        bottom: auto;
        width: auto;
        border: 0;
        //position: static;
        overflow: visible;
        transform: translateX(0);
        background-color: $color-white;
    }
}

.filter-bar__filter-l2-group-item-backlink {
    white-space: nowrap;
    text-align: left;
    font-size: rem-calc(11px);
    line-height: rem-calc(50px);
    color: $color-dark-grey;
    border-bottom: 1px solid $color-light-grey;
    padding: 0 rem-calc(16px);
    background-color: $color-lighter-grey;
    text-transform: uppercase;
    position: relative;
    z-index: 15;

    @if ($little-fooby) {
        border-top-left-radius: rem-calc(6px);
        border-top-right-radius: rem-calc(6px);
    }
}

.filter-bar__filter-l2-group-item-backlink-icon {
    display: inline-block;
    width: rem-calc(15px);
    margin-right: rem-calc(11px);
    position: relative;
    left: rem-calc(3px);
    top: rem-calc(1px);

    .svg-fill {
        fill: $color-dark-grey;
    }
}

.filter-bar__filter-l2-group-item-option {

    @media #{$small-only} {
        position: relative;
        line-height: rem-calc(50px);
        height: rem-calc(50px);
        border-bottom: 1px solid $color-light-grey;
        padding: 0 rem-calc(16px);
        background-color: $color-white;
        z-index: 15;

        &:last-child {
            margin-bottom: rem-calc($mobile-gradient-size - 5px);

            @if ($little-fooby) {
                border-bottom-left-radius: rem-calc(6px);
                border-bottom-right-radius: rem-calc(6px);
            }

            &::after {
                content: " ";
                top: 100%;
                position: absolute;
                left: 0;
                right: 0;
                background-color: $panel-background-color;
                height: rem-calc($mobile-gradient-size);
            }
        }
    }

    white-space: nowrap;
    text-align: left;
    font-size: rem-calc(11px);
    line-height: rem-calc(34px);
    color: $color-dark-grey;
    transition: color $animation-time-fast $animation-easing-default;

    &-checkbox {
        display: inline-block;
        border: 1px solid $color-grey;
        height: rem-calc(16px);
        width: rem-calc(16px);
        background-color: $color-white;
        transition: all $animation-time-fast $animation-easing-default;
        position: relative;
        vertical-align: baseline;
        margin-right: rem-calc(11px);
        top: rem-calc(3px);
        background-repeat: no-repeat;
        background-position: center center;
    }

    &:not([data-filter-state="0"]) {
        cursor: pointer;
    }

    &[data-filter-state="2"] {
        color: $filter-bar-text-color;

        .filter-bar__filter-l2-group-item-option-checkbox {
            border-color: $theme-color;
            background-color: $theme-color;
    	    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgOSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPlBhdGggMjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iMTktU3Blei1CdXR0b25zLC1MaW5rcyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJTdHlsZWd1aWRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTIxLjAwMDAwMCwgLTE0NjcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNGRkZGRkYiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTE4LjAwMDAwMCwgMTQ1OC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTIiIHBvaW50cz0iNCAxMi4yMTY3Njc4IDYuMzM2MzYzNzkgMTUuMzUzNjMxIDExLjExNzU2OCA5Ij48L3BvbHlsaW5lPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
        }
    }

    &[data-filter-state="0"] {
        color: $color-stone-grey;

        .filter-bar__filter-l2-group-item-option-checkbox {
            border-color: $color-grey;
            background-color: $color-light-grey;
            background-image: none;
        }
    }
}
