$offset: 60;
$duration: 1.4s;

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

.spinner {
    transform: rotate(0deg);
    animation: rotator $duration linear infinite;

    > circle {
        transform-origin: center;
        stroke-dasharray: $offset;
        stroke-dashoffset: $offset;
        animation: dash $duration ease-in-out infinite;
    }
}
