/* TEMPLATE T12 Searchresults
##################################################################################################################### */

$mobile-gradient-size: 17px;
$easing: cubic-bezier(.305, .485, .015, 1.195);

.back-to-top-button {
    position: fixed;
    top: rem-calc(70px);
    left: 50%;
    transform: translate(-50%, -200px);
    display: block;
    z-index: 50;
    padding: rem-calc(12px) rem-calc(20px);
    color: $filter-bar-text-color;
    font-size: rem-calc(12px);
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-family: $font-family-montserrat;
    transition: transform $animation-time-slow $animation-easing-default,
        color $animation-time-default $animation-easing-default,
        background-color $animation-time-default $animation-easing-default;

    @if ($little-fooby == false) {
        background-color: $filter-bar-background-color;
    }

    &,
    &:hover,
    &:active,
    &:focus {
        color: $filter-bar-text-color;
    }

    &__text svg {
        position: relative;
        top: rem-calc(2px);
        display: inline-block;
        margin-right: rem-calc(4px);
    }

    &__text .svg-stroke {
        stroke: $filter-bar-text-color;
        transition: stroke $animation-time-default $animation-easing-default;
    }

    @if ($little-fooby) {
        &__background {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            fill: $color-lf-cyan-light;
            transition: fill $animation-time-default $animation-easing-default;
        }
    }

    &--visible {
        transform: translate(-50%, 0);
        transition: transform $animation-time-slow $easing,
            color $animation-time-default $animation-easing-default,
            background-color $animation-time-default $animation-easing-default;
    }

    &:hover {
        color: $color-white;

        @if ($little-fooby == false) {
            background-color: $filter-bar-text-color;
        }
    }

    &:hover &__text .svg-stroke {
        stroke: $color-white;
    }

    @if ($little-fooby) {
        &:hover &__background {
            fill: $filter-bar-text-color;
        }
    }

    @media #{$large-up} {
        top: inherit;
        left: inherit;
        bottom: rem-calc(20px);
        right: rem-calc(20px);
        transform: translate(0, 200px);
        font-size: rem-calc(14px);

        @if ($little-fooby) {
            line-height: rem-calc(46px);
            padding: 0 rem-calc(24px);
        } @else {
            line-height: rem-calc(45px);
            padding: 0 rem-calc(20px);
        }

        &--visible {
            transform: translate(0, 0);
        }
    }

    @media #{$xlarge-up} {
        bottom: rem-calc(40px);
        right: rem-calc(30px);
    }
}

.t12-searchresults {
    border-top: rem-calc(47px) solid $color-lighter-grey;
    position: relative;
    background-color: $color-lighter-grey;

    &__badge {
        @extend %floating-title;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__header-container {
        @media #{$small-only} {
            background-color: $color-white;
        }
    }

    &__header {
        padding-top: rem-calc(34px);

        @media #{$medium-up} {
            padding-top: rem-calc(48px);
            background-color: $color-white;
        }
    }

    &__result-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem-calc(24px);

        &::after,
        &::before {
            content: none;
        }

        @media #{$medium-up} {
            display: none;
        }
    }

    &__result-head-medium {
        display: none;
        margin-bottom: rem-calc(12px);

        @media #{$medium-up} {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    &__sort-wrapper {
        display: flex;
        align-items: center;
    }

    &__sort-label {
        font-size: rem-calc(13px);
        line-height: 1.36;
        letter-spacing: -1%;
        margin-right: rem-calc(8px);
        font-weight: bold;
    }

    /* Tags */

    &__tag-wrapper {
        display: flex;
        flex-direction: column;
        min-height: rem-calc(50px);
        padding-bottom: rem-calc(24px);

        @media #{$medium-up} {
            flex-direction: row;
            margin-left: rem-calc(18px);
            margin-right: rem-calc(18px);
            padding-bottom: rem-calc(32px);
        }
    }

    &__inner-tag-wrapper {
        flex-grow: 1;
        max-height: rem-calc(45px * 2);
        overflow: hidden;

        @media #{$medium-up} {
            max-height: rem-calc(45px * 3);
        }

        @media #{$large-up} {
            max-height: none;
        }
    }

    &__tag-label {
        white-space: nowrap;
        padding-right: rem-calc(10px);
        padding-top: rem-calc(12px);
    }

    [data-button] {
        display: none;
    }

    .filter-bar__filter-l2 {
        @media #{$medium-up} {
            margin-top: rem-calc(48px);
            margin-bottom: rem-calc(22px);
        }
    }

    .background-panel {
        @media #{$small-only} {
            padding-top: rem-calc(12px);
        }
    }

    @media #{$large-up} {
        border-top-width: rem-calc(36px);
    }
}

.t12-searchresults__teaser-result-wrapper,
.t12-searchresults__noresults-wrapper {
    margin-top: rem-calc(30px);
    margin-bottom: rem-calc(30px);

    @media #{$medium-up} {
        margin-top: rem-calc(40px);
        margin-bottom: rem-calc(40px);
    }
}

.t12-searchresults__teaser-result-wrapper {
    min-height: rem-calc(700px);
}

.t12-searchresults__token-wrapper {
    margin-top: rem-calc(20px);
    margin-bottom: rem-calc(-15px);

    @media #{$medium-up} {
        margin-top: 0;
    }
}

.t12-searchresults__result-count {
    margin-top: 0;
    margin-bottom: 0;

    @media #{$small-only} {
        font-size: rem-calc(13px);
    }
}

.t12-searchresults__filter-l1 {
    display: table;
    width: 100%;
    margin-top: rem-calc(6px);
    margin-bottom: rem-calc(20px);

    @media #{$medium-up} {
        margin-top: rem-calc(26px);
        margin-bottom: 0;
    }

    &-label {
        white-space: nowrap;
        vertical-align: top;
        color: $color-stone-grey;
        font-size: rem-calc(11px);
        line-height: rem-calc(15px);
        margin-bottom: rem-calc(10px);

        @media #{$xlarge-up} {
            display: table-cell;
            width: 1px;
            padding-right: rem-calc(55px);
        }
    }
}

.t12-searchresults__filter-l1-list {
    display: block;
    text-align: left;
    padding: 0;
    line-height: 1;
    list-style: none;
    margin: 0;
    width: 100%;

    &.closed .t12-searchresults__filter-l1-list-item:not([data-filter-state="2"]) {
        height: 0;
    }

    @media #{$medium-up} {
        display: inline-flex;
        justify-content: center;
        vertical-align: top;
    }
}

.t12-searchresults__filter-l1-list-item {
    @media #{$small-only} {
        float: left;
        width: 100%;
        clear: left;
        display: block;

        line-height: rem-calc(34px);
        height: rem-calc(34px);
        overflow: hidden;
        transition: height $animation-time-slow $animation-easing-default;
    }

    line-height: 1.6;
    font-size: rem-calc(14px);
    font-weight: bold;
    font-family: $font-family-montserrat;
    text-transform: uppercase;

    @media #{$medium-up} {
        padding-bottom: rem-calc(8px);
        padding-left: rem-calc(20px);
        padding-right: rem-calc(20px);
        margin: 0 rem-calc(4px);
    }

    &:not([data-filter-state="0"]) {
        cursor: pointer;
    }

    &[data-filter-state="2"] {
        color: $filter-level1-text-color;

        @media #{$medium-up} {
            @if ($little-fooby) {
                border-bottom: 2px solid $color-lf-cyan;
            } @else {
                border-bottom: 2px solid $color-desaturated-green;
            }
        }

        .t12-searchresults__filter-l1-list-item-angle {
            opacity: 1;
        }
    }

    &[data-filter-state="0"] {
        opacity: .5;
    }
}

.t12-searchresults__filter-l1-list-item-angle {
    float: right;
    opacity: 0;
    transition: opacity $animation-time-fast $animation-easing-default;

    .svg-fill {
        fill: $filter-level1-text-color;
    }
}

.t12-searchresults__float-right-mobile {
    @media #{$small-only} {
        float: right;
    }
}

.t12-searchresults__filter-l2-group-item-angle-mobile {
    float: right;

    .svg-fill {
        fill: $color-dark-grey;
    }
}

.t12-searchresults__filter-l2-group-item-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;

    @media #{$medium-up} {
        width: auto;
    }
}

.t12-searchresults__filter-l2-group-item-title {
    padding-right: 20px;
    line-height: 20px;
    display: inline-block;
    position: relative;
    min-width: 80px;
    hyphens: manual;

    @media #{$medium-up} {
        vertical-align: middle;
    }
}

.t12-searchresults__filter-l2-group-item-angle {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 0;

    svg {
        transition: transform $animation-time-default $animation-easing-default;
    }

    .svg-fill {
        fill: $color-green;
    }
}

.t12-searchresults__filter-overlay-count-button {
    position: absolute;
    bottom: 20px;
    left: rem-calc(16px);
    right: rem-calc(16px);
    $temp: rem-calc(16px * 2);
    width: calc(100% - #{$temp});
    display: block;
}

.t12-searchresults__filter-l2-group-item-optiongroup {
    // background-color: $color-white;
    position: absolute;
    z-index: 11;
    top: 0;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px);
    transform: translateX(101%);
    transition: transform $animation-time-default $animation-easing-default;
    border-left: 1px solid $color-light-grey;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media #{$small-only} {
        &::before {
            background-color: $color-light-grey;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // $temp: rem-calc($mobile-gradient-size);
            // height: calc(100% - #{$temp});
            height: 100%;
            z-index: 15;
        }
    }

    @media #{$medium-up} {
        left: -1px; // no rem value due to not having remvalues for borders and htis is compensation for the border
        padding: rem-calc(15px) rem-calc(30px);
        $temp: rem-calc(2px);
        min-width: calc(100% + #{$temp}); // +1 to compensate for left -1
        z-index: 5;
        box-shadow: 0 0 rem-calc(3px) rgba(0, 0, 0, .15);
        display: none;
        top: 100%;
        bottom: auto;
        width: auto;
        border: 0;
        //position: static;
        overflow: visible;
        transform: translateX(0);
        background-color: $color-white;
    }
}

.t12-searchresults__filter-l2-group-item-backlink {
    white-space: nowrap;
    text-align: left;
    font-size: rem-calc(11px);
    line-height: rem-calc(50px);
    color: $color-dark-grey;
    border-bottom: 1px solid $color-light-grey;
    padding: 0 rem-calc(16px);
    background-color: $color-lighter-grey;
    text-transform: uppercase;
    position: relative;
    z-index: 15;
}

.t12-searchresults__filter-l2-group-item-backlink-icon {
    display: inline-block;
    width: rem-calc(15px);
    margin-right: rem-calc(11px);
    position: relative;
    left: rem-calc(3px);
    top: rem-calc(1px);

    .svg-fill {
        fill: $color-dark-grey;
    }
}

.t12-searchresults__filter-l2-group-item-option {

    @media #{$small-only} {
        position: relative;
        line-height: rem-calc(50px);
        height: rem-calc(50px);
        border-bottom: 1px solid $color-light-grey;
        padding: 0 rem-calc(16px);
        background-color: $color-white;
        z-index: 15;

        &:last-child {
            margin-bottom: rem-calc($mobile-gradient-size - 5px);

            &::after {
                content: " ";
                top: 100%;
                position: absolute;
                left: 0;
                right: 0;
                background-color: $color-light-grey;
                height: rem-calc($mobile-gradient-size);
            }
        }
    }

    white-space: nowrap;
    text-align: left;
    font-size: rem-calc(11px);
    line-height: rem-calc(34px);
    color: $color-dark-grey;
    transition: color $animation-time-fast $animation-easing-default;

    &-checkbox {
        display: inline-block;
        border: 1px solid $color-grey;
        height: rem-calc(16px);
        width: rem-calc(16px);
        background-color: $color-white;
        transition: all $animation-time-fast $animation-easing-default;
        position: relative;
        vertical-align: baseline;
        margin-right: rem-calc(11px);
        top: rem-calc(3px);
        background-repeat: no-repeat;
        background-position: center center;
    }

    &:not([data-filter-state="0"]) {
        cursor: pointer;
    }

    &[data-filter-state="2"] {
        color: $color-desaturated-green;

        .t12-searchresults__filter-l2-group-item-option-checkbox {
            border-color: $color-green;
            background-color: $color-green;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5cHgiIGhlaWdodD0iN3B4IiB2aWV3Qm94PSIwIDAgOSA3IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICAgICAgPHRpdGxlPlBhdGggMjwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iMTktU3Blei1CdXR0b25zLC1MaW5rcyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4gICAgICAgIDxnIGlkPSJTdHlsZWd1aWRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTIxLjAwMDAwMCwgLTE0NjcuMDAwMDAwKSIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiNGRkZGRkYiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTE4LjAwMDAwMCwgMTQ1OC4wMDAwMDApIj4gICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTIiIHBvaW50cz0iNCAxMi4yMTY3Njc4IDYuMzM2MzYzNzkgMTUuMzUzNjMxIDExLjExNzU2OCA5Ij48L3BvbHlsaW5lPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
        }
    }

    &[data-filter-state="0"] {
        color: $color-stone-grey;

        .t12-searchresults__filter-l2-group-item-option-checkbox {
            border-color: $color-grey;
            background-color: $color-light-grey;
            background-image: none;
        }
    }
}

.t12-searchresults__info-copy {
    p:last-child {
        margin-bottom: rem-calc(30px);
    }
}

.t12-searchresults__suggestion-result-wrapper {
    padding-bottom: rem-calc(30px);

    @media #{$medium-up} {
        padding-bottom: rem-calc(60px);
    }
}
