/* COMPONENT social-media
##################################################################################################################### */

/* scss-lint:disable NestingDepth SelectorDepth */
.infotable-app {
    border: 10px solid $color-light-grey;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: stretch;

    @media #{$medium-up} {
        display: inline-block;
    }
    @media #{$large-up} {
        display: block;
    }
}

.infotable-app--text {
    flex: 0 1 auto;
    padding: .5rem 1rem 1rem;

    .title {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size: 2rem;
    }

    p {
        font-size: .875rem;
        line-height: 1.375;
        padding: .25rem 0;
        text-align: left;
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        width: 100%;
        display: block;
        padding: 0;
        margin-top: 1rem;

        &:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: " ";
            clear: both;
            height: 0;
        }

        li {
            float: left;
            padding-left: .25rem;
            width: 50%;
            text-align: left;

            @media (max-width: 400px) {
                float: none;
                width: 100%;
                padding: rem-calc(5px) 0;
            }

            &:first-child {
                padding-left: 0;
                text-align: right;

                @media (max-width: 400px) {
                    text-align: left;
                }

                @media #{$large-up} {
                    text-align: left;
                }
            }

            img {
                max-width: 8rem;

                @media #{$medium-up} {
                    max-width: 100%;
                }
            }
        }
    }
}

.infotable-app--image {
    flex: 0 1 auto;
    display: none;

    @media #{$medium-up} {
        display: block;
    }

    img {
        width: 100%;
    }
}
/* scss-lint:enable NestingDepth SelectorDepth */
