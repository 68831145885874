/* COMPONENT C35.1 Video
##################################################################################################################### */


.video__placeholder {
    @extend %fancyload-image-placeholder;
}

.video__image {
    @extend %fancyload-image;
}

@include ratio-config("image", ".video");

.video__caption {
    @extend %figcaption;
}

.video__wrapper {
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
        .video__play-btn .animated {
            fill-opacity: .6;
        }

        .video__image {
            transform: translateZ(0) $image-zoom-scale;
        }
    }
}

.video__play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    width: rem-calc(80px);
    height: rem-calc(80px);

    .animated {
        transition: fill-opacity $animation-time-default $animation-easing-default;
    }

    @media #{$medium-up} {
        width: rem-calc(110px);
        height: rem-calc(110px);
    }
}

@media #{$large-up} {
    .video--initial-columns-12 .video__play-btn {
        width: rem-calc(150px);
        height: rem-calc(150px);
    }
}

.video__overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: $color-white;

    opacity: 0;
    transform: scale(0);

    transition: transform .7s cubic-bezier(.600, .095, .475, .940),
                opacity $animation-time-slow $animation-easing-default .58s;
    will-change: transform, opacity;

    &--open {
        transition: transform .7s cubic-bezier(.600, .095, .475, .940),
                    opacity $animation-time-slow $animation-easing-default;

        opacity: 1;
        transform: scale(1);
    }
}

.video__iframe-wrapper {
    position: absolute;
    top: rem-calc(55px);
    right: rem-calc(20px);
    bottom: rem-calc(20px);
    left: rem-calc(20px);
}

.video__stop-btn {
    position: absolute;
    z-index: 10;
    top: -39px;
    right: 20px;
    cursor: pointer;
    height: 25px;
    width: 25px;
    transition: transform $animation-time-default $animation-easing-default;
    color: $color-dark-grey;

    svg {
        width: 100%;
        height: 100%;
    }

    svg path {
        fill: currentColor;
    }

    &:hover {
        transform: scale(1.15);
    }
}

.video__iframe-placeholder,
.video__iframe-wrapper iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #000;
}
