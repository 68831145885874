/* COMPONENT Background Panel
##################################################################################################################### */

.background-panel {
    position: relative;
    display: block;

    @if ($little-fooby) {
        &::before {
            content: '';
            pointer-events: none;
            width: 100%;
            height: calc(100% + 3.5px);
            position: absolute;
            top: -3.5px;
            left: 0;
            background-image: url('../assets/images/little-fooby/backgrounds/section/default.svg');
            background-repeat: repeat-x;
            background-size: auto auto;
        }

        &--decoration-flags-right::before,
        &--decoration-flags-right2::before,
        &--decoration-flags-left::before {
            background-size: 145px auto;
            top: 0;
            height: 100%;

            @media #{$medium-up} {
                background-size: auto auto;
            }
        }

        &--decoration-flags-right::before {
            background-image: url('../assets/images/little-fooby/backgrounds/section/flags-right.svg');
            background-repeat: no-repeat;
            background-position: right top;
        }

        &--decoration-flags-left::before {
            background-image: url('../assets/images/little-fooby/backgrounds/section/flags-left.svg');
            background-repeat: no-repeat;
        }

        &--decoration-flags-right2::before {
            background-image: url('../assets/images/little-fooby/backgrounds/section/flags-right.svg');
            background-repeat: no-repeat;
            background-position: right top;
            background-size: 145px auto;

            @media #{$medium-up} {
                background-image: url('../assets/images/little-fooby/backgrounds/section/flags-right.svg'),
                    url('../assets/images/little-fooby/backgrounds/section/cooking-hat.svg'),
                    url('../assets/images/little-fooby/backgrounds/section/wallwood.svg');
                background-position: right top, left 122px, right bottom -35px;
                background-size: auto auto, auto auto, auto auto;
            }
        }

        &--no-decoration::before {
            content: none;
        }

        &--theme-color {
            background-color: $panel-background-color;

            .image__foreground path {
                fill: $panel-background-color;
            }

            .little-fooby-l2 & {
                background-color: $panel-background-color-l2;

                .image__foreground path {
                    fill: $panel-background-color-l2;
                }
            }

            .little-fooby-l3 & {
                background-color: $panel-background-color-l3;

                .image__foreground path {
                    fill: $panel-background-color-l3;
                }
            }
        }
    }

    &--pale-green {
        background-color: $color-light-pale-green;

        .image__foreground path {
            fill: $color-light-pale-green;
        }
    }

    &--pale-orange {
        background-color: $color-pale-orange;

        .image__foreground path {
            fill: $color-pale-orange;
        }
    }

    &--pale-blue {
        background-color: $color-pale-blue;

        .image__foreground path {
            fill: $color-pale-blue;
        }
    }

    &--pale-grey {
        background-color: $color-light-grey;

        .image__foreground path {
            fill: $color-light-grey;
        }
    }

    &--pale-grey2 {
        background-color: $color-light-grey2;

        .image__foreground path {
            fill: $color-light-grey2;
        }
    }

    &--light-pale-grey {
        background-color: $color-lighter-grey;

        .image__foreground path {
            fill: $color-lighter-grey;
        }
    }

    &--custom-image {
        background: no-repeat center center / cover;
    }

    &--white {
        background-color: $color-white;
    }
}

