/* COMPONENT Page Header Recipe
##################################################################################################################### */

$border-space-mobile: rem-calc(7px);
$border-space-tablet: rem-calc(16px);
$border-space-desktop: rem-calc(20px);

@keyframes page-header-video-btn-pulse {
    0% {
        transform: scale(1);
        border-color: hsla(0, 0%, 100%, .55)
    }

    to {
        transform: scale(1.4);
        border-color: hsla(0, 0%, 100%, 0)
    }
}

.page-header-recipe {
    background-color: $panel-background-color;

    @if ($little-fooby) {
        .little-fooby-l2 & {
            background-color: $panel-background-color-l2;
        }

        .little-fooby-l3 & {
            background-color: $panel-background-color-l3;
        }
    }

    @media #{$large-up} {
        position: relative;
        // max-width: 2000px;
        margin: 0 auto;
        opacity: 0;

        &.is-loaded {
            opacity: 1;
        }
    }

    /* Overwrite share-group styles */

    .share-group {
        margin-top: rem-calc(23px);
        margin-bottom: rem-calc(-10px);
        justify-content: space-between;

        @media #{$medium-up} {
            margin-top: rem-calc(15px);
            padding-top: rem-calc(15px);
            border-top: 1px solid $color-light-grey;
        }

        @media screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1}) {
            justify-content: center;
        }

        @media #{$xlarge-up} {
            justify-content: center;
            padding-top: rem-calc(20px);
        }
    }

    .share-group__single {
        margin-bottom: rem-calc(10px);

        &:not(:last-child) {
            margin-right: rem-calc(10px);

            @media screen and (min-width: #{$screen-sm}) and (max-width: #{$screen-md - 1}) {
                margin-right: rem-calc(20px);
            }

            @media #{$xlarge-up} {
                margin-right: rem-calc(27px);
            }
        }
    }

    .share-group__single-link {
        display: flex;
        flex-direction: column;
    }

    .share-group__single-share-icon {
        margin-right: 0;
        margin-bottom: rem-calc(8px);
    }

    .share-group__single-share-copy {
        @media screen and (min-width: #{$screen-md}) and (max-width: 1699px) {
            font-size: rem-calc(10px);
        }
    }
}


.page-header-recipe__cooking-view-btn-container {
    margin-top: rem-calc(20px);
}

.page-header-recipe__info {
    @media #{$small-only} {
        padding-left: 0;
        padding-right: 0;
    }

    .row {
        @media #{$small-only} {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .col-xs-12 {
        @media #{$small-only} {
            padding-left: 0;
            padding-right: 0;
        }

        @media #{$large-up} {
            min-height: rem-calc(40px);
        }
    }
}

.page-header-recipe__wrapper {
    position: relative;
    padding: 0 0 rem-calc(20px);

    @media #{$large-up} {
        display: flex;
        align-items: center;
        // 100vh - header height - height that should be visible of the next element
        height: calc(100vh - 71px - 36px);
        height: calc(var(--vh, 1vh) * 100 - 71px - 36px);
        padding: 0 rem-calc(20px) rem-calc(20px);
        background-color: $color-white;
    }

    @media #{$xlarge-up} {
        height: calc(100vh - 101px - 36px);
        height: calc(var(--vh, 1vh) * 100 - 101px - 36px);
    }

    .container {
        background-color: $color-white;

        @media #{$medium-up} {
            background-color: transparent;
        }

        @media #{$large-up} {
            width: auto;
            padding-left: 0;
            padding-right: 0;
            max-width: initial;
            margin-left: -48px;

            @if ($little-fooby) {
                [data-lines="3"] & {
                    margin-top: 80px;
                }

                [data-lines="2"] & {
                    margin-top: 64px;
                }

                [data-lines="1"] & {
                    margin-top: 40;
                }
            }
        }
    }

    .row {
        @media #{$large-up} {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.page-header-recipe__image-floater {
    background-color: $panel-background-color;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.page-header-recipe__image-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page-header-recipe__image-container-inner {
    flex-grow: 1;
    padding-top: 66.666%;
    position: relative;
    width: 100%;

    @media #{$large-up} {
        padding-top: 0;
    }
}

.page-header-recipe__image {
    @extend %header-image;
    z-index: 2;
    object-position: center center;
}

@if ($little-fooby == false) {
    .page-header-recipe__image-container-inner::before {
        @extend %imageborder;
    }
}

.page-header-recipe__panel {
    @media #{$large-up} {
        min-width: 410px;
        max-width: 650px;
    }
}

.page-header-recipe__special-col {
    @media #{$large-up} {
        position: static;
        width: auto;
        padding-left: 0;
        padding-right: 0;
        float: none;
    }
}


.page-header-recipe__panel-detail,
.page-header-recipe__panel-inner {
    @if ($little-fooby) {
        background-size: 100% 100%;
    } @else {
        background-color: $color-white;
    }
}

.page-header-recipe__panel-detail {
    position: relative;
    z-index: 10;

    @if ($little-fooby) {
        margin-top: rem-calc(-24px);
        padding: rem-calc(85px) rem-calc(10px) rem-calc(16px);
        background-image: url('../assets/images/little-fooby/backgrounds/recipe-panel.svg');
    } @else {
        margin-top: rem-calc(-17px);
        padding: rem-calc(10px) rem-calc(10px) rem-calc(16px);
    }

    @if ($little-fooby) {
        .title-lines {
            top: rem-calc(-65px);
            left: 50%;
            transform: rotate(-5deg) translateX(-50%);

            @media #{$medium-up} {
                top: rem-calc(-135px);
            }

            @media #{$large-up} {
                top: rem-calc(-124px);
                left: rem-calc(-40px);
                transform: rotate(-5deg);
            }

            &__line {
                line-height: 1;
                padding-left: rem-calc(18px);
                padding-right: rem-calc(24px);

                @media #{$medium-up} {
                    padding-left: rem-calc(22px);
                    padding-right: rem-calc(28px);
                }
            }

            &__line--1 {
                font-size: rem-calc(35px);
                margin-bottom: rem-calc(-40px);
                margin-left: rem-calc(24px);
                padding-top: rem-calc(10px);
                padding-bottom: rem-calc(14px);
                color: $color-lf-magenta;

                @media #{$medium-up} {
                    font-size: rem-calc(50px);
                    margin-bottom: rem-calc(-50px);
                    margin-left: rem-calc(28px);
                    padding-top: rem-calc(14px);
                    padding-bottom: rem-calc(18px);
                }

                .little-fooby-l3 & {
                    color: $color-lf-yellow-light;
                }

                &::before {
                    background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level1-line1.svg');

                    .little-fooby-l2 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level2-line1.svg');
                    }

                    .little-fooby-l3 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level3-line1.svg');
                    }
                }
            }

            &__line--2 {
                margin-bottom: rem-calc(-45px);

                @media #{$medium-up} {
                    margin-bottom: rem-calc(-50px);
                }

                &::before {
                    background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level1-line2.svg');

                    .little-fooby-l2 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level2-line2.svg');
                    }

                    .little-fooby-l3 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level3-line2.svg');
                    }
                }
            }

            &__line--3 {
                margin-left: rem-calc(24px);

                @media #{$medium-up} {
                    margin-left: rem-calc(28px);
                }

                &::before {
                    background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level1-line2.svg');

                    .little-fooby-l2 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level2-line2.svg');
                    }

                    .little-fooby-l3 & {
                        background-image: url('../assets/images/little-fooby/backgrounds/recipe-header-title-lines/level3-line2.svg');
                    }
                }
            }

            &__line--2,
            &__line--3 {
                font-size: rem-calc(55px);
                color: #FFFFFF;
                padding-top: rem-calc(20px);
                padding-bottom: rem-calc(20px);

                @media #{$medium-up} {
                    font-size: rem-calc(80px);
                    padding-top: rem-calc(24px);
                    padding-bottom: rem-calc(24px);
                }
            }

            &[data-title-lines-count="1"] {
                top: rem-calc(-55px);

                @media #{$medium-up} {
                    top: rem-calc(-120px);
                }

                @media #{$large-up} {
                    top: rem-calc(-98px);
                }
            }

            &[data-title-lines-count="3"] {
                top: rem-calc(-115px);

                @media #{$medium-up} {
                    top: rem-calc(-175px);
                }

                @media #{$large-up} {
                    top: rem-calc(-147px);
                }

                .title-lines__line--1 {
                    font-size: rem-calc(35px);
                    margin-bottom: rem-calc(-40px);
                    margin-left: rem-calc(20px);
                    padding-top: rem-calc(15px);
                    padding-bottom: rem-calc(20px);

                    @media #{$medium-up} {
                        font-size: rem-calc(45px);
                        margin-bottom: rem-calc(-40px);
                    }

                    @media #{$large-up} {
                        font-size: rem-calc(45px);
                        margin-bottom: rem-calc(-40px);
                    }
                }

                .title-lines__line--2 {
                    margin-bottom: rem-calc(-40px);

                    @media #{$medium-up} {
                        margin-bottom: rem-calc(-40px);
                    }
                }

                .title-lines__line--2,
                .title-lines__line--3 {
                    font-size: rem-calc(50px);
                    padding-top: rem-calc(20px);
                    padding-bottom: rem-calc(20px);

                    @media #{$medium-up} {
                        font-size: rem-calc(55px);
                        padding-top: rem-calc(20px);
                        padding-bottom: rem-calc(20px);
                    }
                }
            }
        }

        &.big-padded .title-lines {
            @media #{$medium-up} {
                top: rem-calc(-150px);
            }

            &[data-title-lines-count="1"] {
                top: rem-calc(-55px);

                @media #{$medium-up} {
                    top: rem-calc(-135px);
                }

                @media #{$large-up} {
                    top: rem-calc(-116px);
                }
            }

            &[data-title-lines-count="3"] {
                top: rem-calc(-115px);

                @media #{$medium-up} {
                    top: rem-calc(-175px);
                }

                @media #{$large-up} {
                    top: rem-calc(-177px);
                }
            }
        }
    }

    @media #{$medium-up} {
        @if ($little-fooby) {
            margin-top: rem-calc(60px);
            padding: rem-calc(60px) rem-calc(25px) rem-calc(30px);
        } @else {
            margin-top: rem-calc(-63px);
            padding: rem-calc(20px) rem-calc(38px) rem-calc(25px);
        }
    }

    @media #{$large-up} {
        @if ($little-fooby) {
            margin-top: rem-calc(60px);
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-panel-large.svg');

            &.big-padded {
                margin-top: rem-calc(80px);
            }
        } @else {
            margin-top: 0;
            border: 2px solid $color-light-grey;
        }
    }

    @media screen and (min-width: #{$screen-md}) and (max-width: 1023px) {
        // width: rem-calc(300px);

        @if ($little-fooby) {
            padding: rem-calc(46px) rem-calc(10px) rem-calc(6px);
        } @else {
            padding: rem-calc(6px) rem-calc(10px);
        }

        &.big-padded {
            @if ($little-fooby) {
                padding-top: rem-calc(65px);
            } @else {
                padding: rem-calc(55px) rem-calc(10px) rem-calc(6px);
            }
        }
    }

    @media screen and (min-width: 1024px) and (max-width: #{$screen-lg - 1}) {
        // width: rem-calc(350px);

        @if ($little-fooby) {
            padding: rem-calc(55px) rem-calc(16px) rem-calc(15px);
        } @else {
            padding: rem-calc(15px) rem-calc(16px);
        }

        &.big-padded {
            @if ($little-fooby) {
                padding-top: rem-calc(70px);
            } @else {
                padding: rem-calc(60px) rem-calc(10px) rem-calc(15px);
            }
        }
    }

    @media #{$xlarge-up} {
        // width: 23vw;

        &.big-padded {
            @if ($little-fooby) {
                padding-top: rem-calc(80px);
            } @else {
                padding: rem-calc(70px) rem-calc(35px) rem-calc(30px) rem-calc(23px);
            }
        }
    }

    @extend %headerpanel-title;

    @media screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
        h1 {
            margin-bottom: rem-calc(15px);
        }
    }

    @media screen and (min-width: #{$screen-lg}) and (max-width: rem-calc(1699px)) {
        h1 {
            margin-bottom: rem-calc(20px);
        }
    }

    @media screen and (min-width: rem-calc(1700px)) {
        h1 {
            margin-bottom: rem-calc(30px);
        }
    }
}

.page-header-recipe__panel-tags {
    text-align: center;
    margin-bottom: rem-calc(5px);

    .tag {
        display: inline-block;

        @if ($little-fooby) {
            margin: 0 rem-calc(8px) rem-calc(15px);
        } @else {
            margin: 0 rem-calc(6px) rem-calc(15px);
        }
    }

    @media #{$large-up} {
        text-align: left;
    }

    @media screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
        .tag {
            margin: 0 rem-calc(8px) rem-calc(10px) 0;
        }
    }

    @media #{$xlarge-up} {
        .tag {
            @if ($little-fooby) {
                margin: 0 rem-calc(8px) rem-calc(15px) 0;
            } @else {
                margin: 0 rem-calc(13px) rem-calc(15px) 0;
            }
        }
    }
}

.page-header-recipe__meta-container-big {
    display: flex;
    justify-content: space-around;
    border-top: 1px solid $color-light-grey;
    border-bottom: 1px solid $color-light-grey;
    text-align: center;
    margin-top: rem-calc(5px);
    margin-bottom: rem-calc(11px);

    .meta-info-big {
        flex-grow: 1;
        padding: rem-calc(9px) rem-calc(7px);

        &:not(:last-child) {
            border-right: 1px solid $color-light-grey;
        }

        &__value {
            display: block;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: rem-calc(30px);
            line-height: 1;
        }

        &__time {
            line-height: 1;
            font-size: rem-calc(14px);
        }
    }
}

.page-header-recipe__meta-container {
    padding: rem-calc(5px) 0 rem-calc(0px);
    max-width: rem-calc(320px);
    margin: 0 auto;

    .meta-info {
        margin-bottom: rem-calc(8px);
    }

    @media #{$medium-up} {
        max-width: rem-calc(420px);
    }

    @media #{$large-up} {
        max-width: rem-calc(420px);
        margin: 0;

        .meta-info {
            margin-bottom: rem-calc(10px);
        }
    }

    @media screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
        padding: rem-calc(5px) 0 0;
    }
}

.page-header-recipe__panel-inner {
    padding: rem-calc(18px) rem-calc(16px) rem-calc(30px);
    margin-bottom: rem-calc(20px);

    @if ($little-fooby) {
        background-size: 100% 100%;
        background-image: url('../assets/images/little-fooby/backgrounds/header-panel.svg');
    }

    @media #{$medium-up} {
        padding: rem-calc(28px) rem-calc(30px);

        @if ($little-fooby) {
            background-image: url('../assets/images/little-fooby/backgrounds/header-panel-medium.svg');
        }
    }

    @media #{$large-up} {
        width: rem-calc(250px);
        padding: rem-calc(38px) rem-calc(30px);
        margin-top: rem-calc(20px);
        width: 100%;
        overflow: visible;
        position: relative;

        &::before {
            content: " ";
            position: absolute;
            height: 100%;
            width: 4000px;
            left: 50%;
            top: 00px;
            transform: translateX(-50%);
            z-index: 0;
            background-color: $panel-background-color;

            @if ($little-fooby) {
                .little-fooby-l2 & {
                    background-color: $panel-background-color-l2;
                }

                .little-fooby-l3 & {
                    background-color: $panel-background-color-l3;
                }
            }
        }

        &::after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 0;

            @if ($little-fooby) {
                background-size: 100% 100%;
                background-image: url('../assets/images/little-fooby/backgrounds/header-panel.svg');
            } @else {
                background-color: $color-white;
            }

            @media #{$medium-up} {
                @if ($little-fooby) {
                    background-image: url('../assets/images/little-fooby/backgrounds/header-panel-medium.svg');
                }
            }
        }

        > * {
            position: relative;
            z-index: 2;
        }
    }
}

//.page-header-recipe__panel-table {
//    @media #{$large-up} {
//        display: table;
//        width: 100%;
//        position: relative;
//    }
//}



//.flex-item:nth-child(1) {
//    order: 1;
//    flex: 1 1 250px;
//    align-self: auto;
//    }
//
//.flex-item:nth-child(2) {
//    order: 1;
//    flex: 1 1 300px;
//    align-self: auto;
//    }
//


$authorboxwidth: 250px;
$temp: rem-calc($authorboxwidth);

.page-header-recipe__intro {
    p {
        @extend %paragraph--copy-intro;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .readmore + .btn-group {
        margin-top: rem-calc(20px);
    }

    //@media #{$large-up} {
    //    width: calc(100% - #{$temp});
    //    display: table-cell;
    //    vertical-align: middle;
    //    position: relative;
    //    left: #{$temp};
    //}
}

.page-header-recipe__author {
    display: table;
    margin: rem-calc(20px) auto 0;

    //@media #{$large-up} {
    //    width: $temp;
    //    display: table-cell;
    //    vertical-align: middle;
    //    position: relative;
    //    right: calc(100% - #{$temp});
    //    padding-right: rem-calc(15px);
    //}
}

@if ($little-fooby) {
    .page-header-recipe__level {
        text-align: center;

        svg {
            width: rem-calc(19px);
            height: rem-calc(20px);

            @media #{$large-up} {
                width: auto;
                height: auto;
            }
        }
    }

    .page-header-recipe__level-title {
        margin-top: rem-calc(-2px);
        margin-bottom: 0;
    }
}

@media #{$large-up} {
    .page-header-recipe__panel-table {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
    }

    .page-header-recipe__intro {
        order: 2;
        flex: 1 1 auto;
        align-self: auto;
    }

    .page-header-recipe__author {
        order: 1;
        flex: 0 0 250px;
        width: 250px;
        padding-right: rem-calc(15px);
        align-self: auto;
        margin-top: 0;
    }

    @-moz-document url-prefix() {
        .page-header-recipe__panel-table {
            width: 100%;
            -moz-box-sizing: border-box;
        }
    }
}

@if ($little-fooby) {
    .page-header-recipe__panel-table--little-fooby {
        display: flex;
        flex-direction: column;

        @media #{$large-up} {
            flex-direction: row;
        }

        .page-header-recipe__level {
            border-bottom: 1px solid $color-dark-offwhite;
            padding-left: rem-calc(50px);
            padding-right: rem-calc(50px);
            padding-bottom: rem-calc(22px);
            margin-bottom: rem-calc(22px);
            align-self: center;

            @media #{$large-up} {
                flex-basis: rem-calc(345px);
                order: 1;
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .page-header-recipe__intro {
            @media #{$large-up} {
                order: 2;
                border-left: 1px solid $color-dark-offwhite;
                padding-left: rem-calc(40px);
            }
        }
    }
}


/* Modifiers
##################################################################################################################### */

.page-header-recipe--own-recipe {

    background-color: transparent;

    .page-header-recipe__meta-container {
        max-width: rem-calc(175px);
        margin: 0 auto;

        @media #{$medium-up} {
            max-width: rem-calc(175px);
        }

        @media #{$large-up} {
            max-width: rem-calc(420px);
            margin: 0;
        }
    }
}

.page-header-recipe__video {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    @if ($little-fooby) {
        top: calc(50% - 35px);

        @media #{$medium-up} {
            top: 50%;
        }
    }
    @else {
        top: 50%;
    }
}

.page-header-recipe__video-btn {
    position: relative;

    @if ($little-fooby) {
        background-image: url('../assets/images/little-fooby/backgrounds/video-play.svg');
        background-size: 100% 100%;
        background-color: transparent;
        border: none;
        width: rem-calc(103px);
        height: rem-calc(97px);

        @media #{$medium-up} {
            width: rem-calc(130px);
            height: rem-calc(122px);
        }
    }
    @else {
        width: rem-calc(78px);
        height: rem-calc(78px);
        background-color: rgba(0, 0, 0, 0.3);
        border: 2px solid #fff;
        border-radius: 100%;
        transition: background-color $animation-time-default $animation-easing-default;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: rem-calc(3px);

        @if ($little-fooby) {
            .animated {
                fill: $color-lf-cyan;

                .little-fooby-l2 & {
                    fill: $color-lf-green;
                }

                .little-fooby-l3 & {
                    fill: $color-lf-magenta;
                }
            }
        }
    }

    &:hover,
    &:focus {
        @if ($little-fooby) {
            .animated {
                fill: $color-lf-cyan-dark;

                .little-fooby-l2 & {
                    fill: $color-lf-green-dark;
                }

                .little-fooby-l3 & {
                    fill: $color-lf-magenta-dark;
                }
            }
        }
        @else {
            background-color: rgba(0,0,0,0.5);
        }
    }
}

@if ($little-fooby == false) {
    .page-header-recipe__video-btn-pulse {
        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        border-radius: 100%;
        border: 2px solid hsla(0, 0%, 100%, 0);
        animation-name: page-header-video-btn-pulse;
        animation-iteration-count: infinite;
        animation-play-state: running;
        animation-duration: 3.2s;
    }
}

@if ($little-fooby) {
    .page-header-recipe__image-deco-wrapper {
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: $border-space-mobile;

        @media #{$medium-up} {
            padding: $border-space-tablet;
        }

        @media #{$large-up} {
            padding: $border-space-desktop;
        }
    }
}
