.ss-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    float: left;
}

.ss-content {
    height: 100%;
    width: 100%;
    padding: 0 32px 0 0;
    position: relative;
    right: -18px;
    overflow: auto;
    box-sizing: border-box;
}

.ss-scroll {
    position: relative;
    background: rgba(0, 0, 0, .1);
    width: 9px;
    border-radius: 4px;
    top: 0;
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    transition: opacity .25s linear;
}

.ss-hidden .ss-scroll,
.ss-hidden .ss-wrapper::after {
    display: none;
}

.ss-grabbed {
    user-select: none;
}
