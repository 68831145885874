.illustration-download {
    padding-top: rem-calc(21px);

    .btn {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -21px);
    }

    &__content {
        position: relative;
    }
}