/* COMPONENT footer-meta-nav
##################################################################################################################### */

// sass-lint:disable-all

.footer-meta-nav {
    text-align: center;

    @media #{$medium-up} {
        text-align: left;
    }
}

.footer-meta-nav__list {
    @extend %unstyled-list;
    line-height: 1;

    > li {
        display: inline-block;
    }

    @media #{$medium-up} {
        display: block;
        float: left;
    }
}

.footer-meta-nav__item {
    &:not(:first-child) {
        margin-left: rem-calc(24px);
    }

    @media #{$medium-up} {
        &:not(:first-child) {
            margin-left: rem-calc(30px);
        }
    }
}

.footer-meta-nav__link {
    color: $color-white;
    text-decoration: none;
    font-size: rem-calc(14px);
    display: block;
    line-height: rem-calc(30px);
    transition: color $animation-time-default $animation-easing-default;
}

.footer-meta-nav__link:hover,
.footer-meta-nav__link:active,
.footer-meta-nav__link:focus {
    color: $color-stone-grey;
    text-decoration: none;
}
