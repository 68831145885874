/* COMPONENT suggested-results
##################################################################################################################### */

.suggested-results {
    padding-bottom: rem-calc(24px);
}

.suggested-results__column-title {
    display: inline-block;
    font-weight: bold;
    font-size: rem-calc(14px);
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: rem-calc(16px);
}

.suggested-results__column-title--inactive {
    color: $color-stone-grey;
}

.suggested-results__show-all {
    display: block;
    font-family: $font-family-montserrat;
    font-weight: bold;
    font-size: rem-calc(9px);
    color: $color-stone-grey;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
        color: $color-stone-grey;
    }
}

.suggested-article {
    display: block;
    margin-bottom: rem-calc(20px);
    opacity: 1;
    transition: opacity $animation-time-fast $animation-easing-fast;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: inherit;
    }

    .bd {
        font-family: $font-family-roboto;
        font-size: rem-calc(14px);
        line-height: (18 / 14);
    }
}

.suggested-article--hidden {
    opacity: 0;
}

.suggested-article__type {
    display: block;
    margin-top: rem-calc(-2px);
    margin-bottom: rem-calc(1px);
    color: $headings-suggested-color;
    text-transform: uppercase;
    font-family: $font-family-open-sans;
    font-size: rem-calc(10px);
    letter-spacing: rem-calc(0.93px);
    font-weight: bold;
    line-height: 1;
}

.suggested-article__thumbnail {
    position: relative;
    width: rem-calc(60px);
    height: rem-calc(60px);
    overflow: hidden;
}

.suggested-article__thumbnail-placeholder {
    position: relative;
    padding-bottom: calc-ratio(120, 120);
    width: 100%;
    background-color: $color-light-grey;
    z-index: 1;

    &::after {
        content: " ";
        border: 1px solid darken($color-light-grey, 1%);
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        position: absolute;
    }
}

.suggested-article__thumbnail-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
}

.suggested-article__thumbnail-foreground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    svg path {
        fill: $panel-background-color;
    }
}