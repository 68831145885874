/* COMPONENT Image
##################################################################################################################### */

.fooby-image {
    overflow: hidden;
    position: relative;

    &__decoration-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: rem-calc(3px);

        @media #{$medium-up} {
            padding: rem-calc(6px);
        }

        @media #{$large-up} {
            padding: rem-calc(10px);
        }
    }

    &__imgContainer {
        @if ($little-fooby==false) {
            &::before {
                @extend %imageborder;
                z-index: 0;
            }
        }
    }

    &__img {
        width: 100%;
        display: block;
    }
}
