/* COMPONENT Functions Link
##################################################################################################################### */

.functions-link {
    color: $color-stone-grey;
    transition: color $animation-time-default $animation-easing-default;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.functions-link__icon {
    text-align: center;
    display: inline-block;
    margin-right: rem-calc(6px);
    position: relative;
    top: rem-calc(5px);
}

.functions-link__copy {
    text-align: center;
    font-size: rem-calc(11px);
    font-weight: 700;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline;

    @media #{$medium-up} {
        letter-spacing: 1px;
        font-size: rem-calc(12px);
        line-height: (14 / 12);
    }
}

.functions-link:active,
.functions-link:hover,
.functions-link:focus {
    color: $color-green;

    svg .animated {
        fill: $color-green;
    }

    svg .animated-stroke {
        stroke: $color-green;
    }
}
