/* TEMPLATE C4.10 Teaser Group Imagegallery
##################################################################################################################### */

$overlay-nav-icon-bg-size-big: rem-calc(72px);
$overlay-nav-icon-bg-size-medium: rem-calc(48px);
$overlay-nav-icon-bg-size-small: rem-calc(36px);

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version 1.8.2
 * @url https://github.com/feimosi/baguetteBox.js
 */

// sass-lint:disable-all
#baguetteBox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    display: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity $animation-time-default ease;

    &.visible {
        opacity: 1;
    }

    .full-image {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;

        &:before {
            display: inline-block;
            margin-right: -1px;
            width: 1px;
            height: 50%;
            content: "";
        }

        img {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            vertical-align: middle;

            @media #{$medium-up} {
                max-width: 80vw;
                max-height: 75vh;
            }

            &.height-limited {
                max-height: 100vh;

                @media #{$medium-up} {
                    max-height: 75vh;
                }
            }
        }

        figure {
            display: inline-block;
            height: auto;
            position: relative;
            vertical-align: middle;

            @media #{$small-only} {
                position: static;
            }
        }

        figcaption {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            line-height: (19 / 14);
            font-size: rem-calc(14px);
            padding: rem-calc(18px) rem-calc(30px);
            color: $color-white;
            white-space: normal;
            text-align: left;

            @media #{$medium-up} {
                padding: 0 15%;
                position: static;
                text-align: center;
                margin-top: rem-calc(30px);
                background: none; // background-reset
            }
        }
    }
}

#baguetteBox-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: left .4s ease, transform .4s ease;

    &.bounce-from-right {
        animation: bounceFromRight .4s ease-out;
    }

    &.bounce-from-left {
        animation: bounceFromLeft .4s ease-out;
    }
}

@keyframes bounceFromRight {
    0% {
        margin-left: 0;
    }
    50% {
        margin-left: rem-calc(-30px);
    }
    100% {
        margin-left: 0;
    }
}

@keyframes bounceFromLeft {
    0% {
        margin-left: 0;
    }
    50% {
        margin-left: rem-calc(30px);
    }
    100% {
        margin-left: 0;
    }
}

.baguetteBox-button {
    position: absolute;
    margin: 0;
    padding: 0;
    outline: none;
    border: 0;
    border-radius: 15%;
    background-color: transparent;
    color: #ddd;
    font: 1.6em sans-serif;
    cursor: pointer;
    transition: background-color .4s ease;

    &#next-button,
    &#previous-button {
        top: calc(50% - #{rem-calc(29px)});
        background-color: rgba(0,0,0,0.3);
        background-size: $overlay-nav-icon-bg-size-small $overlay-nav-icon-bg-size-small;
        width: $overlay-nav-icon-bg-size-small;
        height: $overlay-nav-icon-bg-size-small;
        border-radius: $overlay-nav-icon-bg-size-small;
        background-size: rem-calc(10px);

        @media #{$medium-only} {
            top: calc(50% - #{rem-calc(28px)});
            background-size: rem-calc(12px);
            width: $overlay-nav-icon-bg-size-medium;
            height: $overlay-nav-icon-bg-size-medium;
            border-radius: $overlay-nav-icon-bg-size-medium;
        }

        @media #{$large-up} {
            top: calc(50% - #{rem-calc(40px)});
            width: $overlay-nav-icon-bg-size-big;
            height: $overlay-nav-icon-bg-size-big;
            border-radius: $overlay-nav-icon-bg-size-big;
            background-size: auto;
        }
    }

    &#next-button {
        right: 2%;
        background-image: url(../assets/images/next.svg);
        background-position: center center;
        background-repeat: no-repeat;

        @media #{$small-only} {
            right: rem-calc(12px);
        }
    }

    &#previous-button {
        left: 2%;
        background-image: url(../assets/images/previous.svg);
        background-position: center center;
        background-repeat: no-repeat;

        @media #{$small-only} {
            left: rem-calc(12px);
            background-position: 48% center;
        }
    }

    &#close-button {
        top: 20px;
        right: calc(2% + #{rem-calc(6px)});
        width: rem-calc(25px);
        height: rem-calc(25px);
        background-image: url(../assets/images/close.svg);
        background-position: center center;
        background-repeat: no-repeat;

        @media #{$small-only} {
            width: rem-calc(13px);
            height: rem-calc(13px);
            right: rem-calc(20px);
        }
    }

    svg {
        display: none;
    }
}

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/

.baguetteBox-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    margin-top: rem-calc(-20px);
    margin-left: rem-calc(-20px);
    width: rem-calc(40px);
    height: rem-calc(40px);
}

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: .6;
    animation: bounce 2s infinite ease-in-out;
}

.baguetteBox-double-bounce2 {
    animation-delay: -1s;
}

@keyframes bounce {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
