.headline-image {
    text-align: center;
}

.headline-image__image {
    max-width: rem-calc(100px);
    max-height: rem-calc(100px);

    @media #{$medium-up} {
        max-width: rem-calc(120px);
        max-height: rem-calc(120px);
    }

    @media #{$large-up} {
        max-width: rem-calc(140px);
        max-height: rem-calc(140px);
    }
}
