/* COMPONENT Teaser-Service
##################################################################################################################### */

.teaser-service {
    display: block;
    background-color: $color-white;
    width: 100%;
    position: relative;

    @if ($little-fooby == false) {
        border: 10px solid $color-light-grey;
    }

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
        color: inherit;
    }

    .eventdetail-group {
        text-align: left;
        margin-top: rem-calc(22px);
        padding-bottom: rem-calc(16px);
    }

    .eventdetail-group__single-share-icon {
        width: rem-calc(14px);
    }

    .functions-group__single {
        margin-bottom: rem-calc(2px);
    }

    .functions-group {
        text-align: left;
        margin-bottom: rem-calc(24px);
    }

    .btn {
        margin-top: rem-calc(6px);
        margin-bottom: rem-calc(10px);
        margin-right: 1rem;

        &:first-child {
            margin-top: 0;
        }

        @media #{$medium-up} {
            float: left;
            margin-top: 0;
            display: block;
        }
    }

    &--background {
        // sass-lint:disable-all
        // <-- TEASER RIGHT -->
        &.teaser-service--background--right {
            .teaser-service__content-container {
                float: none;

                @media #{$medium-up} {
                    float: right;
                }
            }
        }


        // <-- TEASER CENTER -->
        &.teaser-service--background--center {
            .teaser-service__content-container {
                float: none;
                margin: auto;
                text-align: center;
                padding: rem-calc(80px) rem-calc(32px);
            }

            .teaser-service__items {
                overflow: hidden;
                display: table;
                margin: auto;
            }

            .btn {
                float: none;
                // margin-left: rem-calc(10px);
                // margin-right: rem-calc(10px);

                @media #{$medium-up} {
                    float: none;
                    margin-left: rem-calc(10px);
                    margin-right: rem-calc(10px);
                }

                @media #{$large-up} {
                    float: left;
                }
            }
        }
        /* scss-lint:enable all */

        .teaser-service__img-container {
            margin-right: 0;
        }

        .teaser-service__content-container {
            transform: none;
            background: transparent;
            width: 100%;
            float: none;
            padding: rem-calc(18px) rem-calc(32px) rem-calc(160px) rem-calc(18px);
            @media #{$medium-up} {
                width: 50%;
                float: left;
                padding: rem-calc(64px) rem-calc(32px);
            }
        }

        .teaser-service__title {
            color: white;
        }

        .teaser-service__text {
            color: white;
            line-height: 1.5;

            @media #{$medium-up} {
                padding-bottom: 0;
                line-height: 1.125;
            }
        }

    }

    /* Vertical */

    &--vertical {
        flex-grow: 1;
        display: flex;
    }

    &--vertical &__img-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-right: auto;
        min-height: initial;
    }

    &--vertical &__picture {
        display: block;
        padding-bottom: 54.15%;
        position: relative;
        height: 0;
        overflow: hidden;
    }

    &--vertical &__img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--vertical &__content-container {
        flex-grow: 1;
        float: none;
        transform: none;
        width: 100%;
        padding: rem-calc(18px) rem-calc(18px) rem-calc(24px);

        @media #{$medium-up} {
            padding-left: rem-calc(24px);
            padding-right: rem-calc(24px);
        }
    }
}

.teaser-service--faded {
    background-color: rgba($color-white, .8);

    .teaser-service__content-container {
        background: transparent;
    }
}

.teaser-service--nomobileimage {
    .teaser-service__picture {
        display: none;

        @media #{$medium-up} {
            display: block;
        }
    }
}

.teaser-service__headline {
    text-align: center;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: rem-calc(38px);
    line-height: 1.325;
    padding-bottom: rem-calc(4px);
}

.teaser-service__picture {
    .teaser-service__img {
        width: 100%;
        object-fit: cover;

        @media #{$medium-up} {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.teaser-service__img {
    width: 100%;
    @media #{$medium-up} {
        display: none;
    }
}

.teaser-service__img-container {
    display: block;
    margin-right: 0;
    min-height: rem-calc(160px);
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    @media #{$medium-up} {
        margin-right: 42%;
        min-height: rem-calc(300px);
    }

    @media #{$large-up} {
        margin-right: 50%;
        min-height: rem-calc(300px);
    }

    &:after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
    }
}

.teaser-service__background {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: none;
    position: absolute;

    &.small {
        display: block;
        @media #{$medium-up} {
            display: none;
        }
    }

    &.medium {
        @media #{$medium-up} {
            display: block;
        }
        @media #{$large-up} {
            display: none;
        }
    }

    &.large {
        @media #{$large-up} {
            display: block;
        }
    }
}

.teaser-service__content-container {
    order: 0;
    width: 100%;
    padding: rem-calc(18px) rem-calc(18px) rem-calc(24px);
    display: flex;
    align-self: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: space-between;
    align-items: stretch;
    background-color: white;
    position: relative;

    @media #{$medium-up} {
        float: right;
        transform: translateX(100%);
        padding: rem-calc(22px) rem-calc(16px) rem-calc(22px) rem-calc(22px);
        width: 72.5%;
    }

    @media #{$large-up} {
        width: 100%;
        float: right;
        transform: translateX(100%);
        padding: rem-calc(80px) rem-calc(64px) rem-calc(80px) rem-calc(32px);
    }
}

.teaser-service__content-container-positioner {
    flex: 0 1 auto;
    width: 100%;
}

.teaser-service__title {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: rem-calc(28px);
    line-height: 1.325;

    @if ($little-fooby) {
        font-size: rem-calc(36px);
        line-height: 1.055;
        margin-bottom: rem-calc(20px);
    } @else {
        font-size: rem-calc(28px);
        line-height: 1.325;
        padding-bottom: rem-calc(4px);
    }

    @media #{$medium-up} {
        font-size: 2em;
        line-height: 1.1;
    }
    @media #{$large-up} {
        font-size: 2.75em;
        line-height: 1.1;
    }

    &.mobile-center {
        text-align: center;
        @media #{$medium-up} {
            text-align: left;
        }
    }
}

.teaser-service__subtitle {
    font-family: "Roboto Slab";
    font-size: 1em;
    line-height: 1;
    font-weight: 400;
    margin: rem-calc(-8px) 0 0;
    @media #{$medium-up} {
        margin-top: 0;
    }

    &.mobile-center {
        text-align: center;
        @media #{$medium-up} {
            text-align: left;
        }
    }
}

.teaser-service__text {
    line-height: 1.428;
    padding-bottom: 0;
    font-size: rem-calc(14px);

    @if ($little-fooby) {
        margin-bottom: rem-calc(38px);
    } @else {
        margin-bottom: 1.5rem;
    }

    @media #{$large-up} {
        font-size: rem-calc(16px);
           line-height: 1.5;
    }

    &.mobile-center {
        text-align: center;
        @media #{$medium-up} {
            text-align: left;
        }
    }
}

.teaser-service__items {
    overflow: hidden;
    display: table;
    @media #{$medium-up} {
        display: table;
    }

    // sass-lint:disable-all
    .social-media-list {
        @media #{$medium-up} {
            width: 100% !important;
            top: 0 !important;
            margin-top: rem-calc(6px) !important;
            margin-bottom: 0;
        }
        @media #{$large-up} {
            width: calc(100% - 75px) !important;
            margin-top: rem-calc(12px);
            margin-bottom: 0;
        }
    }
    /* scss-lint:enable all */

    &.teaser-service__items--swiper {
        width: 100%;
        text-align: center;
        margin-top: 1em;

        .teaser-service__btn {
            margin: auto;
            float: none;
            display: table;
        }
    }
}

.teaser-service__btn {
    text-transform: uppercase;
    padding: rem-calc(18px) rem-calc(20px);
    background-color: $color-green;
    color: white;
    font-size: .6875rem;
    cursor: pointer;
    border: 2px solid transparent;
    font-family: "Montserrat";
    letter-spacing: .025em;
    display: table;
    margin-top: rem-calc(10px);
    margin-bottom: rem-calc(8px);

    &:first-child {
        margin-top: 0;
    }

    @media #{$medium-up} {
        float: left;
        margin-right: 1.25rem;
        margin-top: 0;
        display: block;
    }

    &:after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
    }

    &.secondary {
        background-color: transparent;
        border-color: $color-green;
        color: $color-green;

        &:hover {
            color: $color-green;
        }
    }

    &:hover {
        text-decoration: none;
        color: white;
    }
}

.teaser-service--appdownload {
    .app-buttons__btn {
        max-width: rem-calc(134px);
    }
}

@if ($little-fooby) {
    .teaser-service--appdownload,
    .teaser-service--newsletter {
        &::after {
            content: '';
            pointer-events: none;
            position: absolute;
            z-index: 3;
            top: rem-calc(-9px);
            left: rem-calc(-1px);
            width: calc(100% + #{rem-calc(6px)});
            height: calc(100% + #{rem-calc(7px)});
            background-size: 100% 100%;
            background-image: url('../assets/images/little-fooby/service-teaser-dots-app.svg');

            @media #{$medium-up} {
                top: rem-calc(12px);
                left: rem-calc(10px);
                height: calc(100% + #{rem-calc(4px)});
                background-image: url('../assets/images/little-fooby/service-teaser-dots-medium.svg');
            }
        }
    }

    .teaser-service--newsletter {
        &::after {
            background-image: url('../assets/images/little-fooby/service-teaser-dots-newsletter.svg');

            @media #{$medium-up} {
                width: calc(100% - #{rem-calc(4px)});
                left: rem-calc(-17px);
                transform: scaleX(-1);
                background-image: url('../assets/images/little-fooby/service-teaser-dots-medium.svg');
            }
        }
    }
}

.teaser-service--social {
    .teaser-service__items {
        display: table;
        margin: auto;
        max-width: 100%;
        @media #{$medium-up} {
            width: auto;
            display: block;
        }
    }

    .social-media {
        margin-bottom: 1rem;
    }

    .social-media-list__item {
        padding: 0 rem-calc(4px);

        @media #{$medium-up} {
            padding: 0;
        }
    }
}

.teaser-service--newsletter {
    &.teaser-service--onlybutton {
        .btn {
            margin: auto;

            @media #{$medium-up} {
                margin: 0;
            }
        }
    }

    // sass-lint:disable-all
    .teaser-service__items {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .item {
            order: 0;
            flex: 1 1 auto;
            align-self: auto;
            margin: 0;
            vertical-align: top;

            &:last-child {
                flex: 0 1 auto;
            }

            .teaser-service__btn {
                margin-right: 0;
                border-width: 0;
                letter-spacing: .05em;
                font-size: rem-calc(12px);
            }

            input {
                border: 1px solid $color-light-grey;
                color: $color-dark-stone-grey;
                border-right: 0;
                width: 100%;
            }
        }
    }
    /* scss-lint:enable all */
}

.teaser-service--swiper {
    padding: 0;
    position: relative;
    @media #{$medium-up} {
        padding: rem-calc(40px);
    }
    @media #{$large-up} {
        padding: rem-calc(32px);
    }

    .slide__image {
        width: 100%;
        flex: 0 1 auto;
    }

    .teaser-service__img {
        @media #{$medium-up} {
            display: block;
        }
    }


    .teaser-service__swiperbtn {
        position: absolute;
        top: 50%;
        width: rem-calc(14px);
        height: rem-calc(44px);
        z-index: 10;
        cursor: pointer;
        @media #{$medium-up} {
            width: rem-calc(24px);
            height: rem-calc(44px);
        }

        &.swiper-button-disabled {
            display: none;
        }

        svg {
            transform: translate(-50%,-50%);
            position: absolute;
            top: 50%;
            left: 50%;
        }

        &.prev {
            left: rem-calc(-10px);
            transform: translate(-100%, -50%);
            @media #{$medium-up} {
                left: rem-calc(-20px);
                transform: translate(-100%, -50%);
            }
        }

        &.next {
            right: rem-calc(-10px);
            transform: translate(100%, -50%);
            @media #{$medium-up} {
                right: rem-calc(-20px);
                transform: translate(100%, -50%);
            }
        }
    }

    &[data-amount="2"] {
        .swiper-slide {
            @media #{$large-up} {
                flex-direction: row;
            }
        }

        .swiper-slide__title {
            @media #{$large-up} {
                padding-top: 0;
            }
        }

        .slide__image {
            @media #{$large-up} {
                width: 50%;
                flex: 0 1 50%;
                padding-right: 1em;
            }
        }

        .swiper-slide__content {
            @media #{$large-up} {
                flex: 0 1 50%;
            }
        }
    }
}
