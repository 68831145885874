/* TEMPLATE T5 Rezept
##################################################################################################################### */

.t5-recipe {
    overflow: hidden;

    &__detail-left {
        @media #{$medium-up} {
            padding-right: rem-calc(31px); // 30px + 1px border
            // border-right: 1px solid $color-dark-offwhite;

            @if ($little-fooby == false) {
                padding-bottom: rem-calc(30px);

                &::after {
                    content: " ";
                    height: calc(100% - 30px);
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                    background: $color-dark-offwhite;
                }
            }
        }

        // has to overwrite default again due to mediaquery
        @media #{$large-up} {
            padding-right: rem-calc(31px); // 30px + 1px border
        }
    }

    &__detail-right {
        @media #{$medium-up} {
            padding-left: rem-calc(30px); // 30px + 1px border

            @if ($little-fooby == false) {
                &::before {
                    content: " ";
                    height: calc(100% - 30px);
                    position: absolute;
                    left: -1px;
                    top: 0;
                    width: 1px;
                    background: $color-dark-offwhite;
                }
            }
        }

        // has to overwrite default again due to mediaquery
        @media #{$large-up} {
            padding-left: rem-calc(30px); // 30px + 1px border
        }
    }

    &__line {
        height: 1px;
        background: $color-dark-offwhite;
        margin-bottom: rem-calc(15px);

        &--spaced {
            margin-top: rem-calc(18px);
            margin-bottom: rem-calc(30px);
        }
    }

    &__small-spacer {
        height: rem-calc(20px);
    }


    @if ($little-fooby) {
        &__recipe-utils {
            text-align: center;
        }

        &__recipe-container {}

        &__bottom {
            margin-top: rem-calc(37px);
            margin-bottom: rem-calc($container-padding-large);

            .btn {
                @media #{$small-only} {
                    display: none;
                }
            }
        }

        &__bottom .share-group {
            margin-top: rem-calc(33px);
        }

        &__column-line {
            position: absolute;
            height: 100%;
            left: 33.333%;
        }

        &__detail-right-title {
            margin-bottom: rem-calc(7px);
        }

        &__detail-right-description {
            font-size: rem-calc(14px);
            font-style: italic;
            margin-bottom: rem-calc(16px);

            @media #{$medium-up} {
                margin-bottom: rem-calc($gutter-medium);
            }
        }

        /* Title row */

        &__title-row &__detail-left {
            padding-bottom: rem-calc($gutter-medium - 5px);

            @media #{$medium-up} {
                padding-bottom: 0;
            }
        }

        &__title-row &__detail-right {
            border-top: 1px solid $color-dark-offwhite;
            padding-top: rem-calc($gutter-medium);
            padding-bottom: 0;

            @media #{$medium-up} {
                border-top: none;
                padding-top: 0;
            }
        }

        &__title-row &__ingredient-overview + * {
            margin-top: rem-calc(18px);
        }

        &__title-row .recipe-ingredientlist__step-wrapper {
            margin-bottom: rem-calc(14px);

            @media #{$medium-up} {
                margin-bottom: 0;
            }
        }

        /* Steps */

        &__steps {
            counter-reset: t5-recipe-row-line;
            margin-top: rem-calc($container-padding-large);

            @media #{$medium-up} {
                margin-top: 0;
            }
        }

        &__steps &__row {
            padding-top: rem-calc(33px);
            padding-bottom: rem-calc(45px);

            @media #{$medium-up} {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        &__steps &__detail-left,
        &__steps &__detail-right {
            @media #{$medium-up} {
                margin-top: rem-calc($gutter-medium);
                margin-bottom: rem-calc($gutter-medium);
            }
        }

        &__steps &__detail-left {
            margin-top: rem-calc(-5px);

            @media #{$medium-up} {
                margin-top: rem-calc($gutter-medium);
            }
        }

        &__row-line,
        &__steps &__detail-right {
            &::before {
                font-family: $font-family-northernsoul-caps;
                font-size: rem-calc(30px);
                line-height: 1;
                position: absolute;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                transform: translate(-50%, -50%);
                width: rem-calc(50px);
                height: rem-calc(50px);
                content: counter(t5-recipe-row-line);
                background-image: url('../assets/images/little-fooby/backgrounds/counter.svg');
                background-size: 100% 100%;
                background-position: center center;
                background-repeat: no-repeat;
                left: 50%;

                @media #{$medium-up} {
                    display: none; // show before element from __detail-right
                }
            }
        }

        &__steps &__detail-right {
            margin-top: rem-calc(6px);

            @media #{$medium-up} {
                margin-top: rem-calc($gutter-medium);
            }

            &::before {
                display: none; // show before element from __row-line on mobile
                top: 0;
                left: rem-calc(-30px);
                margin-top: rem-calc(-$gutter-medium);

                @media #{$medium-up} {
                    display: inline-flex;
                }
            }
        }

        &__steps &__detail-image {
            margin-top: rem-calc(20px);
            margin-bottom: 0px;

            @media #{$medium-up} {
                margin-top: rem-calc(24px);
                margin-bottom: rem-calc(26px);
            }

            .image__placeholder {
                padding-top: calc-ratio(242, 174);
            }
        }

        &__row-line {
            display: flex;
            counter-increment: t5-recipe-row-line;
            border-top: 1px solid $color-dark-offwhite;

            &--kid-step {
                & + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-cyan-dark;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l1.svg');
                }

                .little-fooby-l2 & + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-green-dark2;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l2.svg');
                }

                .little-fooby-l3 & + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-magenta-dark;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l3.svg');
                }

                &.little-fooby-l1 + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-cyan-dark;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l1.svg');
                }

                &.little-fooby-l2 + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-green-dark2;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l2.svg');
                }

                &.little-fooby-l3 + .t5-recipe__row .t5-recipe__detail-right::before {
                    color: $color-lf-magenta-dark;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l3.svg');
                }
            }
        }
    }
}
