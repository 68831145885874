.bookmark-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .cookbook-add {
        margin-bottom: rem-calc(20px);
    }

    .mini-modal__flex-flex {
        display: flex;
        overflow: hidden;
    }

    .mini-modal__flex-flex-inner > *,
    .mini-modal__flex-fix {
        flex-shrink: 0;
    }

    .mini-modal__flex-flex-inner > &__cookbooks-container {
        overflow: auto;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &__content-inner {
        overflow: hidden;
    }

    &__content-inner[data-view-type="not-logged-in"],
    &__content-inner[data-view-type="not-logged-in-article"],
    &__content-inner[data-view-type="ajax"],
    &__content-inner[data-view-type="success"],
    &__content-inner[data-view-type="error"],
    &__content-inner[data-view-type="warning"],
    &__content-inner[data-view-type="warning-article"] {
        .mini-modal__flex-flex-inner {
            text-align: center;
            align-items: center;

            .heading--h3 {
                text-wrap: balance;
            }

            .heading--h3,
            .copy--small {
                width: 80%;
            }

            .btn-group {
                width: 100%;
            }
        }
    }
}
