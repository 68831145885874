/* MODULE 02 Footer
##################################################################################################################### */

.footer {
    position: relative;
    padding-bottom: rem-calc(60px);

    .newsletter {
        margin-bottom: rem-calc(25px);
    }

    .social-media {
        margin-bottom: rem-calc(45px);
    }

    .social-media-list {
        width: 100%;
    }

    .footer-nav__wrapper {
        margin-bottom: rem-calc(40px);
    }

    > .container {
        z-index: 5;
        position: relative;
    }

    @media #{$medium-up} {
        .newsletter {
            margin-bottom: rem-calc(36px);
        }
    }

    @media #{$xlarge-up} {
        padding-bottom: rem-calc(70px);

        .footer-meta-nav {
            float: right;
        }

        .social-media {
            margin-bottom: 0;
        }
    }
}

.footer--custom-image {
    background: no-repeat center center / cover;
}

.footer__custom-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.footer__wrapper {
    padding: 0;
    overflow: hidden;

    @media #{$medium-up} {
        padding: rem-calc(10px);
    }

    @media #{$xlarge-up} {
        padding: rem-calc(20px);
    }

    .foobyapp & {
        display: none;
    }
}

@if ($little-fooby) {
    .footer {
        padding-left: rem-calc(15px);
        padding-right: rem-calc(15px);
        border-radius: rem-calc(6px);

        &::after,
        &::before {
            content: none !important;
        }

        .border-logo-panel__logo-link {
            bottom: rem-calc(11px);
            background-color: $color-dark-stone-grey;
            padding: 0 rem-calc(17px);
            display: flex;
            width: rem-calc(77px);
            height: rem-calc(13px);

            @media #{$medium-up} {
                bottom: rem-calc(19px);
            }
        }

        .svg-group--stars .filled .animated {
            fill: $color-white;
        }

        .svg-group--stars .animated-stroke {
            stroke: $color-white;
        }

        .footer-nav > *:last-child {
            margin-top: rem-calc(25px);

            @media #{$medium-up} {
                margin-top: 0;
            }
        }

        .social-media-list {
            justify-content: flex-start;
        }

        &__deco-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: rem-calc(3px) rem-calc(4px) rem-calc(12px);

            @media #{$medium-up} {
                padding: rem-calc(18px);
            }
        }
    }
}
