/* COMPONENT suggested-search (autocomplete)
##################################################################################################################### */

.suggested-search {
    background-color: $color-white;
    border: 1px solid $color-light-grey;
    list-style: none;
    position: absolute;
    z-index: 20;
    margin: 0;
    top: rem-calc(51px);
    left: 0;
    right: 0;
    padding: rem-calc(1px) 0;
}

.suggested-search__item {
    font-size: rem-calc(16px);
    line-height: rem-calc(35px);
    font-family: $font-family-roboto;
    padding: 0 rem-calc(18px);
    margin: 0 1px;
    cursor: pointer;
    transition: background-color $animation-time-fast $animation-easing-default;

    &--active {
        background-color: $color-light-pale-green;
    }
}
