.season-declarations {
    @extend %unstyled-list;
    display: flex;
    column-gap: rem-calc(25px);
    row-gap: rem-calc(6px);
    flex-wrap: wrap;
}

.season-declarations__item {
    @extend %copy-small;
    display: flex;
}

.season-declarations__sample {
    width: rem-calc(22px);
    height: rem-calc(18px);
    margin-right: rem-calc(10px);
}

