
%clearfix {
    &::after {
        content: ".";
        clear: both;
        display: block;
        visibility: hidden;
        height: 0;
    }
}

%unstyled-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

%footer-section-title {
    font-size: rem-calc(20px);
    line-height: 1.25;
    font-weight: bold;
    letter-spacing: -.04rem;

    @media #{$xlarge-up} {
        font-size: rem-calc(24px);
    }
}

%inline-list {
    //@include clearfix;
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
        float: left;
        display: block;
    }
}

%teaser-inner {
    position: relative;
    padding: rem-calc(10px) rem-calc(15px) 0;
    z-index: 3;

    @media #{$large-up} {
        padding: rem-calc(15px) rem-calc(20px) 0;
    }
}

%teaser-header {
    margin-bottom: rem-calc(7px);
    line-height: 1;
}

%teaser-body {
    @media #{$medium-up} {
        max-width: 83.3%;
    }
}

%teaser-body-title {
    margin-bottom: rem-calc(11px);
    font-family: $font-family-roboto;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    color: $color-dark-grey;
}

%teaser-body-title--big {
    font-size: rem-calc(16px);
    line-height: rem-calc(20px);
    margin-bottom: rem-calc(6px);

    @media #{$large-up} {
        font-size: rem-calc(16px);
        line-height: rem-calc(22px);
    }
}

%teaser-body-text {
    margin-bottom: rem-calc(8px);
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    overflow: hidden;

    @media #{$medium-up} {
        line-height: rem-calc(18px);
        max-height: rem-calc(110px);
    }

    @media #{$large-up} {
        font-size: rem-calc(14px);
    }
}

%teaser-type {
    display: inline-block;
    margin-right: rem-calc(2px);
    font-size: rem-calc(10px);
    font-weight: bold;
    text-transform: uppercase;
    color: $headings-teaser-color;
    letter-spacing: .1em;
    line-height: 1.3;

    .little-fooby-l1.teaser & {
        color: $headings-teaser-color-l1;
    }

    .little-fooby-l2.teaser & {
        color: $headings-teaser-color-l2;
    }

    .little-fooby-l3.teaser & {
        color: $headings-teaser-color-l3;
    }

    @media #{$medium-up} {
        @if ($little-fooby) {
            font-size: rem-calc(13px);
        } @else {
            font-size: rem-calc(12px);
        }
    }
}

// from https://css-tricks.com/equidistant-objects-with-css/
%equidistant-list {
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
}

%fancyload-image-placeholder {
    position: relative;
    width: 100%;
    background-color: $color-light-grey;
    z-index: 1;
}

%fancyload-image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity $animation-time-slow $animation-easing-default,
        $image-zoom-transition;
    z-index: 2;
    object-fit: cover;
    object-position: top center;
    backface-visibility: hidden;

    @media all and (-ms-high-contrast:none) {
        backface-visibility: visible;
    }
}

%fancyload-image--loaded {
    opacity: 1;
}

%figcaption {
    padding-top: rem-calc(15px);
    color: $color-dark-stone-grey;

    &, p, ul {
        font-size: rem-calc(14px);
        line-height: rem-calc(18px);
    }

    @media #{$small-only} {
        padding-bottom: rem-calc(10px);
    }
}

%imageborder {
    $border-space-mobile: rem-calc(7px);
    $border-space-tablet: rem-calc(16px);
    $border-space-desktop: rem-calc(20px);

    position: absolute;
    content: " ";
    border: 2px solid $color-white;
    top: $border-space-mobile;
    right: $border-space-mobile;
    bottom: $border-space-mobile;
    left: $border-space-mobile;
    z-index: 3;

    @media #{$medium-up} {
        top: $border-space-tablet;
        right: $border-space-tablet;
        bottom: $border-space-tablet;
        left: $border-space-tablet;
    }

    @media #{$large-up} {
        top: $border-space-desktop;
        right: $border-space-desktop;
        bottom: $border-space-desktop;
        left: $border-space-desktop;
    }
}

%headerpanel-title {
    h1 {
        text-align: center;
    }

    @media screen and (min-width: #{$screen-md}) and (max-width: #{$screen-lg - 1}) {
        h1 {
            text-align: left;
            font-size: rem-calc(40px);
            line-height: rem-calc(40px);
            letter-spacing: 0;
        }
    }

    @media screen and (min-width: #{$screen-lg}) and (max-width: 1699px) {
        h1 {
            text-align: left;
            font-size: rem-calc(50px);
            line-height: rem-calc(50px);
            letter-spacing: 0;
        }
    }

    @media screen and (min-width: 1700px) {
        h1 {
            text-align: left;
            font-size: rem-calc(60px);
            line-height: rem-calc(60px);
            letter-spacing: 0;
        }
    }
}

%form-element-input {
    font-size: rem-calc(14px);
    padding: rem-calc(7px) rem-calc(13px);
}

%form-element-select {
    @extend %form-element-input;
    background-position: right rem-calc(13px) center;
}

%header-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    backface-visibility: hidden;

    @if ($little-fooby) {
        border-radius: rem-calc(6px);
    }
}

%filter-select-item {
    overflow: hidden;
    font-size: rem-calc(14px);
    font-weight: 700;
    font-family: $font-family-montserrat;
    color: $color-dark-stone-grey;
    text-transform: uppercase;
    transition: height $animation-time-default $animation-easing-default;
    line-height: rem-calc(34px);
    height: rem-calc(34px);
    transition: height $animation-time-slow $animation-easing-default;
}
