.tokens {
    @extend %inline-list;

    &__item {
        @extend %tag;
        @extend %tag--state-hover;
        display: block;
        float: left;
        margin-right: rem-calc(15px);
        margin-bottom: rem-calc(15px);
        background-color: $theme-color;
    }

    &__item-remove-trigger {
        font-size: 16px;
        line-height: 13px;
        position: relative;
        top: 2px;
        padding: 2px 7px;
        display: inline-block;
        margin: -8px -6px -8px 0;
        height: 14px;
        cursor: pointer;
    }

    &__remove-all-token {
        font-family: $font-family-montserrat;
        font-size: rem-calc(10px);
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        line-height: rem-calc(30px); // same height as tags
        margin-bottom: rem-calc(15px); // in case this is the only item line-breaking
    }

    &--hide-when-empty {
        display: none;
    }

    &--hide-when-empty.tokens--not-empty {
        display: block;
    }

}
