.meat-cooked-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.42857;

    @media #{$medium-up} {
        margin-right: rem-calc(-30px);
    }

    // hides/overlaps border-bottom of items in last row
    &::after {
        content: '';
        display: blocK;
        width: 100%;
        height: 1px;
        background-color: $color-white;
        position: absolute;
        bottom: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        flex-basis: 100%;
        margin-right: rem-calc(30px);
        flex-shrink: 0;
        border-bottom: 1px solid $color-light-grey;
        padding: rem-calc(12px) 0 rem-calc(10px);

        @media #{$medium-up} {
            flex-basis: calc(50% - #{rem-calc(30px)});
        }

        @media #{$large-up} {
            flex-basis: calc(33.333% - #{rem-calc(30px)});
        }
    }

    &__item-icon {
        margin-left: rem-calc(19px);
        margin-right: rem-calc(30px);

        @media #{$medium-up} {
            margin-right: rem-calc(45px);
        }
    }

    &__item-text {
        margin-right: rem-calc(19px);
    }
}
