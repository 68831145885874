/* TEMPLATE T13 MyFooby
##################################################################################################################### */

.t13-myfooby__welcome-bar {
    background-color: $color-light-grey;
    //margin-bottom: rem-calc(30px);
    //position: fixed;
    z-index: 98; // is one smaller than header, so that dorpdown still works in header
    left: 0;
    width: 100%;
    //top: rem-calc(50px);
    min-height: rem-calc(81px);
    border-top: 1px solid transparent;
    margin-bottom: rem-calc(30px); // where the floating content starts
}

//.t13-myfooby__welcome-bar-spacer {
//    //height: rem-calc(60px); // adds up to 141px from the header spacer
//    //margin-bottom: rem-calc(30px); // where the floating content starts
//}

@media #{$xlarge-up} {
    body:not(.touch) {
        .t13-myfooby__welcome-bar {
            //top: rem-calc(60px);
        }

        .t13-myfooby__welcome-bar-spacer {
            //height: rem-calc(40px); // adds up to 141px from the header spacer
        }
    }
}

.t13-myfooby__edit-cookbook-icon {
    padding-left: rem-calc(2px);
}

.t13-myfooby__edit-cookbook-icon,
.t13-myfooby__remove-cookbook-icon {
    cursor: pointer;
    margin-left: rem-calc(12px);
    position: relative;
    top: rem-calc(4px);
}

.t13-myfooby__welcome-bar-left {
    padding-top: rem-calc(13px);
    padding-bottom: rem-calc(16px);
}

.t13-myfooby__welcome-bar-right {
    padding-top: rem-calc(14px);
    padding-bottom: rem-calc(16px);
}

.t13-myfooby__welcome-text {
    font-size: rem-calc(30px);
    line-height: rem-calc(30px);
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    text-transform: uppercase;
    letter-spacing: rem-calc(-1px);
    display: block;
    margin-bottom: rem-calc(7px);
}

.t13-myfooby__welcome-bar-link-wrapper {
    font-size: rem-calc(12px);
    font-family: $font-family-montserrat;
    color: $color-stone-grey;
    display: block;
}

.t13-myfooby__welcome-bar-link {
    color: inherit;
    display: inline-block;
    margin-right: rem-calc(16px);
    transition: color $animation-time-default $animation-easing-default;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus,
    &[data-call-state="2"] {
        text-decoration: none;
        color: $color-dark-stone-grey;
    }
}

@media #{$small-only} {
    .t13-myfooby__sidebar {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 400;
        background-color: $color-white;
        padding: rem-calc(16px);
        transition: transform 0s $animation-time-slow,
                    opacity $animation-time-slow $animation-easing-default;
        transform: translateY(-101%);
        opacity: 0;
    }

    .t13-myfooby__sidebar--open {
        transition: transform 0s 0s,
                    opacity $animation-time-slow $animation-easing-default;
        transform: translateY(0);
        opacity: 1;
    }
}

@media #{$medium-up} {
    .t13-myfooby__sidebar {
        margin-bottom: rem-calc(30px);
    }

    .t13-myfooby__sidebar-col {
        position: sticky;
        top: rem-calc(70px);
    }
}

.t13-myfooby__sidebar-searchbar-wrapper {
    margin-bottom: rem-calc(10px);
}

.t13-myfooby__sidebar-section {
    background-color: $color-light-grey;
    margin-bottom: rem-calc(2px);
    transition: background-color $animation-time-default $animation-easing-default;

    &:not(.t13-myfooby__sidebar-section--open):hover {
        background-color: darken($color-light-grey, 8%);
    }

    &--little-fooby {
        background-color: $panel-background-color-l1;
        color: $theme-color-hover-l1;

        .t13-myfooby__sidebar-section-title-text {
            color: $theme-color-hover-l1;
        }

        &:not(.t13-myfooby__sidebar-section--open):hover {
            background-color: darken($panel-background-color-l1, 4%);
        }
    }
}

.t13-myfooby__sidebar-section-title {
    display: flex;
    align-items: center;
    padding: rem-calc(10px);
    cursor: pointer;
    position: relative;

    // has to be before, since after is taken by the clearfix
    &::before {
        position: absolute;
        content: " ";
        bottom: 0;
        height: 1px;
        left: rem-calc(40px);
        right: rem-calc(10px);
        background-color: rgba($color-dark-grey, .5);
        opacity: 0;
        //transition: opacity $animation-time-default $animation-easing-default;
    }

    @media #{$xlarge-up} {
        &::before {
            left: rem-calc(50px);
        }
    }
}

.t13-myfooby__sidebar-section-title-icon {
    width: rem-calc(30px);
    float: left;

    svg {
        max-height: rem-calc(20px);
        max-width: rem-calc(21px);
    }

    @media #{$xlarge-up} {
        width: rem-calc(40px);
        padding-left: rem-calc(2px);

        svg {
            max-height: rem-calc(26px);
            max-width: rem-calc(27px);
        }
    }
}

.svg-cookbook,
.svg-education-hat,
.svg-newspaper,
.svg-myfooby-watch {
    position: relative;
    top: rem-calc(1px);

    @media #{$medium-up} {
        top: rem-calc(5px);
    }

    @media #{$xlarge-up} {
        top: rem-calc(2px);
    }
}

.svg-myfooby-pot {
    @media #{$medium-up} {
        position: relative;
        top: rem-calc(3px);
    }
}

.t13-myfooby__sidebar-section-title-text {
    font-size: rem-calc(11px);
    font-family: $font-family-montserrat;
    color: $color-dark-grey;
    font-weight: bold;
    line-height: rem-calc(24px);
    float: left;
    text-transform: uppercase;

    padding-right: rem-calc(5px);

    $temp: rem-calc(30px);
    width: calc(100% - #{$temp});

    .svg-fill {
        fill: $color-dark-grey;
    }

    @media #{$medium-up} {
        line-height: rem-calc(31px);
    }

    @media #{$xlarge-up} {
        $temp: rem-calc(40px);
        width: calc(100% - #{$temp});
    }
}

.t13-myfooby__sidebar-section-title-text-arrow-down,
.t13-myfooby__sidebar-section-title-text-arrow-right {
    float: right;
}

.t13-myfooby__sidebar-section-title-text-arrow-down {
    @media #{$small-only} {
        display: none;
    }
}

.t13-myfooby__sidebar-section-title-text-arrow-right {
    position: relative;
    top: rem-calc(2px);

    @media #{$medium-up} {
        display: none;
    }
}

.t13-myfooby__sidebar-section-content {

    @media #{$small-only} {
        transform: translateX(100%);
        z-index: 2;
        background: white;
    }

    @media #{$medium-up} {
        height: 0;
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
        //transition: height $animation-time-default $animation-easing-default, // transition height doesn't work
        //            padding $animation-time-default $animation-easing-default;
    }
}

.t13-myfooby__mini-spacer {
    height: rem-calc(10px);

    @media #{$medium-up} {
        height: rem-calc(20px);
    }
}

.t13-myfooby__welcome-wrapper-small {
    line-height: rem-calc(19px);
    margin-top: rem-calc(4px);
    margin-bottom: 0;
}

.t13-myfooby__welcome-text-small {
    font-size: rem-calc(12px);
    font-family: $font-family-montserrat;
    font-weight: normal;
}

.t13-myfooby__sno-open-trigger {
    text-align: center;
    background-color: #f5f9f3;
    font-weight: bold;
    font-size: rem-calc(12px);
    text-transform: uppercase;
    line-height: rem-calc(14px);
    height: rem-calc(50px);
    padding: rem-calc(18px) rem-calc(16px);
    color: $color-green;
    font-family: $font-family-montserrat;
    position: fixed;
    top: rem-calc(50px);
    left: 0;
    right: 0;
    z-index: 98;
}

.t13-myfooby__sno-open-trigger-spacer {
    height: rem-calc(50px);
    margin-bottom: rem-calc(20px);
}

.t13-myfooby__sno-open-trigger-arrow-down {
    float: right;
    position: absolute;
    right: 20px;

    .svg-fill {
        fill: $color-green;
    }
}

.t13-myfooby__sidebar-section-content-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.t13-myfooby__sidebar-section-content-list-item {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding: rem-calc(15px) rem-calc(15px) rem-calc(15px) rem-calc(40px);
    font-size: rem-calc(12px);
    line-height: rem-calc(14px);
    font-family: $font-family-montserrat;
    font-weight: bold;
    cursor: pointer;
    color: $color-dark-grey;
    margin-bottom: rem-calc(2px);
    background-color: $color-light-grey;
    overflow: hidden;
    transition: color $animation-time-default $animation-easing-default,
                background-color $animation-time-default $animation-easing-default;

    @media #{$medium-up} {
        line-height: rem-calc(14px);
        padding: rem-calc(13px) rem-calc(15px) rem-calc(13px) rem-calc(40px);
        margin-bottom: 0;
        background-color: transparent;
    }

    @media #{$xlarge-up} {
        padding-left: rem-calc(50px);
    }

    //&[data-call-state="2"],
    //&:hover {
    //    color: lighten($color-dark-grey, 10%);
    //}

    &[data-call-state="2"],
    &:hover {
        background-color: darken($color-light-grey, 8%);

        @media #{$medium-up} {
            background-color: darken($color-light-grey, 8%);
        }
    }

    [data-call-title],
    .cookbook-add {
        flex-grow: 1;
    }

    &[data-cookbook-likes],
    &[data-cookbook-swipes] {
        &[data-count="0"] {
            display: none;
        }
    }

    &--little-fooby {
        background-color: $panel-background-color-l1;
        color: $theme-color-hover-l1;

        &[data-call-state="2"],
        &:hover {
            color: $theme-color-hover-l1;
            background-color: darken($panel-background-color-l1, 4%);
        }
    }

    &--cookbook {

    }

    &--cookbook-menu {
        .t13-myfooby__sidebar-section-content-list-item-cookbooktext {
            @media #{$medium-up} {
                flex-direction: column;
            }
        }

        .t13-myfooby__sidebar-section-content-list-item-count {
            margin-left: 0;
            margin-top: rem-calc(-4px);

            @media #{$medium-up} {
                margin-top: 0;
            }
        }
    }
}

.t13-myfooby__sidebar-section-content-list-item-cookbooktext {
    display: flex;
    flex-grow: 1;
    margin-top: rem-calc(6px);
}

.t13-myfooby__sidebar-section-content-list-item-cookbookicon {
    display: flex;
    flex-shrink: 0;
    margin-right: rem-calc(4px);
}

.t13-myfooby__sidebar-section-content-list-item-label {
    display: inline-flex;
    font-size: rem-calc(11px);
    line-height: 15 / 11;
    font-weight: bold;
    color: $color-white;
    padding: rem-calc(4px) rem-calc(12px);
    background-color: $theme-color;
    white-space: nowrap;

    @media #{$medium-up} {
        margin-top: rem-calc(4px);
    }
}

// sass-lint:disable-all
@media #{$medium-up} {
    .t13-myfooby__sidebar-section-content-list + .t13-myfooby__sidebar-section-content-list {
        .t13-myfooby__sidebar-section-content-list-item {
            background-color: transparent !important;
        }
    }
}
/* scss-lint:enable all */

.t13-myfooby__profile-wrapper,
.t13-myfooby__teaser-wrapper {
    margin-bottom: rem-calc(42px);

    @media #{$large-up} {
        margin-bottom: rem-calc(60px);
    }
}

.t13-myfooby__teaser-header {
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(24px);

    @media #{$large-up} {
        margin-bottom: rem-calc(32px);
        justify-content: space-between;
        flex-direction: row;
   }
}

.t13-myfooby__teaser-header-left {
    .heading--h3 {
        margin-bottom: rem-calc(24px);

        @media #{$large-up} {
            margin-bottom: 0;
        }
    }
}

.t13-myfooby__teaser-wrapper--cookbook-menu {
    .t13-myfooby__teaser-header-left {
        .heading--h3 {
            margin-bottom: rem-calc(4px);
        }
    }
}

.t13-myfooby__teaser-header-courses {
    display: inline-block;
    color: $color-white;
    padding: rem-calc(4px) rem-calc(12px);
    margin-bottom: rem-calc(24px);
    font-size: rem-calc(11px);
    line-height: 15 / 11;
    background-color: $color-dark-grey;
    font-weight: bold;

    @media #{$large-up} {
        margin-bottom: 0;
    }
}

.t13-myfooby__sidebar-section-content-list-item-count {
    margin-left: rem-calc(5px);
    padding-right: rem-calc(5px);
    white-space: nowrap;

    + span {
        display: inline-block;
        width: calc(100% - 25px);
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media #{$small-only} {
    .sno__flex {
        display: flex;
        flex-direction: column;
    }

    .sno__flex-fix {
        //display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 1;
        //flex-basis: content;
    }

    .sno__flex-flex {
        //display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;

        flex-basis: auto;
        flex-grow: 100;
        //flex-shrink: 100;

        position: relative;
        //overflow-y: scroll;
        //-webkit-overflow-scrolling: touch;
        //padding-bottom: 20px;

        top: -10px;
        margin-bottom: -10px;
        padding-top: 10px;
        padding-bottom: 10px;

        &::after,
        &::before {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            height: 10px;
            z-index: 4;
        }

        &::before {
            top: 0;
            background-image: linear-gradient($color-white, rgba($color-white, 0));
        }

        &::after {
            bottom: 0;
            background-image: linear-gradient(rgba($color-white, 0), $color-white);
        }
    }

    .sno__flex-flex-inner {
        // position: absolute;
        // top: 0;
        // right: 0;
        // bottom: 0;
        // left: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        padding-top: 10px;
        padding-bottom: 10px;
        z-index: 1;

        position: fixed;
        top: 46px;
        right: 16px;
        bottom: 16px;
        left: 16px;
    }

    .sno__root-inner {
        z-index: 1;
    }

    .sno__prevlevel-link {
        white-space: nowrap;
        text-align: left;
        line-height: rem-calc(44px);
        color: $color-dark-grey;
        padding: 0 rem-calc(16px);
        background-color: $color-lighter-grey;
        text-transform: uppercase;
        position: relative;
        z-index: 15;
        font-size: rem-calc(11px);
        font-weight: bold;
        font-family: $font-family-montserrat;
        margin-bottom: rem-calc(10px);
    }

    .sno__prevlevel-link-icon {
        display: inline-block;
        width: rem-calc(14px);
        margin-right: rem-calc(10px);
        position: relative;
        left: rem-calc(3px);
        top: rem-calc(1px);
    }

    .sno__nextlevel-inner {
        z-index: 3;
        $temp: rem-calc(17px);
        transform: translateX(calc(100% + #{$temp})) translateZ(0);
        transition: transform $animation-time-default $animation-easing-default;
    }

    .sno__blocker-background {
        position: fixed;
        //background: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .sno__nextlevel-inner--open {
        transform: translateX(0);
        z-index: 3;
    }

    .sno__close-cross {
        position: absolute;
        top: rem-calc(22px);
        right: rem-calc(16px);
        z-index: 2;
    }
}

// modifiers
.t13-myfooby__sidebar-section--green {
    background-color: rgba($color-light-pale-green, .5);
    transition: background-color $animation-time-default $animation-easing-default;

    &:not(.t13-myfooby__sidebar-section--open):hover {
        background-color: $color-light-pale-green;
    }

    .t13-myfooby__sidebar-section-title,
    .t13-myfooby__sidebar-section-title-text,
    .t13-myfooby__sidebar-section-content-list-item {
        color: $color-green;

        .svg-fill {
            fill: $color-green;
        }

        .svg-stroke {
            stroke: $color-green;
        }
    }

    .t13-myfooby__sidebar-section-content-list-item {
        background-color: $color-light-pale-green;

        @media #{$medium-up} {
            background-color: transparent;
        }
    }

    // means the view auctally represents this navigation point
    .t13-myfooby__sidebar-section-content-list-item[data-call-state="2"],
    .t13-myfooby__sidebar-section-content-list-item:hover {
        color: $color-green;
        background-color: $color-light-pale-green;
    }

    .t13-myfooby__sidebar-section-title {

        // has to be before, since after is taken by the clearfix
        &::before {
            background-color: rgba($color-green, .5);
        }
    }
}

@media #{$medium-up} {
    .t13-myfooby__sidebar-section--open {
        margin-bottom: rem-calc(20px);

        .t13-myfooby__sidebar-section-title {

            // has to be before, since after is taken by the clearfix
            &::before {
                opacity: 1;
            }
        }

        .t13-myfooby__sidebar-section-content {
            height: auto;
            padding-top: rem-calc(12px);
            padding-bottom: rem-calc(7px);
        }
    }
}
