/* COMPONENT Recipedetail How-To Videos
##################################################################################################################### */

.recipedetail-how-to-videos__image-wrapper {
    overflow: hidden;
    position: relative;
    margin-bottom: rem-calc(7px);

    @media #{$large-up} {
        margin-bottom: rem-calc(11px);
    }
}

.recipedetail-how-to-videos__placeholder {
    @extend %fancyload-image-placeholder;

    padding-top: calc-ratio(192, 140);

    @media #{$medium-up} {
        padding-top: calc-ratio(200, 115);
    }
}

.recipedetail-how-to-videos__image {
    @extend %fancyload-image;

    // overwriting extended styles
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
    transition: $image-zoom-transition;
}

.recipedetail-how-to-videos__video-btn {
    position: absolute;
    z-index: 4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform $animation-time-default $animation-easing-default;
}

.recipedetail-how-to-videos__title {
    @extend %teaser-headling-smaller;

    margin-bottom: rem-calc(17px);
    transition: color $animation-time-default $animation-easing-default;

    @media #{$medium-up} {
        margin-bottom: rem-calc(21px);
    }
}

.recipedetail-how-to-videos__item {
    cursor: pointer;

    &:nth-child(2n+1) {
        clear: left;
    }

    @media #{$medium-up} {
        &:nth-child(2n+1) {
            clear: none;
        }
    }

    &:hover {
        .recipedetail-how-to-videos__image {
            transform: translateY(-50%) $image-zoom-scale;
        }

        .recipedetail-how-to-videos__title {
            color: $color-green;
        }
    }
}
