/* COMPONENT C36.11 Teaser Image Special
##################################################################################################################### */

.teaser-image-special {
    position: relative;
    overflow: hidden;
}

.teaser-image-special__img-placeholder {
    position: relative;
    width: 100%;
    background-color: $color-light-grey;
    z-index: 1;
}

// ratios mobile
.teaser-image-special--initial-columns-6 {
    .teaser-image-special__img-placeholder {
        padding-top: calc-ratio(430, 465);
    }
}

.teaser-image-special--initial-columns-12 {
    .teaser-image-special__img-placeholder {
        padding-top: calc-ratio(430, 615);
    }

    .teaser-image-special__body {
        @extend %teaser-body;
    }
}

// ratios tablet
@media #{$medium-up} {
    .teaser-image-special--initial-columns-6 {
        .teaser-image-special__img-placeholder {
            padding-top: calc-ratio(411, 394);
        }
    }

    .teaser-image-special--initial-columns-12 {
        .teaser-image-special__img-placeholder {
            padding-top: calc-ratio(862, 494);
        }
    }
}

// ratios desktop
@media #{$large-up} {
    .teaser-image-special--initial-columns-6 {
        .teaser-image-special__img-placeholder {
            padding-top: calc-ratio(555, 400);
        }
    }

    .teaser-image-special--initial-columns-12 {
        .teaser-image-special__img-placeholder {
            padding-top: calc-ratio(1138, 500);
        }
    }
}

.teaser-image-special__image {
    @extend %fancyload-image;
}

.teaser-image-special__image--loaded {
    @extend %fancyload-image--loaded;
}

.teaser-image-special__link {
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: inherit;
        color: inherit;
    }

    &:hover,
    &:active,
    &:focus {
        .teaser-image-special__image {
            transform: $image-zoom-scale;
        }
    }
}

.teaser-image-special__panel {
    @extend %teaser-inner;
    background-color: $color-white;
    position: absolute;
    left: rem-calc(10px);
    right: rem-calc(10px);
    bottom: rem-calc(10px);
    z-index: 3;

    // overwrites of placeholder
    padding-bottom: rem-calc(2px);

    @media #{$medium-up} {
        padding-bottom: rem-calc(7px);
    }

    @media #{$large-up} {
        left: rem-calc(20px);
        right: rem-calc(20px);
        bottom: rem-calc(20px);
    }
}

.teaser-image-special__header {
    @extend %teaser-header;
}

.teaser-image-special__type {
    @extend %teaser-type;
}

.teaser-image-special__body-title {
    @extend %teaser-body-title;
}

.teaser-image-special__body-text {
    @extend %teaser-body-text;
}
