// A minimal filter bar that can be used inline:

.sort-bar {
    height: rem-calc(30px);
}

.sort-bar__list {
    @extend %unstyled-list;
    display: flex;
    justify-content: flex-end;
}

.sort-bar__item {
    background-color: #e2ecd5;
    color: $color-desaturated-green;
    text-transform: uppercase;
    height: rem-calc(30px);
    padding: 0 rem-calc(15px);
    cursor: pointer;

    font-family: $font-family-montserrat;
    font-size: rem-calc(12px);
    line-height: (14 / 17);
    font-weight: 700;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;

    &:not(:last-child) {
        border-right: $filter-bar-border;
    }

    &.is-active {
        color: $color-white;
        background-color: $color-desaturated-green;
    }
}

@media #{$large-up} {
    .sort-bar {
        margin-left: 0;
        margin-right: 0;
    }

    .sort-bar__item {
        padding: 0 rem-calc(30px);
        border-right: $filter-bar-border;
        flex: 0 0 auto;

        &:first-child {
            border-left: $filter-bar-border;
        }
    }
}
