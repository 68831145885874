.season-calendar {
    $grid-columns: 24;

    &__filter-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: (60px - 32px) / -1;
        margin-bottom: 16px;

        @media #{$medium-up} {
            display: none;
        }
    }

    &__filter-line {
        border-bottom: 1px solid $color-dark-grey;
        opacity: 0.1;
        margin-bottom: 20px;

        @media #{$medium-up} {
            display: none;
        }
    }

    .filter-bar {
        @media #{$medium-only} {
            margin-left: rem-calc(-$container-padding-medium);
            margin-right: rem-calc(-$container-padding-medium);
        }
    }

    .word-wrap {
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        white-space: pre-wrap; /* css-3 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
        white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
        word-break: break-all;
        white-space: normal;
        hyphens: auto;
        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        -ms-hyphens: auto;
    }

    .vertical-grid {
        z-index: 1;
        float: left;
        height: 100%;
        border-left: 2px solid $color-lighter-grey;
    }

    .current-date {
        position: absolute;
        width: 1px; // Substitutes border, so no rem value here
        top: rem-calc(50px);
        bottom: 0;
        z-index: 7;
        background-color: $color-fooby-orange;
    }

    .bar-grid {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media #{$small-only} {
            width: (64px * 12);
            flex-shrink: 0;
        }
    }

    .bar-grid-row {
        overflow: hidden;
        z-index: 2;
        margin: rem-calc(2px) 0;

        &:first-of-type {
            margin-top:  rem-calc(12px);
        }

        &:last-of-type {
            margin-bottom:  rem-calc(12px);
        }
    }

    @for $i from 0 to $grid-columns {
        .bar-grid-col-#{$i} {
            float: left;
            position: relative;
            min-height: rem-calc(1px);
            width: percentage($i / $grid-columns);
            padding: 0;
        }

        .bar-grid-left-#{$i} {
            left: percentage($i / $grid-columns);
        }
    }
}
