
[data-animation-fade-in-block],
%animation-fade-in-block {
    opacity: 0;
    transform: translate3d(-30px, -10px, 0);
    transform-origin: center center;
    transition: opacity .5s $animation-easing-default,
                transform .5s cubic-bezier(.150, .865, .355, .990),
                border $animation-time-default $animation-easing-default;
    //will-change: opacity, transform;
}

.global--fade-in-block,
%global--fade-in-block {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.global--fade-out-block,
%global--fade-out-block {
    opacity: 0;
    transform: scale(.95);
}

[data-animation-image-fadein] {
    opacity: 0;
    transition: opacity $animation-time-default $animation-easing-default,
                $image-zoom-transition;
}

[data-lazy="fadein"] {
    opacity: 0;
    transition: opacity $animation-time-default $animation-easing-default,
                $image-zoom-transition;
}

.global--visible,
[data-lazy="fadein"].error,
[data-lazy="fadein"].loaded {
    opacity: 1;
}
