/* COMPONENT Headline H1
##################################################################################################################### */

.headline-h1 {
    padding: rem-calc(25px) rem-calc(30px) rem-calc(6px); // 24px padding bottom come from margin of p

    @if ($little-fooby) {
        background-image: url('../assets/images/little-fooby/backgrounds/header-panel.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    } @else {
        background-color: $color-white;
    }

    @if ($little-fooby) {
        .heading--h3 {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: rem-calc(40px);
            line-height: 1.5;
        }
    }

    @media #{$medium-up} {
        @if ($little-fooby) {
            background-image: url('../assets/images/little-fooby/backgrounds/header-panel-medium.svg');
        }
    }

    @media #{$large-up} {
        padding: rem-calc(55px) rem-calc(80px) rem-calc(36px) rem-calc(60px); // 24px padding bottom come from margin of p
    }
}
