/* COMPONENT Spacer
##################################################################################################################### */

.spacer {
    @extend %clearfix;
    min-height: 1px;
    display: block;
    width: 100%;
    float: left;
}

.spacer--small {
    height: rem-calc(30px);
}

.spacer--medium {
    height: rem-calc(60px);
}

.spacer--large {
    height: rem-calc(90px);
}
