.t34-confirmation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 50px);
    text-align: center;

    @media #{$medium-up} {
        height: calc(100vh - 70px);
    }

    @media #{$xlarge-up} {
        height: calc(100vh - 100px);
    }

    &__logo {
        position: absolute;
        bottom: rem-calc(-15px);
        left: 50%;
        transform: translate(-50%);
        background-color: #fff;
        padding: 0 rem-calc(20px);
    }

    &__title {
        margin-bottom: rem-calc(30px);
    }

    &__text {
        margin-bottom: 0;
    }

    &__wrapper,
    &__inner-wrapper,
    &__top,
    &__bottom {
        display: flex;
        flex-direction: column;
    }

    &__wrapper,
    &__inner-wrapper {
        flex-grow: 1;
        flex-shrink: 0;
    }

    &__wrapper {
        padding: rem-calc(20px);
    }

    &__inner-wrapper {
        position: relative;
        border: 2px solid $color-dark-grey;
        justify-content: center;
    }

    &__top,
    &__bottom {
        flex-shrink: 0;
    }

    &__top {
        padding: rem-calc(20px);
        padding-top: rem-calc(30px);
    }

    &__bottom {
        padding: rem-calc(40px) rem-calc(20px) rem-calc(30px);

        @media #{$medium-up} {
            padding-top: rem-calc(80px);
        }
    }
}
