/* COMPONENT Tags
##################################################################################################################### */

// sass-lint:disable-all

.tag,
%tag {
    font-family: $font-family-montserrat;
    font-size: rem-calc(10px);
    font-weight: 700;
    text-transform: uppercase;
    line-height: rem-calc(14px);
    display: inline-block;
    text-decoration: none;
    transition: all .22s ease-in-out;
    position: relative;

    @if ($little-fooby) {
        padding: rem-calc(8px) rem-calc(17px);
        color: $color-lf-cyan-dark;
    } @else {
        padding: rem-calc(8px) rem-calc(9px);
        color: $color-green;
        background-color: $color-light-pale-green;
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__text {
        position: relative;
    }

    @if ($little-fooby) {
        &__bg-background,
        &__bg-foreground {
            transition: fill .2s ease-in;
        }

        .little-fooby-l2 & {
            color: $color-lf-green-dark2;

            .tag__bg-background {
                fill: $color-lf-green-dark;
            }

            .tag__bg-foreground {
                fill: $color-lf-green-light;
            }
        }

        .little-fooby-l3 & {
            color: $color-lf-magenta-dark;

            .tag__bg-background {
                fill: $color-lf-magenta-dark;
            }

            .tag__bg-foreground {
                fill: $color-lf-magenta-light;
            }
        }

        &.little-fooby-l1 {
            color: $color-lf-cyan-dark;

            .tag__bg-background {
                fill: $color-lf-cyan-dark;
            }

            .tag__bg-foreground {
                fill: #B0E1EB;
            }
        }

        &.little-fooby-l2 {
            color: $color-lf-green-dark2;

            .tag__bg-background {
                fill: $color-lf-green-dark;
            }

            .tag__bg-foreground {
                fill: $color-lf-green-light;
            }
        }

        &.little-fooby-l3 {
            color: $color-lf-magenta-dark;

            .tag__bg-background {
                fill: $color-lf-magenta-dark;
            }

            .tag__bg-foreground {
                fill: $color-lf-magenta-light;
            }
        }
    }
}

.tag:hover,
.tag--state-hover,
.tag:active,
.tag:focus,
.tag--state-active,
%tag--state-hover,
%tag--state-active {
    text-decoration: none;
    color: $color-white;
}

.tag:hover:not(.tag--state-inactive),
.tag--state-hover,
.tag:active,
.tag:focus,
.tag--state-active,
%tag--state-hover,
%tag--state-active {
    @if ($little-fooby) {
        .tag__bg-foreground {
            fill: $color-lf-cyan-dark;
        }

        .little-fooby-l2 & {
            .tag__bg-foreground {
                fill: $color-lf-green-dark;
            }
        }

        .little-fooby-l3 & {
            .tag__bg-foreground {
                fill: $color-lf-magenta-dark;
            }
        }

        &.little-fooby-l1 {
            .tag__bg-foreground {
                fill: $color-lf-cyan-dark;
            }
        }

        &.little-fooby-l2 {
            .tag__bg-foreground {
                fill: $color-lf-green-dark;
            }
        }

        &.little-fooby-l3 {
            .tag__bg-foreground {
                fill: $color-lf-magenta-dark;
            }
        }
    } @else {
        background-color: $color-desaturated-green;
    }
}

.tag--state-inactive {
    color: $color-white;
    background-color: rgba($color-desaturated-green, .5);
}
