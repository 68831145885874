/* COMPONENT Typography
##################################################################################################################### */

// Headings

%heading--grey-centered,
.heading--grey-centered {
    @extend %h2;
    text-align: center;
    color: $headings-grey-color;
}

// gives possibility of seo unrelevant text, styled like a h3 heading
%heading--h3,
.heading--h3 {
    @extend %h3;
}

// gives possibility of seo unrelevant text, styled like a h2 heading
%heading--h2,
.heading--h2 {
    @extend %h2;
}

%heading--title,
.heading--title {
    font-family: $headings-font-family;
    color: $headings-grey-color;
    font-size: rem-calc(30px);
    line-height: rem-calc(36px);
    letter-spacing: rem-calc(-.5px);

    // due to large spate at top, we need to reposition and subtract from wanted margin-bottom
    position: relative;
    top: -5px;
    margin-bottom: rem-calc(13px);

    @media #{$medium-up} {
        font-size: rem-calc(45px);
        line-height: rem-calc(48px);
    }
}

// Paragraph styles

%paragraph--subtitle,
.paragraph--subtitle {
    font-family: $font-family-roboto;
    font-size: rem-calc(18px);
    line-height: rem-calc(28px);
    text-align: center;

    @media #{$medium-up} {
        font-size: rem-calc(20px);
        line-height: rem-calc(30px);
    }
}

%copy--big,
.copy--big {
    @extend %p;

    font-size: rem-calc(20px);
    margin-bottom: rem-calc(20px);
}

%paragraph--copy-intro,
.paragraph--copy-intro {
    @extend %p;
    font-family: $font-family-roboto;
}

%paragraph--copy-bold,
.paragraph--copy-bold {
    @extend %p;
    font-weight: 700;
}

%paragraph--subtext-picture,
%copy-small,
.paragraph--subtext-picture,
.copy--small {
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
}

.copy--center,
%copy--center {
    text-align: center;
}

.copy--font-northernsoul-script {
    font-family: $font-family-northernsoul-script;
}

.copy--slim-margin {
    margin-bottom: rem-calc(8px);
}

.copy--small-margin {
    margin-bottom: rem-calc(12px);
}

.copy--medium-margin {
    margin-bottom: rem-calc(33px);

    @media #{$medium-up} {
        margin-bottom: rem-calc(60px);
    }
}

.copy--space-top {
    margin-top: rem-calc(30px);
}

.floating-title,
%floating-title {
    display: inline-block;
    font-size: rem-calc(12px);
    line-height: rem-calc(14px);
    font-family: $font-family-roboto;
    letter-spacing: rem-calc(2px);
    color: $color-white;
    text-transform: uppercase;
    white-space: nowrap;
    padding: rem-calc(8px) rem-calc(20px);

    @if ($little-fooby) {
        background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l1.svg');
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .little-fooby-l2 & {
            background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l2.svg');
        }

        .little-fooby-l3 & {
            background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l3.svg');
        }

        &.little-fooby-l1 {
            background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l1.svg');
        }

        &.little-fooby-l2 {
            background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l2.svg');
        }

        &.little-fooby-l3 {
            background-image: url('../assets/images/little-fooby/backgrounds/floating-title/floating-title-l3.svg');
        }
    } @else {
        background-color: $color-fooby-orange;
    }
}

.floating-title--teaser {
    font-size: rem-calc(11px);
    line-height: rem-calc(12px);
    padding: rem-calc(6px) rem-calc(10px) rem-calc(7px);
}

%teaser-headling-smaller,
.copy--roboto-medium {
    font-family: $font-family-roboto;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);

    @media #{$medium-up} {
        font-size: rem-calc(16px);
        line-height: rem-calc(22px);
    }
}

%error,
.copy--error {
    font-size: rem-calc(14px);
    color: $color-error;
    line-height: 1.3;
    padding: 0;
    margin: 0 0 rem-calc(12px);
}

%form-error {
    @extend %error;
    font-size: rem-calc(11px);
    margin: rem-calc(2px) 0 0;
    float: left;
}

%small-monterrat {
    font-size: rem-calc(12px);
    line-height: (16 / 12);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;

    @media #{$medium-up} {
        font-size: rem-calc(14px);
        line-height: (18 / 14);
    }
}

@media #{$small-only} {
    .copy--xs-centered {
        text-align: center;
    }
}

@media #{$medium-only} {
    .copy--sm-centered {
        text-align: center;
    }
}

//%paragraph--small,
//.paragraph--small {
//    font-size: rem-calc(14px);
//    line-height: rem-calc(24px);
//}

//.paragraph--xsmall {
//    font-size: rem-calc(12px);
//    line-height: rem-calc(18px);
//}
