.season-bar {
    display: flex;
    gap: 25px;
    overflow: hidden;
    margin-bottom: rem-calc($gutter-medium);

    @media #{$medium-up} {
        align-items: center;
    }

    &__declarations {
    }

    &__label {
        @extend %copy-small;
    }

    &__switch {
        margin-left: auto;
        white-space: nowrap;

        @media #{$small-only} {
            display: none;
        }
    }
}
