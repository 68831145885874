@if ($little-fooby) {
    .marked-line {
        background-size: 100% 1.3em;
        background-position: 0;
        background-repeat: no-repeat;
        text-shadow: none;
        display: inline;
        padding-left: 0.4em;
        padding-right: 0.4em;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        margin-left: -0.4em;
        margin-right: -0.4em;
        max-width: 100%;
        background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line.svg');

        .little-fooby-l2 & {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line-l2.svg');
        }

        .little-fooby-l3 & {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line-l3.svg');
        }

        &.little-fooby-l1 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line.svg');
        }

        &.little-fooby-l2 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line-l2.svg');
        }

        &.little-fooby-l3 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line-l3.svg');
        }

        &--line2 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2.svg');

            .little-fooby-l2 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2-l2.svg');
            }

            .little-fooby-l3 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2-l3.svg');
            }

            &.little-fooby-l1 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2.svg');
            }

            &.little-fooby-l2 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2-l2.svg');
            }

            &.little-fooby-l3 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line2-l3.svg');
            }
        }

        &--line3 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3.svg');

            .little-fooby-l2 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3-l2.svg');
            }

            .little-fooby-l3 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3-l3.svg');
            }

            &.little-fooby-l1 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3.svg');
            }

            &.little-fooby-l2 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3-l2.svg');
            }

            &.little-fooby-l3 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line3-l3.svg');
            }
        }

        &--line4 {
            background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4.svg');

            .little-fooby-l2 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4-l2.svg');
            }

            .little-fooby-l3 & {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4-l3.svg');
            }

            &.little-fooby-l1 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4.svg');
            }

            &.little-fooby-l2 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4-l2.svg');
            }

            &.little-fooby-l3 {
                background-image: url('../assets/images/little-fooby/backgrounds/recipe-text-lines/text-line4-l3.svg');
            }
        }

        &--no-padding {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
}