/* COMPONENT Buttons
##################################################################################################################### */

$buttonPaddingTop: 16px;
$buttonPaddingBottom: 15px;
$buttonPaddingHorizontal: 35px;

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc($buttonPaddingTop) rem-calc($buttonPaddingHorizontal) rem-calc($buttonPaddingBottom);
    font-size: rem-calc(12px);
    font-family: $font-family-montserrat;
    font-weight: bold;
    line-height: rem-calc(15px);
    text-transform: uppercase;
    border-radius: 0;
    position: relative;
    text-decoration: none;
    border: 2px solid transparent;
    text-align: center;
    letter-spacing: 0.5px;
    transition: color $animation-time-default $animation-easing-default,
        background-color $animation-time-default $animation-easing-default,
        border-color $animation-time-default $animation-easing-default;

    @media #{$small-only} {
        display: flex;
        width: 100%;
    }

    [data-resultcount-total] {
        display: inline-block;
        margin: 0 rem-calc(4px);
    }

    &__icon,
    &__text {
        position: relative;
        display: inline-flex;
        z-index: 1;
    }

    &__icon {
        flex-shrink: 0;
        height: 1em;
        width: rem-calc(24px);
        position: relative;
        margin-top: -1px;

        svg {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }
    }

    &__icon + &__text,
    &__text + &__icon {
        margin-left: rem-calc(11px);
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
    }

    @if ($little-fooby) {
        &__bg-background,
        &__bg-foreground {
            transition: fill .2s ease-in;
        }
    }

    &.loading {
        .btn__loader {
            display: block;
        }
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

/* Theme-Modifiers
##################################################################################################################### */

@mixin button-colors($text-color, $border-color, $background-color, $text-color-hover: $background-color, $background-color-hover: $text-color, $border-color-hover: $background-color-hover, $disabled-color: #666666, $disabled-opacity: 0.6) {
    color: $text-color;
    border-color: $border-color;
    background-color: $background-color;

    .animated-stroke {
        stroke: $text-color;
    }

    .animated,
    .svg-fill {
        fill: $text-color;
    }

    svg {
        fill: $text-color;
    }

    &:hover,
    &:active,
    &:focus {
        color: $text-color-hover;
        background-color: $background-color-hover;
        border-color: $border-color-hover;

        .animated-stroke {
            stroke: $text-color-hover;
        }

        .animated,
        .svg-fill {
            fill: $text-color-hover;
        }

        svg {
            fill: $text-color-hover;
        }
    }

    &[disabled] {
        color: $disabled-color;
        opacity: $disabled-opacity;
        text-decoration: line-through;
        background-color: transparent;
        border-color: $disabled-color;
    }

    &.loading {
        color: $theme-color;

        .loader-pot svg * {
            fill: $theme-color;
        }
    }
}

@mixin button-level-bg($background-color, $foreground-color, $hover: true) {
    .btn__bg-background {
        fill: $background-color;
    }

    .btn__bg-foreground {
        fill: $foreground-color;
    }

    @if ($hover) {
        &:hover,
        &:active,
        &:focus {
            .btn__bg-foreground {
                fill: $background-color;
            }
        }
    }
}


.btn--green {
    @include button-colors($color-white, $color-green, $color-green, $color-white, $color-dark-green);
}

.btn--inverted {
    @include button-colors($color-green, $color-green, transparent, $color-white, $color-green);
}

.btn--white {
    @include button-colors($color-white, $color-white, transparent, $color-grey, transparent, $color-grey, $color-grey, 0.4);
}

.btn--danger,
[data-btn-style="danger"] {
    @include button-colors($color-white, $color-danger, $color-danger, $color-white, $color-danger-hover, $color-danger-hover);
}

.btn--danger-inverted {
    @include button-colors($color-danger, $color-danger, transparent, $color-white, $color-danger, $color-danger);
}

.btn--danger-borderless {
    @include button-colors($color-danger, $color-white, transparent, $color-danger-hover, transparent);
    padding-left: 0;
    padding-right: 0;
    border: none;
}

@if ($little-fooby) {
    .btn--little-fooby {
        @include button-colors($color-white, transparent, transparent, $color-white, transparent, transparent);
        padding: rem-calc($buttonPaddingTop + 2) rem-calc($buttonPaddingHorizontal) rem-calc($buttonPaddingBottom + 2);
        border: none;
        letter-spacing: 1px;

        @include button-level-bg($color-lf-cyan-darker, $color-lf-cyan-dark);

        .little-fooby-l2 & {
            @include button-level-bg($color-lf-green-darker, $color-lf-green-dark);
        }

        .little-fooby-l3 & {
            @include button-level-bg($color-lf-magenta-darker, $color-lf-magenta-dark);
        }

        &.little-fooby-l1 {
            @include button-level-bg($color-lf-cyan-darker, $color-lf-cyan-darker);
        }

        &.little-fooby-l2 {
            @include button-level-bg($color-lf-green-darker, $color-lf-green-dark);
        }

        &.little-fooby-l3 {
            @include button-level-bg($color-lf-cyan-darker, $color-lf-magenta-dark);
        }

        &[disabled] {
            @include button-level-bg($color-lf-disabled-hover, $color-lf-disabled, false);
        }
    }

    .btn--little-fooby-secondary {
        @include button-colors($color-lf-cyan-dark, transparent, transparent, $color-white, transparent, transparent);
        @include button-level-bg($color-lf-cyan-dark, $color-lf-cyan-light);

        .little-fooby-l2 & {
            @include button-colors($color-lf-green-dark2, transparent, transparent, $color-white, transparent, transparent);
            @include button-level-bg($color-lf-green-dark, $color-lf-green-light);
        }

        .little-fooby-l3 & {
            @include button-colors($color-lf-magenta-dark, transparent, transparent, $color-white, transparent, transparent);
            @include button-level-bg($color-lf-magenta-dark, $color-lf-magenta-light);
        }

        &.little-fooby-l1 {
            @include button-colors($color-lf-cyan-dark, transparent, transparent, $color-white, transparent, transparent);
            @include button-level-bg($color-lf-cyan-dark, $color-lf-cyan-light);
        }

        &.little-fooby-l2 {
            @include button-colors($color-lf-green-dark2, transparent, transparent, $color-white, transparent, transparent);
            @include button-level-bg($color-lf-green-dark, $color-lf-green-light);
        }

        &.little-fooby-l3 {
            @include button-colors($color-lf-magenta-dark, transparent, transparent, $color-white, transparent, transparent);
            @include button-level-bg($color-lf-magenta-dark, $color-lf-magenta-light);
        }

        &[disabled] {
            @include button-level-bg($color-lf-disabled-secondary-hover, $color-lf-disabled-secondary, false);
        }
    }

    .btn--little-fooby.btn--little-fooby-white {
        @include button-colors($color-dark-stone-grey, transparent, transparent, $color-dark-stone-grey, transparent, transparent);
        @include button-level-bg(#B6B6B6, $color-white);

        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]):focus {
            .btn__bg-foreground {
                fill: #B6B6B6;
            }
        }

        &[disabled] {
            .btn__bg-foreground {
                fill: $color-white;
            }

            opacity: 0.2;
        }
    }
}

/* Modifiers
##################################################################################################################### */

.btn--fullwidth {
    display: flex;
    width: 100%;
    padding-left: rem-calc(19px);
    padding-right: rem-calc(19px);
    text-align: left;
}

.btn--has-bottom-spacer {
    margin-bottom: rem-calc($gutter-small);
}

.btn-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media #{$medium-up} {
        flex-direction: row;
    }

    .btn {
        width: 100%;
        padding-left: rem-calc(10px);
        padding-right: rem-calc(10px);
        text-align: center;

        &:not(:last-child) {
            margin-bottom: rem-calc($gutter-small);

            @media #{$medium-up} {
                margin-right: rem-calc($gutter-medium / 2);
                margin-bottom: 0;
            }
        }
    }

    &--column {
        @media #{$medium-up} {
            flex-direction: column;
        }

        .btn {
            &:not(:last-child) {
                margin-bottom: rem-calc($gutter-small);
                margin-right: 0;
            }
        }
    }

    &--nowrap-mobile {
        @media #{$small-only} {
            flex-direction: row;
        }

        .btn:not(:last-child) {
            @media #{$small-only} {
                margin-right: rem-calc($gutter-small);
                margin-bottom: 0;
            }
        }
    }

    &--switch-mobile {
        @media #{$small-only} {
            flex-direction: column-reverse;
        }

        .btn {
            &:not(:last-child) {
                @media #{$small-only} {
                    margin-top: rem-calc($gutter-small);
                    margin-bottom: 0;
                }
            }
        }
    }

    &--autowidth {
        justify-content: flex-start;

        .btn {
            @media #{$large-up} {
                width: auto;
                padding-left: rem-calc($buttonPaddingHorizontal);
                padding-right: rem-calc($buttonPaddingHorizontal);
            }
        }
    }
}

.btn__loader {
    display: none;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;

    .loader-pot {
        position: absolute;
        width: rem-calc(34px);
        height: rem-calc(34px);
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
