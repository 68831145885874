/* Print
##################################################################################################################### */

@page {
    size: auto; /* auto is the initial value */
    margin: 5mm 0mm; /* this affects the margin in the printer settings */
}

// sass-lint:disable-all
@media print {

    html {
        font-size: 8.6pt;
        margin: 0px;
    }

    body,
    .container {
        margin: 0px;
        padding: 0px;
        float: none;
        background: none #fff;
        max-width: none;
    }

    body {
        padding: 5mm 5mm 5mm 10mm !important;
    }

    body {
        > .header,
        > .header__spacer,
        > .background-panel,
        > .container,
        > .teaser-group-automated,
        > .footer__wrapper,
        .share-group,
        .recipedetail-how-to-videos,
        .btn,
        //.page-header-recipe__panel-inner,
        .page-header-recipe__intro,
        .video__overlay,
        .portion-calculator__calculator-hint,
        .mini-modal,
        .share-group,
        .tooltip,
        .recipe-overlay,
        .search-overlay,
        .meta-info__break,
        .portion-calculator__operation-minus,
        .portion-calculator__operation-plus,
        .page-header-recipe__panel-tags,
        .prev-next-navi {
            display: none !important;
        }
    }

    .page-header-recipe__author {
        display: block;
        float: left;
        margin: 0;
        padding-left: 30px;
    }

    .page-header-recipe__panel-inner {
        padding: 0;
    }

    //.author-info__profile-img {
    //
    //}

    .bring-import-link-fooby {
        display: none !important;
    }

    a[href]:after {
        content: none !important;
    }

    .author-info__profile-img,
    .author-info__copy {
        display: table-cell;
        vertical-align: middle;
    }

    .author-info__profile-img {
        padding-right: 15px;
    }

    //.author-info {
    //    padding-left: 30px;
    //}

    // specific print styles for T5 recipe-detail

    .t5-recipe {
        //padding-top: .5cm;

        .page-header-recipe {
            @include clearfix;
            display: block;
            padding-bottom: .5cm;
            width: 100%;
        }

        //.container {
        //    padding-bottom: 40px;
        //    position: relative;
        //}

        .page-header-recipe__image-container {
            width: auto !important;
            height: auto !important;
        }

        .page-header-recipe__image-container-inner {
            &:before {
                content: none;
            }
        }

        .page-header-recipe__image-floater {
            display: block;
            width: 33% !important;
            height: auto !important;
            float: left;
        }

        .page-header-recipe > .container {
            display: block;
            width: 66% !important;
            float: left;
        }

        .page-header-recipe__panel-detail {
            position: static;
            top: 0 !important;  // for firefox
            padding-left: 30px;
            padding-bottom: 0;

            h1 {
                text-align: left;
            }
        }

        .page-header-recipe__meta-container {
            margin: 0;
            max-width: none;
            padding-bottom: 0;
        }

        .portion-calculator__calculator {
            width: auto;
            margin: 0;
        }

        .portion-calculator__display {
            margin-bottom: 7px;
        }

        .portion-calculator__display-portions,
        .portion-calculator__display-label {
            display: inline;
            font-weight: normal;
            font-size: inherit;
        }

        .recipe-ingredientlist__ingredient-quantity,
        .recipe-ingredientlist__ingredient-desc {
            display: inline-block;
            font-size: inherit;
            font-weight: normal;
            vertical-align: top;
            line-height: 1.1;
        }

        .recipe-ingredientlist__ingredient-quantity {
            margin-right: 0;
            width: 50px;
            min-height: 1px;
        }

        .recipe-ingredientlist__ingredient-desc {
            width: calc(100% - 55px);
        }
    }

    .meta-info {
        padding-left: 20px;
        font-size: inherit;
        line-height: inherit;

        svg {
            width: 17px;
            height:17px;
        }
    }

    .t5-recipe__detail-left,
    .t5-recipe__detail-right {
        float: left;
        display: block;
    }

    .t5-recipe__detail-left {
        vertical-align: top;
        width: 33%;
    }

    .t5-recipe__detail-right {
        vertical-align: top;
        width: 63%;
        padding-left: 3%;
    }

    .t5-recipe__line {
        display: none;
    }

    .t5-recipe__line--spaced {
        margin: 0;
    }

    // General

    .table__wrapper {
        padding: 1rem;

        .floating-title {
            top: -13px;
            left: -16px;
            background-color: #fff !important;
            color: $color-dark-grey;
            font-family: $font-family-open-sans;
            transform: none;
            font-size: 10px;
            letter-spacing: inherit;
        }
    }

    .table_th,
    .table_td {
        font-size: 1rem;
    }

    .spacer.spacer--small {
        display: inline-block;
        height: 15px;
    }

    .h-tx-center {
        text-align: inherit;
    }

    h1,
    .heading--h2 {
        color: $color-dark-grey;

        // Prevents cutting off the top of the headline
        padding-top: rem-calc(10px);
    }

    .h-horizontal-guttered {
        margin-bottom: 0;
    }

    .full-width {
        width: 100%;
        max-width: 100%;
        display: block;
    }

    @page {
        margin: 20mm 10mm;
    }
}
/* scss-lint:enable all */
