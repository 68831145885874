.grocery-list {
    position: relative;
    margin-bottom: rem-calc(40px);

    &__header {
        display: flex;
        flex-direction: column;
        padding-bottom: rem-calc(22px);
        margin-bottom: rem-calc(22px);
        border-bottom: 1px solid $color-dark-offwhite;

        @media #{$medium-up} {
            margin-bottom: rem-calc(13px);
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: rem-calc(30px);
        }
    }

    &__title {
        width: auto;
        margin-bottom: rem-calc(20px);

        @media #{$medium-up} {
            margin-bottom: 0;
        }
    }

    &__settings {
        margin-bottom: rem-calc(22px);
    }

    &__rayon-list,
    &__ingredient-list {
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: normal;
    }

    &__rayon-item,
    &__ingredient-item {
        width: 100%;
        transition: all .5s;
    }

    &__rayon-item {
        &:not(:last-child) {
            padding-bottom: rem-calc(25px);
        }
    }

    &__rayon-title {
        font-family: $font-family-roboto;
        font-size: rem-calc(18px);
        margin-bottom: rem-calc(8px);
        color: rgba(#444444, 0.5);
        line-height: 1.2;
    }

    &__ingredient-list {
        &:not(:empty) {
            border-top: 1px solid $color-dark-offwhite;
        }
    }

    &__ingredient-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: rem-calc(12px) 0px;
        border-bottom: 1px solid $color-dark-offwhite;

        &:not(:last-child)::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: rem-calc(33px); // start of text
            height: 1px;
            background-color: $color-white;
        }

        &--done {
           text-decoration: line-through;
           color: #7FAA47;
        }

        .form-element {
            margin-bottom: 0;
        }

        .form-element > input {
            margin-right: rem-calc(17px);
        }
    }

    &__ingredient-item-middle {
        flex-grow: 1;
    }

    &__ingredient-title {
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
    }

    &__ingredient-unit {
        margin-left: rem-calc(8px);
        font-size: rem-calc(12px);
        color: rgba(#222222, 0.5);
        font-family: $font-family-montserrat;
    }

    &__ingredient-edit {
        display: flex;
        cursor: pointer;
        margin-left: rem-calc(20px);
    }

    &__ingredient-item--done &__ingredient-unit {
        color: rgba(#7FAA47, 0.5);
    }

    &__bought-title {
        text-transform: uppercase;
        color: #999999;
        font-size: rem-calc(12px);
        font-family: $font-family-montserrat;
        text-align: center;
        margin-top: rem-calc(30px);
        cursor: pointer;
        padding-bottom: rem-calc(24px);
        border-bottom: 1px solid $color-dark-offwhite;
        margin-bottom: 0;
        transition: border-bottom-color .2s ease-in;
        transition-delay: .125s;

        svg {
            transition: transform .2s ease-in;
        }

        &--shown {
            border-bottom-color: transparent;
            transition: none;
        }

        &--shown svg {
            transform: rotate(-180deg);
        }
    }

    &__actions {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        margin-top: rem-calc(40px);

        @media #{$medium-up} {
            flex-direction: row;
            margin-top: rem-calc(50px);
        }
    }

    &__actions .btn:not(last-child) {
        margin-bottom: 15px;

        @media #{$medium-up} {
            margin-right: 18px;
            margin-bottom: 0;
        }
    }


    /* Empty view */

    &__empty {
        padding: 28px 0;
        text-align: center;

        @media #{$medium-up} {
            padding: 48px 0;
        }
    }

    &__empty-image {
        margin-bottom: rem-calc(24px);
    }

    &__empty-title {
        font-size: rem-calc(18px);
        margin-bottom: rem-calc(19px);
        line-height: 1.333;
        font-family: $font-family-roboto;
    }

    &__empty-description {
        line-height: 1.875;
    }


    /* Animations */

    &__animation-down-leave-active,
    &__animation-up-leave-active {
        position: absolute;
    }

    &__animation-down-enter,
    &__animation-down-leave-to {
        opacity: 0;
        transform: translateY(30px);
    }

    &__animation-up-enter,
    &__animation-up-leave-to {
        opacity: 0;
        transform: translateY(-30px);
    }

    &__animation-fade-enter-active,
    &__animation-fade-leave-active {
        transition: opacity .125s;
    }

    &__animation-fade-enter,
    &__animation-fade-leave-to {
        opacity: 0;
    }
}
