.print-overlay {
    display: flex;
    flex-grow: 1;

    &__left {
        flex-shrink: 0;
        display: none;

        @media #{$medium-up} {
            display: block;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @media #{$medium-up} {
            padding-left: rem-calc(30px);
        }
    }

    &__header {
        text-align: center;
    }

    &__title {
        @extend %h3;
        margin-bottom: 1px;

        @if ($little-fooby) {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: rem-calc(40px);
        }
    }

    &__subtitle {
        padding-bottom: rem-calc(27px);
        border-bottom: 1px solid $color-dark-offwhite;
    }

    &__quantity-text {
        font-size: rem-calc(12px);
        margin-top: rem-calc(27px);
        font-weight: bold;
    }

    &__text {
        font-size: rem-calc(12px);
        text-align: center;
        font-weight: bold;
        margin-bottom: rem-calc(11px);
    }

    &__option-wrapper {
        display: flex;
        justify-content: center;
        user-select: none;
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: inherit;
    }

    &__option {
        display: flex;

        &:not(:last-child) {
            margin-right: rem-calc($gutter-small);

            @media #{$medium-up} {
                margin-right: rem-calc(26px);
            }
        }
    }

    &__actionbar {
        border-top: 1px solid $color-dark-offwhite;
        margin-top: rem-calc(27px);
        padding-top: rem-calc(30px);
        flex-grow: 1;
        justify-content: flex-end;
    }

    &__placeholder {
        border: 1px solid $color-dark-offwhite;
    }

    .portion-calculator .portion-calculator__calculator {
        padding-top: rem-calc(15px);
        margin-bottom: rem-calc(30px);
        min-height: initial;
    }
}
