/* COMPONENT form-element
##################################################################################################################### */

$form-element-color-placeholder: $color-stone-grey;
$form-element-color-error: $color-error;
$form-element-font-size-input: rem-calc(14px);
$form-element-font-size-label: rem-calc(12px);

.form-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $gutter-small;

    @media #{$medium-up} {
        margin-bottom: rem-calc($gutter-medium / 2);
    }

    &.has-error {
        > label {
            color: $form-element-color-error;
        }

        .form-element__msglabel {
            display: block;
        }

        input[type=text],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"],
        textarea {
            &::placeholder {
                color: $color-error;
            }
        }

        input,
        textarea,
        select {
            color: $form-element-color-error;
            border-color: $form-element-color-error;
        }
    }

    &.is-disabled {
        background-color: $color-offwhite;
    }

    > label {
        font-size: $form-element-font-size-label;
        font-weight: 700;
        display: block;
        margin-bottom: 5px;
    }

    .form-element {
        margin-bottom: 0;
    }

    textarea,
    input[type=text],
    input[type="password"],
    input[type="date"],
    input[type="datetime"],
    input[type="month"],
    input[type="week"],
    input[type="email"],
    input[type="number"],
    input[type="search"],
    input[type="tel"],
    input[type="time"],
    input[type="url"] {
        @extend %form-element-input;
    }

    select {
        @extend %form-element-select;
    }

    &__msgbar {
        overflow: hidden;
        width: 100%;

        &.is-hidden {
            display: none;
        }

        &.is-left {
            > p {
                float: left;
            }
        }

        > p {
            @extend %form-error;
        }
    }

    &__itemwrap {
        display: flex;
    }

    &__itemwrap input[type=checkbox] {
        margin-right: rem-calc(11px);
        top: rem-calc(2px);
    }

    &__item {
        display: flex;
        vertical-align: top;
    }

    &__label {
        width: auto;
        display: inline-block;
        margin-right: rem-calc(32px);
        font-size: $form-element-font-size-input;
        position: relative;
        vertical-align: top;
    }

    &--larger-space {
        margin-bottom: rem-calc(20px);
    }

    &--no-border-right {
        textarea,
        input[type=text],
        input[type="password"],
        input[type="date"],
        input[type="datetime"],
        input[type="month"],
        input[type="week"],
        input[type="email"],
        input[type="number"],
        input[type="search"],
        input[type="tel"],
        input[type="time"],
        input[type="url"] {
            border-right: none;
        }
    }
}
