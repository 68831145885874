/* COMPONENT Page Header Recipe
##################################################################################################################### */

// Used for recipe text
@mixin fluid-recipe-text-font() {
    @include fluid-prop('font-size', 18px, 20px, 0px, $screen-md);
    @include fluid-prop('line-height', 18px * 1.3, 20px * 1.25, 0px, $screen-md);

    @media #{$large-up} {
        @include fluid-prop('font-size', 18px, 20px, $screen-md, $screen-lg);
        @include fluid-prop('line-height', 20px * 1.25, 22px * 1.2, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('font-size', 20px, 23px, $screen-lg, $screen-fullhd);
        @include fluid-prop('line-height', 20px * 1.2, 23px * 1.2, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('font-size', 23px, 40px, $screen-fullhd, $screen-uhd);
        @include fluid-prop('line-height', 23px * 1.2, 40px * 1.2, $screen-fullhd, $screen-uhd);
    }
}

// Used for step title and how-to headline
@mixin fluid-recipe-headline-font() {
    @include fluid-prop('font-size', 11px, 13px, 0px, $screen-md);
    @include fluid-prop('line-height', 11px * 1.5, 13px * 1.4, 0px, $screen-md);

    @media #{$large-up} {
        @include fluid-prop('font-size', 13px, 14px, $screen-md, $screen-lg);
        @include fluid-prop('line-height', 13px * 1.4, 15px * 1.4, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('font-size', 14px, 16px, $screen-lg, $screen-fullhd);
        @include fluid-prop('line-height', 14px * 1.4, 16px * 1.35, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('font-size', 20px, 36px, $screen-fullhd, $screen-uhd);
        @include fluid-prop('line-height', 20px * 1.35, 36px * 1, $screen-fullhd, $screen-uhd);
    }
}

// Used for ingredients text and how-to title
@mixin fluid-recipe-ingredients-font() {
    @include fluid-prop('font-size', 16px, 18px, 0px, $screen-md);
    @include fluid-prop('line-height', 16px * 1.4, 18px * 1.35, 0px, $screen-md);

    @media #{$large-up} {
        @include fluid-prop('font-size', 18px, 20px, $screen-md, $screen-lg);
        @include fluid-prop('line-height', 18px * 1.35, 20px * 1.3, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('font-size', 18px, 20px, $screen-lg, $screen-fullhd);
        @include fluid-prop('line-height', 18px * 1.3, 20px * 1.2, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('font-size', 20px, 42px, $screen-fullhd, $screen-uhd);
        @include fluid-prop('line-height', 20px * 1.2, 42px * 1.15, $screen-fullhd, $screen-uhd);
    }
}

// Used for ingredients padding
@mixin fluid-recipe-ingredients-padding() {
    @include fluid-prop('padding-bottom', 8px, 10px, 0px, $screen-md);

    @media #{$large-up} {
        @include fluid-prop('padding-bottom', 10px, 12px, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('padding-bottom', 12px, 14px, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('padding-bottom', 14px, 18px, $screen-fullhd, $screen-uhd);
    }
}

// Used for inner padding of inner area
@mixin fluid-inner-area-padding() {
    @media #{$large-up} {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    @media #{$large-up} {
        @include fluid-prop('padding', 32px, 32px, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('padding', 32px, 38px, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('padding', 38px, 50px, $screen-fullhd, $screen-uhd);
    }
}

// Used for gutter padding between step title and recipe text
@mixin fluid-inner-area-gutter-padding() {
    @media #{$large-up} {
        @include fluid-prop('margin-bottom', 30px, 32px, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('margin-bottom', 32px, 36px, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('margin-bottom', 36px, 40px, $screen-fullhd, $screen-uhd);
    }
}

// Used for overlay close button
@mixin fluid-button-size() {
    @include fluid-prop('width', 48px, 48px, 0px, $screen-md);
    @include fluid-prop('height', 48px, 48px, 0px, $screen-md);

    @media #{$large-up} {
        @include fluid-prop('width', 48px, 48px, $screen-md, $screen-lg);
        @include fluid-prop('height', 48px, 48px, $screen-md, $screen-lg);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('width', 48px, 55px, $screen-lg, $screen-fullhd);
        @include fluid-prop('height', 48px, 55px, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('width', 55px, 96px, $screen-fullhd, $screen-uhd);
        @include fluid-prop('height', 55px, 96px, $screen-fullhd, $screen-uhd);
    }
};

@mixin fluid-notes-button-position() {
    left: rem-calc(24px);

    @media #{$large-up} {
        left: auto;
        right: rem-calc(85px);
    }

    @media #{$xlarge-up} {
        @include fluid-prop('right', 85px, 96px, $screen-lg, $screen-fullhd);
    }

    @media #{$fullhd-up} {
        @include fluid-prop('right', 96px, 157px, $screen-fullhd, $screen-uhd);
    }
}

// sass-lint:disable-all
#overlay-repo {
    display: none;
}
/* scss-lint:enable all */


/* scss-lint:disable NestingDepth SelectorDepth MergeableSelector */
.recipe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    background-color: white;

    .container {
        max-width: none;
    }

    &.is-show {
        display: block;
    }

    repo {
        display: none;
    }

    .table__wrapper {
        margin-top: 3em;

        @media #{$medium-up} {
            margin-top: 3em;
        }
    }

    .infotable-app {
        margin-top: 1.25em;

        @media #{$medium-up} {
            margin-top: 3em;
        }

        @media #{$large-up} {
            margin-top: 0;
        }
    }

    // .btn.step {
    //     border: 0;
    //     background-color: $color-green;
    //     width: 100%;
    //     height: 100%;
    //     padding: 0;
    //     cursor: pointer;
    //     display: table;

    //     span {
    //         transform: translate(-50%, -50%);
    //         position: absolute;
    //         left: 50%;
    //         top: 50%;
    //         color: white;
    //     }
    // }

    .controller-interface {
        position: relative;
        overflow: hidden;

        // &::after {
        //     visibility: hidden;
        //     display: block;
        //     font-size: 0;
        //     content: " ";
        //     clear: both;
        //     height: 0;
        // }

        .btn.step {
            border: 0;
            background-color: transparent;
            // width: rem-calc(140px);
            user-select: none;
            width: auto;
            height: auto;
            // height: 100%;
            padding: 0;
            cursor: pointer;

            &.next {
                text-align: right;
            }

            &.prev {
                text-align: left;
            }

            &.prev .btn__icon {
                transform: rotate(180deg);
            }

            // span {
            //     transform: translate(-50%, -50%);
            //     position: absolute;
            //     left: 50%;
            //     top: 50%;
            //     color: $color-green;
            // }

            @if ($little-fooby) {
                color: $theme-color;

                .little-fooby-l2 & {
                    color: $theme-color-l2;
                }

                .little-fooby-l3 & {
                    color: $theme-color-l3;
                }
            }
        }

        &__progressbar {
            width: 100%;
            height: rem-calc(3px);
            background-color: $color-light-grey;
            display: block;
            position: relative;
            // position: absolute;
            // left: 0;
            // top: 0;
            flex-shrink: 0;

            .progress {
                transition: width .5s ease-out;
                width: 30%;
                height: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;

                @if ($little-fooby) {
                    background-color: $theme-color;

                    .little-fooby-l2 & {
                        background-color: $theme-color-l2;
                    }

                    .little-fooby-l3 & {
                        background-color: $theme-color-l3;
                    }
                } @else {
                    background-color: $color-fooby-orange;
                }
            }
        }

        &__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 rem-calc(16px);
            width: 100%;
            flex-grow: 1;

            @media #{$large-up} {
                padding: 0 rem-calc(40px);
            }

            &--right {
                justify-content: flex-end;
            }
        }

        &.top {
            width: 100%;
            $temp: rem-calc(50px);
            height: calc(100% - #{$temp});
            overflow-y: auto;

            @media #{$large-up} {
                display: flex;
            }
        }

        &.bottom {
            height: rem-calc(50px);
            display: flex;
            flex-direction: column;
        }

        &--utensils.top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .utensils {
                margin: rem-calc(36px) 0;

                @media #{$medium-up} {
                    width: 90%;
                    margin: rem-calc(50px) 0;
                }

                @media #{$large-up} {
                    width: 80%;
                }

                @media #{$xlarge-up} {
                    width: 65%;
                }
            }

            .recipe-utensils-header {
                text-align: center;
                margin-top: rem-calc(20px);
                margin-bottom: 0;
                width: calc(100% - #{rem-calc(160px)});

                @media #{$medium-up} {
                    margin-top: rem-calc(55px);
                }

                @media #{$large-up} {
                    width: 55%;
                }
            }
        }
    }

    .howto-link {
        cursor: pointer;
        display: block;
        padding-top: 1rem;
        overflow: hidden;

        &:last-of-type {
            padding-bottom: 2rem;
        }

        &:hover {
            text-decoration: none;
        }

        .wrapper {
            //display: table;

            > div {
                //display: table-cell;
                vertical-align: middle;
                float: left;

                > span {
                    display: block;
                    color: black;
                    padding-left: 1rem;

                    &:first-child {
                        font-weight: 700;
                        text-transform: uppercase;
                        @include fluid-recipe-headline-font();

                        @if ($little-fooby) {
                            color: $theme-color;

                            .little-fooby-l2 & {
                                color: $theme-color-l2;
                            }

                            .little-fooby-l3 & {
                                color: $theme-color-l3;
                            }
                        } @else {
                            color: $color-fooby-orange;
                        }
                    }

                    &:last-child {
                        @include fluid-recipe-ingredients-font();
                    }
                }
            }
        }

        .icon {
            width: 2.75em;
            height: 2.75em;
            border-radius: 50%;
            position: relative;
            background-color: $theme-color;

            @if ($little-fooby) {
                .little-fooby-l2 & {
                    background-color: $theme-color-l2;
                }

                .little-fooby-l3 & {
                    background-color: $theme-color-l3;
                }
            }

            svg {
                transform: translate(-32%, -50%);
                position: absolute;
                top: 50%;
                left: 50%;
                color: white;
            }
        }

        .labels {
            $temp: rem-calc(45px);
            max-width: calc(100% - #{$temp});
        }
    }

    .recipe-overlay__notes-button,
    .recipe-overlay--close {
        @include fluid-button-size();
        z-index: 1;
        top: 1em;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        display: block;
        backface-visibility: hidden;
        transition: all $animation-time-default $animation-easing-default;

        .recipeoverlay-active & {
            will-change: transform;
        }

        svg {
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            height: 40%;
        }
    }

    .recipe-overlay__notes-button {
        @include fluid-notes-button-position();
        background-color: $color-white;
        border: 1px solid $theme-color;

        @if ($little-fooby) {
            .little-fooby-l2 & {
                color: $theme-color-l2;
                border: 1px solid $theme-color-l2;
            }

            .little-fooby-l3 & {
                color: $theme-color-l3;
                border: 1px solid $theme-color-l3;
            }
        }

        &:hover,
        &.has-note {
            @if ($little-fooby) {
                color: $color-white;
                background-color: $theme-color;

                .little-fooby-l2 & {
                    background-color: $theme-color-l2;
                }

                .little-fooby-l3 & {
                    background-color: $theme-color-l3;
                }
            } @else {
                color: $theme-color;
            }
        }
    }

    .recipe-overlay--close {
        background-color: $color-light-grey;
        border: 1px solid $color-light-grey;
        right: rem-calc(24px);

        &:hover {
            background-color: $color-dark-offwhite;
            border-color: $color-dark-offwhite;
        }
    }

    .overlay-page {
        width: 100%;
        height: 100%;
        display: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &.is-active {
            display: block;

            @media #{$large-up} {
                display: flex;
            }
        }

        @media #{$large-up} {
            height: 100%;
            flex-direction: row;
            overflow-y: hidden;
        }

        .area {
            @media #{$large-up} {
                order: 0;
                flex: 1 2 80%;
                align-self: auto;
            }
        }

        .area.one {
            @media #{$large-up} {
                @if ($little-fooby) {
                    background-color: $panel-background-color;

                    .little-fooby-l2 & {
                        background-color: $panel-background-color-l2;
                    }

                    .little-fooby-l3 & {
                        background-color: $panel-background-color-l3;
                    }
                } @else {
                    background-color: #f5f5f5;
                }
            }
        }
    }

    .overlay-page.p1 {
        @media #{$large-up} {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: stretch;
            overflow-y: hidden;
        }

        .recipe-ingredientlist__ingredient-wrapper {
            display: block;
            padding-bottom: 0;
            margin-bottom: rem-calc(10px);
        }

        .recipe-ingredientlist__ingredient-checkbox {
            display: none;

            &:checked + .recipe-ingredientlist__ingredient .recipe-ingredientlist__ingredient-quantity,
            &:checked + .recipe-ingredientlist__ingredient .recipe-ingredientlist__ingredient-desc {
                text-decoration: line-through;
            }

            &:checked + .recipe-ingredientlist__ingredient .recipe-ingredientlist__ingredient-check-icon {
                display: block;
            }
        }

        .recipe-ingredientlist__ingredient-desc {
            // 101px (normal width of ingredient-desc) + 13px (width of check-icon element)
            width: calc(100% - #{rem-calc(114px)});
        }

        .recipe-ingredientlist__ingredient-check-icon {
            display: none;
        }

        .area.one {
            display: block;

            @media #{$large-up} {
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: flex-start;
                align-items: stretch;
                display: flex;
            }

            > .fooby-image {
                order: 0;
                flex: 0 1 auto;
                align-self: auto;
            }

            > .headerpanel {
                order: 0;
                flex: 1 0 auto;
                align-self: auto;
                background-color: transparent;
            }
        }

        .area.two {
            display: block;

            @media #{$large-up} {
                flex: 0 0 rem-calc(455px);
                height: 100%;
            }

            .inner-area {
                height: 100%;
                padding-top: rem-calc(8px);

                @media #{$large-up} {
                    overflow-y: auto;
                    padding: rem-calc(32px);
                    -webkit-overflow-scrolling: touch;
                }

                .h-horizontal-guttered {
                    @media #{$large-up} {
                        margin-bottom: 0;
                    }
                }
            }

            .recipe-ingredientlist-header {
                padding-bottom: rem-calc(13px);
                text-align: center;
                font-size: rem-calc(18px);

                @media #{$large-up} {
                    text-align: left;
                    padding-bottom: rem-calc(20px);

                    @if ($little-fooby) {
                        font-size: rem-calc(38px);
                        line-height: 1.05;
                        font-family: $headings-font-family;
                    } @else {
                        font-size: 1em;
                    }
                }
            }

            .recipe-ingredientlist {
                padding-bottom: rem-calc(32px);

                .spacer:first-child {
                    display: none;
                }
            }

            .meta-info {
                padding-bottom: rem-calc(24px);
                display: table;
                margin: auto;

                @media #{$large-up} {
                    display: block;
                    padding-bottom: rem-calc(24px);
                }
            }
        }

        .district.one {
            height: 100%;
            overflow: hidden;
            // padding-bottom: 3em;

            // @media #{$large-up} {
            //     padding-bottom: 0;
            //     height: calc(100% - 50px);
            // }
        }

        // .district.two {
        //     position: fixed;
        //     bottom: 0;
        //     left: 0;
        //     display: block;
        //     height: rem-calc(50px);
        //     width: 100%;
        //     z-index: 9999;

        //     @media #{$large-up} {
        //         position: static;
        //         width: 100%;
        //         left: auto;
        //         top: auto;
        //         height: rem-calc(50px);
        //         z-index: 0;
        //     }
        // }
    }

    .floating-title--pull-up {
        position: relative;
        top: rem-calc(-15px);
    }

    .overlay-page.p2 {
        overflow-y: hidden;

        @media #{$large-up} {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: stretch;
        }

        @if ($little-fooby) {
            .counter-title {
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $headings-font-family;

                @media #{$large-up} {
                    justify-content: flex-start;
                    margin-top: rem-calc(-10px);
                }
            }

            .counter-title__text {
                font-size: rem-calc(38px);
                line-height: 1.05;
                font-family: $headings-font-family;
                margin-left: rem-calc(32px);
                margin-right: rem-calc(32px);
                margin-top: rem-calc(12px);

                @media #{$large-up} {
                    margin-top: 0;
                    margin-left: rem-calc(19px);
                }
            }

            .counter-title__count {
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -50%);
                margin: 0 rem-calc(-4px);
                color: $color-lf-cyan-dark;
                background-image: url('../assets/images/little-fooby/backgrounds/counter-l1.svg');
                background-size: 100% 100%;
                width: rem-calc(62px);
                height: rem-calc(59px);
                font-size: rem-calc(20px);
                letter-spacing: 0.8px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media #{$large-up} {
                    position: static;
                    top: auto;
                    left: auto;
                    transform: none;
                }

                .little-fooby-l2 & {
                    color: $color-lf-green-dark2;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l2.svg');
                }

                .little-fooby-l3 & {
                    color: $color-lf-magenta-dark;
                    background-image: url('../assets/images/little-fooby/backgrounds/counter-l3.svg');
                }
            }

            .counter-title__count-big {
                font-size: rem-calc(30px);
                line-height: 1;
                letter-spacing: 2.4px;
            }
        }

        .recipe-instructions {
            @include fluid-recipe-text-font();
        }

        .controller-interface.top.is-default .recipe-instructions {
            @media #{$fullhd-up} {
                max-width: 75%;
            }

            @media #{$uhd-up} {
                max-width: 60%;
            }
        }

        .recipe-ingredientlist__step-wrapper {
            width: 100%;
            display: table;

            @if ($little-fooby) {
                @media #{$small-only} {
                    padding-bottom: 1.5em;
                }

                @media #{$large-up} {
                    padding-top: 1.5em;
                    padding-bottom: 1.5em;
                }
            }
            @else {
                @media #{$large-up} {
                    border-top: 2px solid $color-light-grey;
                    padding-top: 1.5em;
                }
            }
        }

        .decoration-line {
            margin-bottom: 1.5em;

            @media #{$medium-only} {
                display: none;
            }

            @media #{$large-up} {
                margin-bottom: 0;
            }
        }

        .recipe-ingredientlist__ingredient-wrapper {
            display: table-row;
        }

        .recipe-ingredientlist__ingredient-desc,
        .recipe-ingredientlist__ingredient-quantity {
            @include fluid-recipe-ingredients-font();
            @include fluid-recipe-ingredients-padding();
            display: table-cell;
            vertical-align: top;
        }

        .recipe-ingredientlist__ingredient-quantity {
            white-space: nowrap;
            width: 1px;
            font-weight: 400;
            padding-right: .8em;
            float: none;
            color: $color-stone-grey;
            min-width: rem-calc(50px);
        }

        .recipe-ingredientlist__ingredient-desc {
            width: auto;
            padding-right: 0;
            color: $color-stone-grey;
        }

        .recipe-ingredientlist__ingredient-wrapper {
            padding-bottom: 0;
        }

        .floating-title {
            @include fluid-recipe-headline-font();
            display: table;
            margin: auto;

            @media #{$large-up} {
                display: inline-block;
                margin: 0;
            }
        }

        .controller-interface.is-default {
            justify-content: flex-start;

            .area.one {
                height: rem-calc(150px);
                background-repeat: repeat;

                @if ($little-fooby) {
                    background-image: url("../assets/images/default-pattern-little-fooby.jpg");
                } @else {
                    background-image: url("../assets/images/default-pattern.jpeg");
                }

                @media #{$large-up} {
                    height: auto;
                    flex: 0 0 30%;
                }
            }

            .area.two {
                flex: 1 1 80%;
            }
        }

        .area.one {
            display: block;
            position: relative;
            //flex-direction: column;
            //flex-wrap: nowrap;
            //justify-content: space-between;
            //align-content: flex-start;
            //align-items: stretch;
            //flex: 0 1 auto;

            @media #{$large-up} {
                //display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: flex-start;
                align-items: stretch;
                flex: 1 2 80%;
            }

            > .fooby-image {
                //order: 0;
                //flex: 0 1 auto;
                //align-self: auto;
                //height: 100%;

                .fooby-image__img {
                    display: block;
                    width: 100%;
                }
            }

            > .headerpanel {
                @if ($little-fooby) {
                    position: absolute;
                    bottom: 2.5em;
                    transform: translateY(100%);
                    margin-top: 0;
                } @else {
                    background-color: transparent;

                    @media #{$large-up} {
                        background-color: #f5f5f5;
                    }
                }
            }
        }

        .area.temp {
            flex: 0 0 auto;
            display: block;

            @media #{$large-up} {
                display: flex;
            }

            .row {
                margin: 0;
            }
        }

        .area.two {
            display: block;

            @if ($little-fooby) {
                padding-top: rem-calc(60px);
            }

            @media #{$medium-up} {
                display: flex;
            }

            @media #{$large-up} {
                order: 0;
                flex-direction: column;
                flex: 0 1 rem-calc(455px);

                @if ($little-fooby) {
                    padding-top: 0;
                }
            }

            @media #{$xlarge-up} {
                flex: 0 1 40%;
            }

            .inner-area {
                overflow-y: auto;
                -webkit-overflow-scrolling: auto;
                padding-top: 0;
                padding-bottom: 0;
                @include fluid-inner-area-padding();
            }

            .recipe-ingredientlist-header {
                padding-bottom: rem-calc(13px);
                text-align: center;
                font-size: 1.25em;

                @media #{$large-up} {
                    text-align: left;
                    padding-bottom: rem-calc(20px);
                    font-size: 1em;
                }
            }

            .recipe-ingredientlist {
                padding-bottom: rem-calc(32px);
            }

            .meta-info {
                padding-bottom: rem-calc(24px);
                display: table;
                margin: auto;

                @media #{$large-up} {
                    display: block;
                    padding-bottom: rem-calc(24px);
                }
            }
        }

        .district.one {
            padding-bottom: 0;

            @media #{$medium-up} {
                @if ($little-fooby == false) {
                    flex-grow: 1;
                }
            }
            @media #{$medium-only} {
                @if ($little-fooby) {
                    order: 1;
                    width: auto;
                    flex-basis: 40%;
                    flex-shrink: 0;
                }
            }

            @media #{$large-up} {
                @if ($little-fooby == false) {
                    flex-basis: 0;
                    flex-grow: 1;
                    overflow: auto;
                }

                padding-bottom: 0;
            }

            .inner-area {
                .h-horizontal-guttered {
                    @include fluid-inner-area-gutter-padding();
                }
            }
        }

        .district.two {
            display: block;
            width: 100%;
            padding-bottom: rem-calc(25px);

            @media #{$medium-up} {
                width: auto;
                flex-shrink: 0;

                @if ($little-fooby) {
                    flex-basis: 60%;
                }
                @else {
                    flex-basis: 40%;
                }
            }

            @media #{$large-up} {
                flex-shrink: 0;
                flex-basis: auto;
                align-self: flex-end;
                padding-bottom: 0;
                width: 100%;
                z-index: 0;
            }

            .inner-area {
                @media #{$large-up} {
                    padding-top: 0;
                    padding-bottom: .75em;
                }
            }
        }
    }

    .overlay-page.p3 {
        // Many are not needed, but these don't break the page
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-content: flex-start;
        align-items: stretch;
        overflow-y: hidden;

        .share-group__single-share-icon {
            display: flex;

            @media #{$medium-up} {
                display: inline-flex;
            }

            @media #{$large-up} {
                display: flex;
                margin-right: 0;
            }
        }

        .table__wrapper {
            padding: 1rem 1.75rem 1.75rem;

            .floating-title {
                transform: translateY(-50%);
                padding: .75em 1em;
                font-size: 10px;
                left: auto;

                @if ($little-fooby == false) {
                    background-color: $color-stone-grey;
                }
            }
        }

        .share-group .share-group__single {
            float: none;

            @media #{$large-up} {
                float: left;
            }
        }

        .endtitle {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: 3rem;
            color: $headings-color;
            text-align: center;

            @media #{$large-up} {
                text-align: left;
            }
        }

        .recipe-ingredientlist__step-wrapper {
            border-top: 2px solid $color-light-grey;
            padding-top: 1.5em;
        }

        .recipe-ingredientlist__ingredient-quantity {
            font-weight: 400;
            padding-right: .5em;
            width: auto;
            color: $color-stone-grey;
        }

        .recipe-ingredientlist__ingredient-desc {
            padding-right: 0;
            color: $color-stone-grey;
        }

        .recipe-ingredientlist__ingredient-wrapper {
            padding-bottom: .325rem;
        }

        .floating-title {
            display: table;
            margin: auto;

            @media #{$large-up} {
                display: inline-block;
                margin: 0;
            }
        }

        .controller-interface {
            display: block;

            @media #{$large-up} {
                display: flex;
            }

            &.bottom {
                display: flex;
            }
        }

        .area.one {
            // Probably alot of these are not needed
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: flex-start;
            align-items: stretch;
            flex: 0 1 auto;

            @media #{$medium-up} {
                flex: 0 1 auto;
            }

            @media #{$large-up} {
                flex: 1 2 80%;
            }

            > .fooby-image {

                .fooby-image__img {
                    display: block;
                    width: 100%;
                }
            }

            > .headerpanel {
                order: 0;
                flex: 1 0 auto;
                align-self: auto;
                background-color: transparent;

                @media #{$large-up} {
                    background-color: #f5f5f5;
                }

                .headerpanel__inner {
                    width: 100%;
                    padding-bottom: 0;

                    @media #{$large-up} {
                        padding-bottom: 3em;
                    }
                }
            }
        }

        .area.temp {
            flex: 0 0 auto;

            .row {
                margin: 0;
            }
        }

        .area.two {
            display: block;

            @media #{$medium-up} {
                flex-flow: row nowrap;
                align-content: flex-start;
                align-items: stretch;
                align-self: auto;
                display: flex;
                flex: 1 1 0;
                justify-content: space-between;
                order: 0;
            }

            @media #{$large-up} {
                height: 100%;
                flex-direction: column;
                flex: 0 0 rem-calc(455px);
            }

            .inner-area {
                overflow-y: auto;
                -webkit-overflow-scrolling: auto;
                height: 100%;
                padding-top: rem-calc(8px);
                padding-bottom: 0;

                @media #{$large-up} {
                    padding: rem-calc(32px);
                    overflow-y: scroll;
                    -webkit-overflow-scrolling: touch;
                }
            }

            .recipe-ingredientlist-header {
                padding-bottom: rem-calc(13px);
                text-align: center;
                font-size: 1.25em;

                @media #{$large-up} {
                    text-align: left;
                    padding-bottom: rem-calc(20px);
                    font-size: 1em;
                }
            }

            .recipe-ingredientlist {
                padding-bottom: rem-calc(32px);
            }

            .meta-info {
                padding-bottom: rem-calc(24px);
                display: table;
                margin: auto;

                @media #{$large-up} {
                    display: block;
                    padding-bottom: rem-calc(24px);
                }
            }
        }

        .district.one {
            align-self: auto;
            flex: 1 1 auto;
            height: auto;
            order: 0;
            padding-bottom: 0;
            overflow: hidden;

            @media #{$medium-up} {
                padding-bottom: 3em;
                height: 100%;
            }

            @media #{$large-up} {
                flex: 1 1 0;
                padding-bottom: 0;
            }
        }
    }

    .notes {
        font-size: rem-calc(14px);

        @media #{$large-up} {
            margin-left: rem-calc(-32px);
            margin-right: rem-calc(-32px);
            padding-left: rem-calc(32px);
            padding-right: rem-calc(32px);
            border-left: none;
            border-right: none;
        }
    }
}
/* scss-lint:enable NestingDepth SelectorDepth MergeableSelector */

.headerpanel {
    width: 100%;
    padding: 0 1em;
    margin-top: -2.5em;

    @media #{$medium-up} {
        padding: 0 2em;
    }

    @media #{$large-up} {
        padding: 0 3em;
        margin-top: 0;
    }

    .headerpanel__inner {
        @extend %headerpanel-title;

        position: static;
        display: table;
        margin: 0 auto;
        padding: rem-calc(20px) rem-calc(10px) rem-calc(26px);
        transform: translateY(0%);
        z-index: 10;

        @if ($little-fooby) {
            background-image: url('../assets/images/little-fooby/backgrounds/cookingview-header-panel.svg');
            background-size: 100% 100%;
            background-repeat: no-repeat;
        } @else {
            background-color: $color-white;
        }

        h1 {
            margin: 0;
            padding: 0 rem-calc(32px);
            text-align: center;
        }

        @media #{$large-up} {
            @if ($little-fooby) {
                background-image: url('../assets/images/little-fooby/backgrounds/header-panel-medium.svg');
            }
        }

        @media #{$large-up} {
            transform: translateY(-50%);
        }

        .floating-title {
            @include floating-title();
        }
    }
}
