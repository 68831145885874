
$border-color: #f0f0f0;
/* scss-lint:disable QualifyingElement PlaceholderInExtend */
.culinaria-commentcomponent-hide {
    display: none;
}

.comments__padder {
    padding: rem-calc(15px) rem-calc(20px);

    @media #{$medium-up} {
        padding: rem-calc(25px) rem-calc(30px) rem-calc(20px);
    }
}

// compose

.scf-composer-block {
    border: 1px solid $border-color;
}

.scf-composer-toolbar,
.scf-comment-toolbar.save-delete-bar {
    border-top: 1px solid $border-color;
    padding: rem-calc(15px) rem-calc(20px);

    @media #{$medium-up} {
        padding: rem-calc(15px) rem-calc(30px);
    }
}

.text-buble-icon {
    display: block;
    float: left;

    // should stay pixel
    width: 22px;
    margin-right: 10px;

    .fill {
        fill: $color-stone-grey;
    }
}

.scf-composer-msg {
    border: 0 none;
    padding: 0;
    width: calc(100% - 32px);
    font-size: rem-calc(14px);
    line-height: (20 / 14);
    resize: vertical;
}

.culinaria-nickname-textbox {
    border-top: 1px solid $border-color;
    padding: rem-calc(20px) 0 0;
    overflow: hidden;
}

.culinaria-nickname-text,
.culinaria-nickname-error,
.culinaria-nickname-text-reply,
.culinaria-nickname-error-reply {
    font-size: rem-calc(12px);
    line-height: (17 / 12);
    display: block;
    float: left;
    width: 100%;
    margin-bottom: rem-calc(5px);

    @media #{$medium-up} {
        padding-right: rem-calc(50px);
        width: calc(100% - 266px);
    }
}

.culinaria-nickname-error,
.culinaria-nickname-error-reply {
    color: $color-danger;
}

input.culinaria-nickname-input {
    height: rem-calc(44px);
    width: 100%;
    border-color: $border-color;
    font-size: rem-calc(12px);
    color: $color-dark-stone-grey;

    @media #{$medium-up} {
        width: rem-calc(265px);
    }
}

// comments list

.scf-comments-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}


// comments

.scf-comment {
    border: 1px solid $border-color;
    padding: 0;
    margin: rem-calc(20px) 0 0;
}

.scf-comment-author-name {
    overflow: hidden;
    margin-bottom: rem-calc(3px);
}

.scf-nickname {
    margin-right: rem-calc(15px);
    font-size: rem-calc(16px);
    line-height: (30 / 16);
    display: block;
    float: left;
    font-weight: 700;
}

.scf-comment-time {
    font-size: rem-calc(12px);
    color: $color-stone-grey;
    display: block;
    float: left;
    line-height: (33 / 12);
}

.scf-comment-msg p {
    font-size: rem-calc(14px);
    line-height: (20 / 14);
    margin: 0;
}

.culinaria-success-overlay-reply,
.culinaria-success-overlay {
    background-color: $color-dark-stone-grey;
    color: $color-white;
    text-align: center;
    position: relative;
    padding: rem-calc(30px);

    .save-big {
        height: rem-calc(50px);
        width: rem-calc(50px);
    }

    .close-icon {
        position: absolute;
        top: rem-calc(20px);
        right: rem-calc(20px);
        cursor: pointer;

        .svg-stroke {
            stroke: $color-white;
        }
    }

    p {
        font-size: rem-calc(14px);
        line-height: (20 / 14);
        text-align: center;
        margin: 0;
    }
}

.culinaria-login-overlay,
.culinaria-login-overlay-reply {
    position: relative;

    .close-icon {
        position: absolute;
        top: rem-calc(20px);
        right: rem-calc(20px);
        cursor: pointer;

        .svg-stroke {
            stroke: $color-stone-grey;
        }
    }
}

.culinaria-login-overlay-link {
    width: 100%;
    margin-bottom: rem-calc(5px);

    @media #{$medium-up} {
        width: auto;
        margin-bottom: 0;
    }
}

.scf-comment-replies {
    border-top: 1px solid $border-color;
    margin-top: rem-calc(5px);

    .scf-comments-list,
    .scf-comment-toolbar {
        padding-left: rem-calc(40px);

        @media #{$medium-up} {
            padding-left: rem-calc(50px);
        }
    }

    .scf-comment {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        margin-top: 0;
    }

    .comments__padder {
        padding: rem-calc(20px) rem-calc(30px) rem-calc(25px) 0;
    }

    .scf-composer-block {
        border: 0;
    }

    .scf-composer-toolbar {
        padding-left: 0;
    }

    .text-buble-icon {
        display: none;
    }

    .scf-composer-msg {
        width: 100%;
    }
}




.culinaria-load-more {
    @extend .btn;
    @extend .btn--inverted;

    width: 100%;
    cursor: pointer;
    margin-top: rem-calc(20px);
}
/* scss-lint:enable QualifyingElement PlaceholderInExtend */
