/* COMPONENT C62 Einkaufslistse
##################################################################################################################### */

$shoppinglist-divider-color: #CCCCCC;

/* scss-lint:disable QualifyingElement */
.mini-modal--shoppinglist .mini-modal__flex-flex--scrollable .mini-modal__flex-flex-inner {
    padding-right: rem-calc(16px);
}

.mini-modal--shoppinglist .mini-modal__flex {
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);

    @media #{$medium-up} {
        min-height: rem-calc(400px);
    }
}

.mini-modal--shoppinglist .mini-modal__content {
    @media #{$medium-up} {
        padding: 0;
    }
}

.mini-modal--shoppinglist .mini-modal__flex-flex {
    overflow: auto;
}

.shoppinglist--nostock {
    .shoppinglist__subtitle {
        display: none;
    }
}

.shoppinglist__scrollarea {
    position: relative;
}

.shoppinglist h3 {
    @if ($little-fooby) {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        font-size: rem-calc(40px);
    }
}

.shoppinglist__subtitle {
    margin: rem-calc(22px) 0 rem-calc(7px);
    padding: 0;
    font-size: rem-calc(12px);
    line-height: 1.416;

    &.shoppinglist__subtitle--nospace {
        margin-top: 0;
    }
}

.shoppinglist__gradient {
    height: rem-calc(30px);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(240,240,240,1) 100%);
}

.shoppinglist__list {
    list-style: none;
    padding: 0;
    line-height: 1;
    margin: 0;
}

.shoppinglist__item {
    &:not(:last-child) {
        margin-bottom: rem-calc(10px);
    }
}

.shopping-itemrow {
    display: flex;
}

.shopping-itemrow__item {
    display: table-cell;
    vertical-align: middle;
    position: relative;

    &:first-child {
        width: rem-calc(64px);
        flex-shrink: 0;
    }

    &:nth-child(2) {
        flex-grow: 1;
    }
}

.shopping-itemrow__label {
    position: relative;
    width: 100%;
    display: block;
    padding: rem-calc(7.5px) rem-calc(13px);
    border: 1px solid $shoppinglist-divider-color;
    padding-right: rem-calc(40px);
    font-size: rem-calc(14px);
    line-height: 1.7;
    background-color: $color-light-grey;
}

input.itemrow__input {
    width: 100%;
    height: rem-calc(40px);
    border-right: none;
    font-size: rem-calc(14px);
    padding: 0;
    text-align: center;
    background-color: white;
}

.shopping-itemrow__trigger {
    position: absolute;
    right: rem-calc(13px);
    top: 50%;
    transform: translateY(-50%);
    width: rem-calc(20px);
    height: rem-calc(20px);
    border-radius: 100%;
    border: 1px solid $color-green;
    display: block;
    transform-origin: center center;
    cursor: pointer;

    &::before {
        //transition: transform 800ms;
        width: rem-calc(10px);
        height: 1px;
        background-color: $color-green;
        transform: translate(-50%, -50%) rotate(0deg);
        transform-origin: center center;
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        display: block;
    }

    &::after {
        width: 0;
        height: 1px;
        background-color: $color-green;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
    }
}

.shopping-itemrow__trigger--positive {
    &::before {
        transform: translate(-50%, -50%) rotate(90deg);
        transform-origin: center center;
    }

    &::after {
        width: rem-calc(10px);
    }
}

.shopping-itemrow__trigger--negative {
    border-color: $color-error;

    &::before,
    &::after {
        background-color: $color-error;
    }
}

.shoppinglist__list--ingredients {
    .shopping-itemrow__trigger--positive {
        display: none;
    }
}

.shoppinglist__list--stock {
    .shopping-itemrow__trigger--negative {
        display: none;
    }
}


.shoppinglist__add {
    position: relative;
}

input.shoppinglist__addinput {
    width: 100%;
    height: rem-calc(40px);
    font-size: rem-calc(14px);
    color: black;
    padding: 0;
    text-align: left;
    padding-left: rem-calc(12px);
    background-color: white;
}

input.shoppinglist__mail {
    width: 100%;
    height: rem-calc(40px);
    padding: 0;
    margin: 0;
    padding-left: rem-calc(16px);
    font-size: rem-calc(14px);

    @if ($little-fooby == false) {
        border-right: none;
    }
}

.shoppinglist__footer {
    width: 100%;
    display: block;
}

.shoppinglist__innerwrapper {
    & > *:first-child {
        margin-top: 0;
    }
}

.shopping-itemrow--mail {
    margin-top: rem-calc(4px);
    margin-bottom: rem-calc(8px);

    .shopping-itemrow__item {
        vertical-align: top;

        &:first-child {
            @if ($little-fooby) {
                width: 70%;
                margin-right: rem-calc(15px);
            }
            @else {
                width: 80%;
            }
        }
    }

    .btn--small {
        height: rem-calc(40px);
        min-width: initial;
        padding: 0;
        width: 100%;
    }
}

.shoppinglist__share-link {
    display: inline-flex;
    align-items: center;
    font-size: rem-calc(11px);
    line-height: (14 / 11);
    letter-spacing: 1px;
    color: #717171;
    font-family: $font-family-montserrat;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: rem-calc(22px);
    transition: color $animation-time-default $animation-easing-default;

    &:hover,
    &:focus {
        color: $theme-color;

        .animated {
            fill: $theme-color;

            .little-fooby-l2 & {
                color: $theme-color-l2;
            }

            .little-fooby-l3 & {
                color: $theme-color-l3;
            }
        }
    }
}

.shoppinglist__share-link-icon {
    display: flex;
    margin-right: rem-calc(10px);
}

.shoppinglist__error {
    font-size: rem-calc(11px);
    display: block;
    color: $color-danger;
}
/* scss-lint:enable QualifyingElement */
