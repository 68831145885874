/* COMPONENT Svgs
##################################################################################################################### */

svg .animated,
svg .fill {
    transition: fill $animation-time-default $animation-easing-default;
}

svg .animated-stroke {
    transition: stroke $animation-time-default $animation-easing-default;
}

svg .animated-all {
    transition: fill $animation-time-default $animation-easing-default,
        stroke $animation-time-default $animation-easing-default;
}

.svg-group {
    display: inline-flex;

    &--stars {
        svg:not(:last-child) {
            margin-right: rem-calc(3px);
        }

        .filled .animated {
            fill: $color-lf-cyan;
        }

        .animated-stroke {
            stroke: $color-lf-cyan;
        }

        .little-fooby-l2 & {
            .filled .animated {
                fill: $color-lf-green;
            }

            .animated-stroke {
                stroke: $color-lf-green;
            }
        }

        .little-fooby-l3 & {
            .filled .animated {
                fill: $color-lf-magenta;
            }

            .animated-stroke {
                stroke: $color-lf-magenta;
            }
        }

        &.little-fooby-l1,
        .little-fooby-l1.teaser & {
            .filled .animated {
                fill: $color-lf-cyan;
            }

            .animated-stroke {
                stroke: $color-lf-cyan;
            }
        }

        &.little-fooby-l2,
        .little-fooby-l2.teaser & {
            .filled .animated {
                fill: $color-lf-green;
            }

            .animated-stroke {
                stroke: $color-lf-green;
            }
        }

        &.little-fooby-l3,
        .little-fooby-l3.teaser & {
            .filled .animated {
                fill: $color-lf-magenta;
            }

            .animated-stroke {
                stroke: $color-lf-magenta;
            }
        }
    }
}

.svg-wrapper {
    display: inline-flex;
    position: relative;
    width: rem-calc(20px);
    height: rem-calc(20px);

    &__inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
