.error-page {
    height: 100%;
    width: 100%;
    position: relative;
}

.error-page__logo {
    margin: 0 auto;
    width: rem-calc(120px);
    position: relative;
    z-index: 5;

    @media #{$medium-up} {
        width: rem-calc(162px);
    }

    @media #{$small-only} and (orientation: landscape) {
        display: none;
    }
}

@media #{$xlarge-up} {
    body:not(.touch) .error-page .header-logo {
        transform: inherit;
    }
}

.error-page__copy {
    color: $color-dark-grey;
    padding: 0 rem-calc(30px);
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    position: relative;
    z-index: 5;

    @media #{$medium-up} {
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: rem-calc(860px);
    }
}

.error-page__copy-headline {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: rem-calc(50px);
    line-height: 1;
    letter-spacing: rem-calc(-3px);
    text-transform: lowercase;
    text-align: center;

    @media #{$medium-up} {
        font-size: rem-calc(100px);
    }
}

.error-page__copy-text {
    font-family: $font-family-roboto;
    font-size: rem-calc(12px);
    line-height: (18 / 12);

    &-link {
        color: inherit;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
            text-decoration: none;
        }
    }

    @media #{$medium-up} {
        font-size: rem-calc(18px);
        line-height: (24 / 18);
    }
}
