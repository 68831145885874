/* COMPONENT Teaser
##################################################################################################################### */

.teaser {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:hover {
        .teaser__inner {
            border-left-color: darken($color-dark-offwhite, 20%);
            border-right-color: darken($color-dark-offwhite, 20%);
            border-bottom-color: darken($color-dark-offwhite, 20%);
        }

        .teaser__img-container {
            border-left-color: darken($color-dark-offwhite, 20%);
            border-right-color: darken($color-dark-offwhite, 20%);
            border-top-color: darken($color-dark-offwhite, 20%);
        }

        .teaser__footer::before {
            background-color: darken($color-dark-offwhite, 20%);
        }
    }

    &.teaser--theme-dark:hover {
        .teaser__inner {
            border-left-color: lighten($color-dark-grey, 40%);
            border-right-color: lighten($color-dark-grey, 40%);
            border-bottom-color: lighten($color-dark-grey, 40%);
        }

        .teaser__footer::before {
            background-color: $color-dark-grey;
        }
    }

    // Serachresults from Solr may contain <em>, this is to counterfeit the italic styling of the em
    em {
        font-style: inherit;
        color: $color-fooby-orange;
    }
}

.teaser__click-area {
    color: inherit;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: inherit;

        .teaser__img {
            transform: translateZ(0) $image-zoom-scale;
        }
    }
}

.teaser__img-container {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-left: 1px solid $color-dark-offwhite;
    border-right: 1px solid $color-dark-offwhite;
    border-top: 1px solid $color-dark-offwhite;
    transition: border $animation-time-default $animation-easing-default;

    .teaser--little-fooby & {
        border: none;
        border-top-left-radius: rem-calc(6px);
        border-top-right-radius: rem-calc(6px);
    }

    img {
        width: 100%;
    }
}

.teaser__inner {
    @extend %teaser-inner;
    flex-grow: 1;
    border-left: 1px solid $color-dark-offwhite;
    border-right: 1px solid $color-dark-offwhite;
    border-bottom: 1px solid $color-dark-offwhite;
    transition: border $animation-time-default $animation-easing-default;
    background-color: $color-white;

    .teaser--little-fooby & {
        border-bottom-left-radius: rem-calc(6px);
        border-bottom-right-radius: rem-calc(6px);
    }

    @media #{$medium-up} {
        min-height: rem-calc(218px);
    }
}

.teaser__video-btn {
    display: block;
    position: absolute;
    top: rem-calc(-15px);
    right: rem-calc(15px);
    margin-top: -46px; // height of btn itself
    z-index: 3;

    & ~ .teaser__lf-logo {
        display: none;
    }

    .teaser--little-fooby & {
        margin-top: rem-calc(-14px);
        right: rem-calc(20px);
    }
}

.teaser__category {
    position: absolute;
    top: 0;
    right: rem-calc(15px);
    transform: translateY(-50%);
    z-index: 3;
}

.teaser__author-img,
.teaser__lf-logo {
    position: absolute;
    top: 0;
    right: rem-calc(20px);
    width: rem-calc(66px);
    height: rem-calc(66px);
    border-radius: 100%;
    border: 4px solid $color-white;
    overflow: hidden;
    transform: translateY(-50%);
    background-color: $color-light-grey;
    z-index: 2;

    img {
        width: 100%;
    }

    + .teaser__header {
        width: calc(100% - 75px);
    }

    @media #{$large-up} {
        width: rem-calc(76px);
        height: rem-calc(76px);
    }
}

.teaser__lf-logo {
    border: none;
    background-color: transparent;
}

.teaser__header {
    @extend %teaser-header;
}

.teaser__type {
    @extend %teaser-type;
}

.teaser__type-level {
    display: flex;
    margin-top: rem-calc(4px);
    font-size: rem-calc(14px);

    .svg-group {
        margin-right: rem-calc(8px);
    }

    .little-fooby-l1.teaser & {
        color: $headings-teaser-color-l1;
    }

    .little-fooby-l2.teaser & {
        color: $headings-teaser-color-l2;
    }

    .little-fooby-l3.teaser & {
        color: $headings-teaser-color-l3;
    }
}

.teaser__author {
    display: inline-block;
    font-size: rem-calc(11px);

    @media #{$medium-up} {
        font-size: rem-calc(12px);
    }
}

.teaser__body {
    position: relative;
    padding-bottom: rem-calc(40px);
}

.teaser__body-title {
    @extend %teaser-body-title;
}

.teaser--initial-columns-3 {
    .teaser__body-title {
        @extend %teaser-body-title--big;
    }
}

.teaser__body-text {
    @extend %teaser-body-text;
}

.teaser__meta-block {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: rem-calc(50px);
    padding: 0 rem-calc(15px);
    z-index: 4;

    .meta-info:not(:last-child) {
        margin-bottom: rem-calc(8px);
    }

    @media #{$large-up} {
        padding: 0 rem-calc(20px);
    }
}

.teaser__meta-block-inner {
    display: table-cell;
    vertical-align: bottom;
}

.teaser__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem-calc(37px);
    padding: 0 rem-calc(15px);
    z-index: 4;

    &::before {
        content: " ";
        display: block;
        height: 1px;
        width: 100%;
        background-color: $color-dark-offwhite;
        transition: background-color $animation-time-fast $animation-easing-default;
    }

    @media #{$large-up} {
        padding: 0 rem-calc(20px);
    }
}

a > .teaser__footer {
    position: static;
}

.teaser--has-meta-block {
    .teaser__body {
        padding-bottom: rem-calc(122px);

        @media #{$large-up} {
            padding-bottom: rem-calc(132px);
        }

        @media #{$xlarge-up} {
            padding-bottom: rem-calc(122px);
        }
    }
}

.teaser__img {
    @extend %fancyload-image;

    /* displayed when teaser image fails to load */
    &[data-lazy].error::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: 40%;
        background: $color-light-grey url(../assets/images/recipeimages/missing-image.svg) no-repeat center center;
    }
}

.teaser__img--loaded {
    @extend %fancyload-image--loaded;
}

.teaser__img-placeholder {
    position: relative;
    width: 100%;
    background-color: $color-light-grey;
    z-index: 1;
}

/* missing picture for external recipe in MyFooby area */
.teaser__img--missing {
    background-size: 40%;
    background: url(../assets/images/recipeimages/missing-image.svg) no-repeat center center;
    opacity: 1;
}

.teaser--partner-special {
    .teaser__img {
        width: auto;
        max-height: 100%;
        max-width: 100%;
        padding: rem-calc(10px);
        top: 50%;
        left: 50%;
        right: inherit;
        transform: translate(-50%, -50%);
    }

    .teaser__img-placeholder {
        background-color: $color-white;
    }

    .teaser__click-area {
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            .teaser__img {
                transform: translate(-50%, -50%) $image-zoom-scale;
            }
        }
    }
}

// ratios mobile
.teaser--initial-columns-3,
.teaser--initial-columns-4,
.teaser--initial-columns-6,
.teaser--initial-columns-8,
.teaser--initial-columns-12 {
    .teaser__img-placeholder {
        padding-top: calc-ratio(430, 285);
    }
}

@media #{$medium-up} {
    .teaser--initial-columns-3 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(411, 225);
        }

        &.teaser--columns-sm-4 {
            .teaser__img-placeholder {
                padding-top: calc-ratio(262, 225);
            }
        }
    }

    .teaser--initial-columns-4 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(262, 225);
        }
    }

    .teaser--initial-columns-6 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(411, 225);
        }
    }

    .teaser--initial-columns-8 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(562, 225);
        }
    }

    .teaser--initial-columns-12 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(862, 225);
        }
    }
}

@media #{$large-up} {
    .teaser--initial-columns-3 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(262, 300);
        }

        &.teaser--columns-sm-4 {
            .teaser__img-placeholder {
                padding-top: calc-ratio(262, 300);
            }
        }
    }

    .teaser--initial-columns-4 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(358, 300);
        }

        // same initial columns 3
        &.teaser--myfooby {
            .teaser__img-placeholder {
                padding-top: calc-ratio(262, 300);
            }
        }
    }

    .teaser--initial-columns-6 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(555, 300);
        }
    }

    .teaser--initial-columns-8 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(748, 300);
        }
    }

    .teaser--initial-columns-12 {
        .teaser__img-placeholder {
            padding-top: calc-ratio(1138, 300);
        }
    }
}

// meta-info component
.meta-info {
    position: relative;
    padding-left: rem-calc(20px);
    font-size: rem-calc(11px);
    line-height: rem-calc(15px);

    svg {
        position: absolute;
        left: 0;
        top: rem-calc(-1px);
        width: rem-calc(16px);
        height: rem-calc(16px);
    }

    // allways has fontsize 12px
    &--big {
        font-size: rem-calc(12px);
        line-height: rem-calc(15px);

        svg {
            width: rem-calc(17px);
            height: rem-calc(17px);
        }
    }

    &--visible-breaks {
        .meta-info__break {
            display: inline;
        }

        .meta-info__non-break {
            display: none;
        }
    }

    &:not(.meta-info--visible-breaks) {
        .meta-info__break {
            display: none;
        }

        .meta-info__non-break {
            display: inline;
        }
    }

    @media #{$large-up} {
        font-size: rem-calc(12px);

        svg {
            top: rem-calc(-1px);
            width: rem-calc(17px);
            height: rem-calc(17px);
        }
    }
}

.teaser--initial-columns-8,
.teaser--initial-columns-12 {
    .teaser__body {
        @extend %teaser-body;
    }
}

.teaser--theme-dark {
    .teaser__img-placeholder {
        background-color: transparent;
    }

    .teaser__author,
    .teaser__type,
    .teaser__body-title,
    .teaser__body-text {
        color: $color-dark-grey;
    }

    .teaser__footer::before {
        background-color: $color-dark-grey;
    }

    .share-group__single-link:not(.like--active):not(.bookmark--active) {
        .share-group__single-share-copy {
            color: $color-dark-grey;
        }

        .animated-stroke {
            stroke: $color-dark-grey;
        }

        .animated {
            fill: $color-dark-grey;
        }
    }
}
