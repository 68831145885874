/* COMPONENT Swiper
##################################################################################################################### */
/* scss-lint:disable NestingDepth */
// No BEM standard possible due to beeing overwrites of a plugin
[data-feed-automated],
.external-recipe__img-slider-wrapper {
    .swiper-container {
        margin-left: $container-padding-small * -1;
        margin-right: $container-padding-small * -1;
        padding-left: $container-padding-small;
        padding-right: $container-padding-small;
        position: relative;
        overflow: visible;

        transform: translate3d(0,0,0);
        // 20ms is roughly over one frame delay
        transition: all $animation-time-default $animation-easing-default 20ms;

        &.swiper-container--centered {
            transform: translate3d(20px,0,0);
            transition-delay: 0ms;

            @media #{$medium-up} {
                transform: translate3d(18px,0,0);
            }
        }

        @media #{$medium-up} {
            margin-left: $container-padding-medium * -1;
            margin-right: $container-padding-medium * -1;
            padding-left: $container-padding-medium;
            padding-right: $container-padding-medium;
        }

        .swiper-slide {
            padding-left: 0;
            padding-right: 0;
            //transition: opacity $animation-time-default $animation-easing-default;
            display: flex;
            width: calc(100% - 40px);
            position: relative;
            z-index: 1;

            @media #{$medium-up} {
                width: calc(33.3333333% - 32px);
            }
        }
    }

    .swiper-wrapper {
        margin-right: 0;
        margin-left: 0;
        position: relative;
    }

    .swiper-button-inner {
        border-radius: 100%;
        width: rem-calc(36px);
        height: rem-calc(36px);
        background-color: rgba(0, 0, 0, 0.3);

        .svg-stroke {
            stroke: $color-white;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: $color-white;
        cursor: pointer;
        height: calc(100% - 12px);
        position: absolute;
        top: 0;
        //width: 44px;
        z-index: 10;
        margin: 0;
        background-image: none;

        .swiper-button-inner,
        svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        @media #{$medium-up} {
            height: calc(100% - 30px);
            //width: 48px;
        }

        @media #{$large-up} {
            display: none;
        }
    }

    .swiper-button-next {
        right: 15px;
        width: 30px;
        padding-right: 5px;
        display: block;
        // 20ms is roughly over one frame delay
        transition: right $animation-time-default $animation-easing-default 32ms,
                    width $animation-time-default $animation-easing-default 32ms,
                    opacity $animation-time-default $animation-easing-default;

        @media #{$medium-up} {
            width: 31px;
            right: 17px;
        }

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-button-prev {
        left: -20px;
        width: 25px;
        transition: width $animation-time-default $animation-easing-default;

        @media #{$medium-up} {
            left: -18px;
            width: 30px;
        }

        &.swiper-button-disabled {
            width: 0;
            overflow: hidden;
            opacity: 1; // overwrite

            + .swiper-button-next {
                right: -5px;
                width: 50px;
                transition-delay: 0ms;

                @media #{$medium-up} {
                    right: -5px;
                }
            }
        }
    }

    // overwrites
    @media #{$large-up} {
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
        }
    }
}

[data-feed-automated] {
    [data-feed-wrapper]:not(.swiper-container) {
        .swiper-button-next,
        .swiper-button-prev {
            display: none !important;
        }
    }
}

[data-swiper-gallery] {

    .swiper-container {
        margin-left: $container-padding-small * -1;
        margin-right: $container-padding-small * -1;
        padding-left: $container-padding-small;
        padding-right: $container-padding-small;
        position: relative;
        overflow: visible;

        transform: translate3d(0,0,0);
        // 20ms is roughly over one frame delay
        transition: all $animation-time-default $animation-easing-default 20ms;
        padding-right: 42px;

        &.swiper-container--centered {
            transform: translate3d(26px, 0, 0);
            transition-delay: 0ms;
        }

        .swiper-slide {
            padding-left: 0;
            padding-right: 0;
            display: flex;
            position: relative;
            z-index: 1;
            width: auto;
            max-width: 90%;
            overflow: hidden;
            color: inherit;
            padding-bottom: rem-calc(30px);

            &.has-caption {
                padding-bottom: rem-calc(104px);
            }

            &:first-child {
                .image__caption {
                    padding-left: rem-calc(15px);
                }
            }

            &:last-child {
                .image__caption {
                    padding-right: rem-calc(15px);
                }
            }
        }
    }

    .swiper-wrapper {
        margin-right: 0;
        margin-left: 0;
        position: relative;
    }

    .swiper-button-inner {
        border-radius: 100%;
        width: rem-calc(36px);
        height: rem-calc(36px);
        background-color: rgba(0, 0, 0, 0.3);

        .svg-stroke {
            stroke: $color-white;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: $color-white;
        cursor: pointer;
        height: calc(100% - 12px);
        position: absolute;
        top: 0;
        z-index: 10;
        margin: 0;
        background-image: none;
        height: 210px;

        .swiper-button-inner,
        svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        @media #{$medium-up} {
            height: 420px;
        }
    }

    .swiper-button-next {
        right: 41px;
        width: 30px;
        padding-right: 5px;
        display: block;
        // 20ms is roughly over one frame delay
        transition: right $animation-time-default $animation-easing-default 32ms,
                    width $animation-time-default $animation-easing-default 32ms,
                    opacity $animation-time-default $animation-easing-default;

        &.swiper-button-disabled {
            opacity: 0;
        }
    }

    .swiper-button-prev {
        left: -10px;
        width: 25px;
        transition: width $animation-time-default $animation-easing-default;

        &.swiper-button-disabled {
            width: 0;
            overflow: hidden;
            opacity: 1; // overwrite

            + .swiper-button-next {
                right: 16px;
                width: 50px;
                transition-delay: 0ms;
            }
        }
    }
}


/* scss-lint:enable NestingDepth */
