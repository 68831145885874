/* COMPONENT Share Group
##################################################################################################################### */

.eventdetail-group {
    @extend %inline-list;
    text-align: center;
    line-height: 1;
    padding-bottom: rem-calc(26px);

    /* has to be here because of extend */
    .eventdetail-group__single {
        display: block;
        float: none;
        line-height: 1;
        font-size: rem-calc(12px);
        margin-bottom: rem-calc(4px);

        @media #{$medium-up} {
            margin-bottom: rem-calc(6px);
        }
    }
}

.eventdetail-group__single-link {
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.eventdetail-group__single-share-icon {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;

    svg {
        width: 17px;
        height: 17px;
    }
}

.eventdetail-group__single-share-copy {
    text-align: center;
    font-weight: 300;
    font-family: $font-family-open-sans;
    color: $color-dark-grey;
    transition: color $animation-time-default $animation-easing-default;
    display: inline;
}

.eventdetail-group__single-link:active,
.eventdetail-group__single-link:hover,
.eventdetail-group__single-link:focus {
    .eventdetail-group__single-share-copy {
        color: $color-green;
    }

    svg .animated {
        fill: $color-green;
    }

    svg .animated-stroke {
        stroke: $color-green;
    }
}

// Modifier for eventdetail-group in Teaser
.eventdetail-group--in-teaser {
    line-height: 1;
    text-align: right;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    .eventdetail-group__single-link {
        padding: rem-calc(5px);
        position: relative;
        right: rem-calc(-5px);
    }

    .eventdetail-group__single {
        line-height: 1;

        &:not(:last-child) {
            margin-right: rem-calc(19px);
        }

        @media #{$medium-up} {
            &:not(:last-child) {
                margin-right: rem-calc(11px);
            }
        }
    }

    .eventdetail-group__eventdetail-group,
    .eventdetail-group__single-share-copy {
        display: inline-block;
    }

    .eventdetail-group__single-share-copy {
        vertical-align: middle;

        @media #{$medium-up} {
            font-size: rem-calc(10px);
        }
    }

    .eventdetail-group__eventdetail-group {
        vertical-align: middle;

        @media #{$large-up} {
            top: 0;
            margin-right: 0;
        }
    }
}

//.eventdetail-group--in-teaser {
//}
