.meat {
    &__wrapper {
        @media #{$medium-up} {
            overflow: hidden;
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-template-areas: "image title"
                                 "image text";
        }
    }

    &__image-wrapper {
        position: relative;
        height: 0;
        padding-top: calc-ratio(384, 217);
        margin-top: rem-calc(24px);
        background-color: rgba($color-light-grey, 0.5);

        @media #{$medium-up} {
            grid-area: image;
            margin-top: 0;
            height: auto;
            padding-top: 0;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        padding: rem-calc(20px) rem-calc(30px);
        object-fit: contain;
        width: 100%;
        height: 100%;

        @media #{$medium-up} {
            padding: rem-calc(27px) rem-calc(42px);
        }
    }

    &__title,
    &__text {
        @media #{$medium-up} {
            margin-left: rem-calc(42px);
        }
    }

    &__title {
        font-family: $font-family-amatic;
        font-size: rem-calc(45px);
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: rem-calc(-4px);
        text-transform: uppercase;
        text-align: center;

        @media #{$medium-up} {
            grid-area: title;
            font-size: rem-calc(55px);
            text-align: left;
        }
    }

    &__text {
        line-height: 1.85;
        margin-top: rem-calc(20px);

        @media #{$medium-up} {
            grid-area: text;
        }
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $color-dark-offwhite;
        margin-top: rem-calc(28px);

        @media #{$medium-up} {
            flex-direction: row;
            margin-top: rem-calc(33px);
        }
    }

    &__suitables-wrapper {
        @media #{$medium-up} {
            border-right: 1px solid $color-dark-offwhite;
        }
    }

    &__suitables-wrapper,
    &__preparations-wrapper {
        flex-basis: 50%;
        padding-top: rem-calc(29px);
        padding-bottom: rem-calc(29px);

        @media #{$medium-up} {
            padding-top: rem-calc(26px);
            padding-bottom: rem-calc(40px);
        }
    }

    &__preparations-wrapper {
        @media #{$medium-up} {
            padding-left: rem-calc(47px);
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin-top: rem-calc(35px);
    }

    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        line-height: 1.42857;
    }

    &__item {
        flex-basis: 50%;
        display: flex;
        align-items: center;
        margin-top: rem-calc(42px);
    }

    &__item-icon {
        display: inline-flex;
        position: relative;
        flex-shrink: 0;
        height: 1em;
        width: rem-calc(34px);
        margin-right: rem-calc(16px);

        &--check {
            width: rem-calc(10px);
        }

        svg {
            position: absolute;
            transform: translate3d(-50%, -50%, 0);
            left: 50%;
            top: 50%;
        }
    }

    &__item-text {
        display: inline-flex;
    }
}
