/* TRUMPS Overwrites
##################################################################################################################### */

.animate--fade-in {
    transition: opacity $animation-time-slow $animation-easing-default;
    opacity: 1;
}

.animate--fade-out {
    transition: opacity $animation-time-slow $animation-easing-default;
    opacity: 0;
}

.trump--block {
    display: block;
}

.no-scroll {
    overflow: hidden;
}

.trump--hidden {
    display: none;
}
