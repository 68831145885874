/* COMPONENT C35.2 Image
##################################################################################################################### */

.image__wrapper {
    overflow: hidden;
    position: relative;
}

.image__placeholder {
    @extend %fancyload-image-placeholder;
}

.image__image {
    @extend %fancyload-image;
}

.image__foreground {
    pointer-events: none;
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    z-index: 2;

    &--mobile {
        @media #{$medium-up} {
            display: none;
        }
    }

    &--medium {
        display: none;

        @media #{$medium-up} {
            display: blocK;
        }
    }
}

@include ratio-config("image", ".image");

.image__caption {
    @extend %figcaption;
}

.image--keep-aspect-ratio {
    .image__wrapper {
        overflow: visible;
    }

    .image__placeholder {
        position: absolute;
        height: 100%;
    }

    .image__image {
        position: relative;
        width: 100%;
    }
}

.image--image-gallery {
    position: relative;
    max-width: 100%;

    .image__image {
        position: relative;
        width: auto;
    }

    .image__caption {
        position: absolute;
        top: 100%;
    }
}

.image--caption-normal {
    .image__caption {
        padding-top: rem-calc(25px);

        @media #{$small-only} {
            padding-bottom: rem-calc(20px);
        }

        p {
            margin-bottom: 0;
        }

        &, p, ul {
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);
        }

        ul li {
            &:not(:last-child) {
                margin-bottom: rem-calc(5px);
            }
        }
    }
}
