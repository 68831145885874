/* COMPONENT external-recipe
##################################################################################################################### */

$temp: rem-calc($container-padding-small);
$temp2: rem-calc($container-padding-small * 2);
$temp3: rem-calc($container-padding-small * 1.25);

.mini-modal--external-recipe {
    .mini-modal__content {
        // top: 0;
        // left: 0;
        // bottom: 0;
        // margin: 0;
        // padding: 0;
        // width: 100%;
        // transform: none;

        @media #{$medium-up} {
            // top: 50%;
            // left: 50%;
            // bottom: auto;
            padding: rem-calc($container-padding-medium * 1.25) rem-calc($container-padding-medium);
            width: 586px;
            // transform: translate(-50%, -50%);
        }

        //@media #{$large-up} {
        //    width: 506px;
        //    padding: rem-calc($container-padding-large * 1.25) rem-calc($container-padding-large);
        //}
    }

    .mini-modal__flex-flex--scrollable {
        &::before {
            background-image: linear-gradient($color-lighter-grey, rgba($color-lighter-grey, 0));
        }

        &::after {
            background-image: linear-gradient(rgba($color-lighter-grey, 0), $color-lighter-grey);
        }
    }

    .mini-modal__inner,
    .mini-modal__close {
        background-color: $color-lighter-grey;
    }

    .mini-modal__inner {
        height: 100%;

        @media #{$medium-up} {
            height: auto;
        }
    }

    .theme--dark {
        .mini-modal__inner,
        .mini-modal__close {
            background-color: $color-dark-stone-grey;
            color: $color-white;

            .svg-stroke {
                stroke: $color-white;
            }
        }

        .heading--h3 {
            color: inherit;
        }
    }
}

.external-recipe {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: $color-dark-grey;

    .external-recipe__label {
        display: inline-block;
        font-size: rem-calc(12px);
        font-weight: bold;
        margin-bottom: rem-calc(5px);
        cursor: pointer;
    }

    .external-recipe__input {
        @extend %form-element-input;
    }

    // .cookbook-add,
    .icon-link--small {
        margin-bottom: rem-calc(20px);
    }

    // .btn-group {
    //     padding-top: rem-calc(20px);
    // }
}

.external-recipe--create-mode {
    .visible-for-edit {
        display: none;
    }
}

.external-recipe--edit-mode {
    .visible-for-create {
        display: none;
    }
}

.external-recipe__url-container,
.external-recipe__title-container,
.external-recipe__cookbooks-container {
    position: relative;
    padding-bottom: rem-calc(6px);

    > small {
        display: none;
        position: absolute;
        bottom: rem-calc(-10px);
        max-width: 50%;
        font-size: rem-calc(10px);
        color: $color-error;
        text-align: right;
    }

    &.error {
        > small {
            display: block;
        }

        .external-recipe__label {
            color: $color-error;
        }

        input {
            border-color: $color-error;
        }
    }
}

.external-recipe__title-container {
    padding-bottom: rem-calc(24px);
}

.external-recipe__cookbooks-container {
    .external-recipe__label {
        margin-bottom: rem-calc(10px);
    }

    &.error {
        ~ .cookbook-select-error-message {
            display: block;
        }
    }
}

@keyframes loader-anim {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

.external-recipe__loader {

    svg {
        transform-origin: center center;
        fill: $color-dark-grey;
    }

    &.show {
        svg {
            animation: loader-anim 1s infinite linear;
        }
    }
}

.external-recipe__title-container,
.external-recipe__img-slider-wrapper {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity $animation-time-default $animation-easing-default;

    &.loading {
        height: auto;
    }

    &.show {
        height: auto;
        opacity: 1;
    }
}

.external-recipe__missing-image {
    display: none;
    background: url(../assets/images/recipeimages/missing-image.svg) no-repeat center 33px;
    height: rem-calc(141px);
    margin-bottom: rem-calc(20px);
    border: 1px solid rgba($color-stone-grey, 0.5);

    &.show {
        display: block;
    }

    &-text {
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 30px;
        color: rgba($color-dark-grey, 0.2);
        font-size: rem-calc(14px);
        font-weight: bold;
        letter-spacing: rem-calc(-0.35px);
        line-height: (19 / 14);
        text-align: center;
    }
}

// sass-lint:disable-all

// had to use important to solve style issue quickly. very very ugly, i know...
.external-recipe__img-slider-wrapper {
    margin-bottom: rem-calc(6px);
    display: none;

    &.show {
        display: block;
    }

    .swiper-container {
        padding: 0;
        margin-left: rem-calc(32px);
        margin-right: rem-calc(32px);
        height: 145px;
    }

    .swiper-slide {
        background-color: $color-lighter-grey;

        img {
            margin: 0 auto;
            height: 145px;
            width: auto;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        top: -1px;      // fix subpixel rendering issue
        bottom: -1px;   // fix subpixel rendering issue
        width: rem-calc(32px) !important;
        height: auto;
        background-color: $color-lighter-grey;

        svg {
            width: rem-calc(14px);
            height: rem-calc(14px);
        }
    }

    .swiper-button-prev {
        left: rem-calc(-32px) !important;

        &.swiper-button-disabled {
            width: 0 !important;
        }
    }

    .swiper-button-next,
    .swiper-button-disabled + .swiper-button-next {
        right: rem-calc(-32px) !important;
        width: rem-calc(32px) !important;
    }

    .swiper-pagination {
        bottom: -28px;
        font-size: rem-calc(12px);
    }

    @media only screen and (min-width: 500px) {
        .swiper-container {
            margin-left: rem-calc(114px) !important;
            margin-right: rem-calc(114px) !important;
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: rem-calc(114px) !important;
        }

        .swiper-button-prev {
            left: rem-calc(-114px) !important;

            > svg {
                left: 80%;
            }
        }

        .swiper-button-next {
            > svg {
                right: 80%;
                left: auto;
                transform: translate(50%, -50%);
            }
        }

        .swiper-button-next,
        .swiper-button-disabled + .swiper-button-next {
            right: rem-calc(-114px) !important;
            width: rem-calc(114px) !important;
        }
    }

    @media #{$large-up} {
        .swiper-button-prev,
        .swiper-button-next {
            display: block;
        }
    }
}
/* scss-lint:ensable all */

.external-recipe-group {
    position: relative;
    display: none;

    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 0;
        opacity: 0;
        overflow: hidden;
        transition: opacity $animation-time-default $animation-easing-default;

        .spinner {
            display: block;
            margin: 0 auto rem-calc(10px);
            width: 35px;
            height: 35px;
        }

        &.show {
            height: auto;
            overflow: visible;
            opacity: 1;
        }
    }

    &.show {
        display: block;
    }

    .loader__text {
        font-size: rem-calc(14px);
        font-weight: bold;
    }
}

.cookbook-select-error-message {
    display: none;
    font-size: rem-calc(12px);
    text-align: center;
    margin-bottom: rem-calc(20px);
    color: $color-error;
}
