/* COMPONENT Table
##################################################################################################################### */

.table__wrapper {
    margin-top: rem-calc(13px);
    border: 2px solid $color-light-grey;
    padding: rem-calc(34px) rem-calc(18px) rem-calc(38px);
    position: relative;

    @if ($little-fooby) {
        border-radius: rem-calc(6px);
    }

    .floating-title {
        left: 50%;
        position: absolute;
        top: -15px;
        transform: translateX(-50%);
    }

    .floating-title--small {
        top: 0;
    }

    @media #{$medium-up} {
        padding-right: rem-calc(28px);
        padding-left: rem-calc(28px);
        padding-bottom: rem-calc(31px);

        .floating-title {
            left: 28px; // 30px - border
            transform: none;
        }
    }

    @media #{$large-up} {
        padding-right: rem-calc(48px);
        padding-left: rem-calc(48px);
    }
}

.table {
    // empty rule
    border-collapse: collapse;
    width: 100%;
}

.table_tr {
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
        border-bottom: 0 none;
    }
}

.table_th,
.table_td {
    line-height: rem-calc(30px);
    font-size: rem-calc(14px);
    text-align: left;
    padding: rem-calc(4px) 0;
}

// Not BEM style, but markup must not be changed at this point
.t5-recipe {
    .table_th,
    .table_td {
        @extend %p;

        margin-bottom: 0;
        line-height: rem-calc(30px);
    }
}

.table_th {
    padding-right: rem-calc(20px);
}

.table__after-content {
    padding-top: rem-calc(27px);
}

@media #{$small-only} {
    .table--collapse-xs {
        .table_tr {
            display: block;
            padding: rem-calc(10px) 0;
        }

        .table_th,
        .table_td {
            display: block;
            padding: 0;
        }

        .table_td {
            line-height: rem-calc(20px);
        }
    }
}

@media #{$medium-only} {
    .table--collapse-sm {
        .table_tr {
            display: block;
            padding: rem-calc(10px) 0;
        }

        .table_th,
        .table_td {
            display: block;
            padding: 0;
        }

        .table_td {
            line-height: rem-calc(20px);
        }
    }
}

@media #{$large-only} {
    .table--collapse-md {
        .table_tr {
            display: block;
            padding: rem-calc(10px) 0;
        }

        .table_th,
        .table_td {
            display: block;
            padding: 0;
        }

        .table_td {
            line-height: rem-calc(20px);
        }
    }
}

@media #{$xlarge-up} {
    .table--collapse-lg {
        .table_tr {
            display: block;
            padding: rem-calc(10px) 0;
        }

        .table_th,
        .table_td {
            display: block;
            padding: 0;
        }

        .table_td {
            line-height: rem-calc(20px);
        }
    }
}
