/* COMPONENT Portion Calculator
##################################################################################################################### */

.portion-calculator__calculator-wrapper {
    position: relative;
    user-select: none;
}

.portion-calculator__calculator-hint {
    background: $color-dark-stone-grey;
    position: absolute;
    left: rem-calc(-10px);
    right: rem-calc(-10px);
    padding: rem-calc(16px) rem-calc(18px);
    top: rem-calc(60px);
    opacity: 0;
    display: none;
    z-index: 3;

    @media #{$medium-only} {
        top: rem-calc(78px);
    }

    p {
        color: $color-white;
        margin-bottom: rem-calc(5px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: $color-dark-stone-grey;
        border-width: 6px;
        margin-left: -6px;
    }
}

.portion-calculator__calculator-hint-heading {
    font-weight: bold;
}

.portion-calculator__calculator-hint-small {
    @extend %paragraph--subtext-picture;
}

.portion-calculator__calculator-hint-closer {
    display: block;
    float: right;
    position: relative;
    cursor: pointer;
    right: rem-calc(-2px);
    opacity: 1;
    transition: opacity $animation-time-default $animation-easing-default;

    .svg-stroke {
        stroke: $color-white;
    }

    &:hover {
        opacity: .6;
    }
}

.portion-calculator__calculator {
    width: rem-calc(190px);
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    @media #{$medium-up} {
        padding-top: rem-calc(25px);
        min-height: rem-calc(88px);
    }

    @media #{$xlarge-up} {
        padding-top: 0;
        min-height: rem-calc(51px);
        width: rem-calc(210px);
    }
}

/* scss-lint:disable NestingDepth */
.portion-calculator__operation-minus,
.portion-calculator__operation-plus {
    float: left;
    cursor: pointer;

    &,
    svg {
        width: rem-calc(40px);
        height: rem-calc(40px);

        .animated.global--inverted {
            stroke-width: 1.8px;
        }

        @media #{$xlarge-up} {
            width: rem-calc(50px);
            height: rem-calc(50px);
        }
    }

    @if ($little-fooby) {
        .bg__background,
        .bg__foreground,
        .bg__line {
            transition: all .2s ease-in;
        }

        &:hover {
            .bg__foreground {
                fill: $color-lf-cyan-dark;
            }

            .bg__line {
                fill: $color-white;
            }
        }

        .little-fooby-l2 & {
            .bg__foreground {
                fill: $color-lf-green-light;
            }
 
            .bg__background {
                fill: $color-lf-green-dark;
            }

            .bg__line {
                fill: $color-lf-green-dark2;
            }

            &:hover {
                .bg__foreground {
                    fill: $color-lf-green-dark;
                }

                .bg__line {
                    fill: $color-white;
                }
            }
        }

        .little-fooby-l3 & {
            .bg__foreground {
                fill: $color-lf-magenta-light;
            }
 
            .bg__background {
                fill: $color-lf-magenta-dark;
            }

            .bg__line {
                fill: $color-lf-magenta-dark;
            }

            &:hover {
                .bg__foreground {
                    fill: $color-lf-magenta-dark;
                }

                .bg__line {
                    fill: $color-white;
                }
            }
        }

        &.little-fooby-l1 {
            .bg__foreground {
                fill: $color-lf-cyan-light;
            }
 
            .bg__background {
                fill: $color-lf-cyan-dark;
            }

            .bg__line {
                fill: $color-lf-cyan-dark;
            }

            &:hover {
                .bg__foreground {
                    fill: $color-lf-cyan-dark;
                }

                .bg__line {
                    fill: $color-white;
                }
            }
        }

        &.little-fooby-l2 {
            .bg__foreground {
                fill: $color-lf-green-light;
            }
 
            .bg__background {
                fill: $color-lf-green-dark;
            }

            .bg__line {
                fill: $color-lf-green-dark2;
            }

            &:hover {
                .bg__foreground {
                    fill: $color-lf-green-dark;
                }

                .bg__line {
                    fill: $color-white;
                }
            }
        }

        &.little-fooby-l3 {
            .bg__foreground {
                fill: $color-lf-magenta-light;
            }
 
            .bg__background {
                fill: $color-lf-magenta-dark;
            }

            .bg__line {
                fill: $color-lf-magenta-dark;
            }

            &:hover {
                .bg__foreground {
                    fill: $color-lf-magenta-dark;
                }

                .bg__line {
                    fill: $color-white;
                }
            }
        }

    } @else {
        &:hover {
            .animated {
                fill: $color-white;

                &.global--inverted {
                    fill: $color-green;
                }
            }
        }
    }
}
/* scss-lint:enable NestingDepth */

.portion-calculator__display {
    float: left;
    width: rem-calc(110px);
}

.portion-calculator__display-label,
.portion-calculator__display-portions {
    display: block;
    text-align: center;
}

.portion-calculator__display-portions {
    font-size: rem-calc(24px);
    font-weight: bold;
    line-height: 1;
    color: $color-dark-stone-grey;

    @media #{$large-up} {
        font-size: rem-calc(28px);
    }
}

.portion-calculator__display-label {
    font-size: rem-calc(12px);
    line-height: rem-calc(17px);
    color: $color-stone-grey;
    text-transform: uppercase;

    @media #{$large-up} {
        font-size: rem-calc(14px);
        line-height: rem-calc(19px);
    }
}
