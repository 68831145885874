/* COMPONENT Rezept Zutatenliste
##################################################################################################################### */

.recipe-ingredientlist__step-wrapper {
    padding-top: rem-calc(3px);
}

.recipe-ingredientlist__ingredient-wrapper {
    //border-bottom: 1px solid $color-light-grey;
    padding-bottom: rem-calc(10px);
    //margin-bottom: rem-calc(10px);
    overflow: hidden;
}

.recipe-ingredientlist__ingredient-quantity,
.recipe-ingredientlist__ingredient-desc,
.recipe-ingredientlist__ingredient-check-icon {
    @extend %p;
    @extend %paragraph--subtext-picture;
    //line-height: rem-calc(18px);
    margin: 0;
    display: block;
    float: left;
}

.recipe-ingredientlist__ingredient-quantity {
    margin-right: rem-calc(30px);
    font-weight: bold;
    width: rem-calc(70px);
    min-height: 1px;

    @media #{$medium-only} {
        margin-right: rem-calc(5px);
        font-weight: bold;
        width: rem-calc(60px);
    }
}

.recipe-ingredientlist__ingredient-desc {
    $temp: rem-calc(101px);
    width: calc(100% - #{$temp});
    padding-right: rem-calc(20px);

    @media #{$medium-only} {
        $temp: rem-calc(66px);
        width: calc(100% - #{$temp});
        padding-right: 0;
    }
}

.recipe-ingredientlist-header {
    font-family: "Roboto Slab";
}

.recipe-ingredientlist-header--amount {
    &::before {
        content: attr(data-amount);
    }
}

.recipe-ingredientlist-header--label {
    &::before {
        content: attr(data-label-multi);
    }

    &.is-single::before {
        content: attr(data-label-single);
    }
}
