.utensils {
    &__list {
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        margin: rem-calc(-2px);
    }

    &__item {
    }

    &__label {
        display: block;
        position: relative;
        width: rem-calc(124px);
        height: rem-calc(175px);
        margin: rem-calc(2px);

        @media #{$medium-up} {
            width: rem-calc(130px);
        }
    }

    &__checkbox[type="checkbox"] {
        position: absolute;
        top: rem-calc(5px);
        right: rem-calc(5px);
        z-index: 1;
    }

    &__checkbox[type="checkbox"]:checked + &__wrapper {
        background-color: $color-white;
        border-color: $color-dark-offwhite;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $color-light-grey2;
        border: 1px solid $color-light-grey2;
        border-radius: rem-calc(5px);
        flex-shrink: 0;
        padding: rem-calc(10px) 0;
        transition: all $animation-time-default $animation-easing-default;
        user-select: none;
        cursor: pointer;

        @media #{$medium-up} {
            padding: rem-calc(10px) 0;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem-calc(90px);
    }

    &__text {
        display: block;
        margin-top: rem-calc(10px);
        padding: 0 rem-calc(12px);
        font-size: rem-calc(14px);
        line-height: 1.285;

        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: normal;
        word-break: normal;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

        @media #{$medium-up} {
            padding: 0 rem-calc(25px);
        }
    }
}