.notes-overlay {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    > [data-view-type] {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    > [data-view-type="not-logged-in"],
    > [data-view-type="warning"],
    > [data-view-type="success"],
    > [data-view-type="error"] {
        flex-grow: 0;
    }

    &__text {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__description {
        font-size: rem-calc(12px);
        margin-bottom: rem-calc(5px);
    }

    &__textarea {
        margin-bottom: rem-calc(20px);
        padding: rem-calc(10px) rem-calc(13px);
    }
}
