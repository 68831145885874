/* COMPONENT social-media
##################################################################################################################### */

.social-media__title {
    font-weight: 700;
    font-size: rem-calc(16px);
    line-height: rem-calc(50px);

    margin-bottom: rem-calc(6px);

    @media #{$medium-up} {
        display: inline-block;
        margin-right: 5%;
        font-size: rem-calc(18px);
        line-height: rem-calc(40px);
    }

    @media #{$xlarge-up} {
        margin-right: 0;
    }
}

.social-media-list {
    @extend %equidistant-list;

    @media #{$medium-up} {
        width: 45%;
    }

    @media #{$medium-only} {
        top: 7px;
    }

    @media #{$large-only} {
        top: 7px;
    }
}

.social-media-list__item {
    transition: opacity $animation-time-default $animation-easing-default;

    &:not(:last-child) {
        margin-right: rem-calc(15px);
    }

    svg {
        height: rem-calc(27px);
        width: rem-calc(27px);
    }

    &:hover {
        opacity: .6;
    }
}

.social-media--not-justified {
    .social-media-list {
        justify-content: flex-start;
    }

    .social-media-list__item {
        margin: 0 rem-calc(5px);
    }

    @media #{$medium-up} {
        .social-media-list__item {
            margin: 0 rem-calc(10px);
        }
    }

    @media #{$large-up} {
        .social-media-list {
            text-align: left;
        }

        .social-media-list__item {
            margin: 0 rem-calc(25px) 0 0;
        }
    }
}
