/* COMPONENT footer-nav
##################################################################################################################### */

.footer-nav {
    margin-top: 17px;

    @media #{$large-up} {
        display: flex;
    }

    > * {
        @media #{$large-up} {
            @if ($little-fooby == false) {
                width: auto;
            }
        }

        @media #{$xlarge-up} {
            flex-grow: 1;
            flex-basis: 0;
        }
    }
}

.footer-nav__title {
    @extend %footer-section-title;
}

.footer-nav__section {
    border-bottom: 1px solid $color-white;

    @media #{$medium-up} {
        border-bottom: 0 none;
    }
}

.footer-nav__link {
    display: block;
    padding: 0;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-white;
    font-weight: 700;
    font-size: rem-calc(16px);
    line-height: rem-calc(50px);

    > svg {
        display: none;
    }

    @media #{$medium-up} {
        font-size: rem-calc(18px);
        line-height: rem-calc(40px);
        white-space: nowrap;
    }

    &:hover,
    &--state-hover,
    &:active,
    &--state-active,
    &:focus {
        color: $color-white;
        text-decoration: none;
    }
}

.footer-nav__list {
    @extend %unstyled-list;
    margin-bottom: 20px;
    line-height: 1.357;

    @media #{$medium-up} {
        margin-bottom: 10px;
    }
}

.footer-nav__list-item {
    margin: rem-calc(5px) 0;
}

.footer-nav__list-item-link {
    color: $color-white;
    font-size: rem-calc(14px);
    transition: color $animation-time-default $animation-easing-default;

    .svg-group {
        margin-right: rem-calc(3px);
    }

    &:hover,
    &--state-hover,
    &:active,
    &--state-active,
    &:focus {
        color: $color-stone-grey;
        text-decoration: none;
    }
}
