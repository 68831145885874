.floating-title-wrapper {
    position: relative;
    border: 1px solid $color-dark-offwhite;
    padding: rem-calc(35px) rem-calc(25px) rem-calc(20px);

    @media #{$medium-up} {
        padding: rem-calc(35px);
    }

    .floating-content {
        *:last-child {
            margin-bottom: 0;
        }
    }

    .floating-title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        @media #{$medium-up} {
            left: auto;
            transform: translateY(-50%);
        }
    }
}
