.newsletter-selection {
    display: flex;
    position: relative;
    margin: rem-calc(-8px) 0;
    flex-direction: column;
    align-items: stretch;

    @media #{$medium-up} {
        margin: 0 rem-calc(-7px);
        flex-direction: row;
    }

    & > &__item {
        position: relative;
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        margin: rem-calc(8px) 0;
        font-weight: normal;

        @media #{$medium-up} {
            margin: 0 rem-calc(7px);
        }
    }

    &__checkbox[type="checkbox"] {
        position: absolute;
        right: rem-calc(11px);
        bottom: 50%;
        transform: translateY(50%);

        @media #{$medium-up} {
            transform: none;
            bottom: rem-calc(11px);
        }
    }

    &__checkbox[type="checkbox"]:checked + &__inner {
        border-color: $theme-color-hover;
    }

    &__inner {
        flex-grow: 1;
        display: flex;
        border: 1px solid $color-white;
        transition: border-color $animation-time-fast $animation-easing-default;
        background-color: $color-white;
        padding: rem-calc(8px);

        @media #{$medium-up} {
            flex-direction: column;
            padding-bottom: rem-calc(45px);
        }
    }

    &__text {
        flex-grow: 1;
        font-size: rem-calc(14px);
        line-height: 1.428;
        margin: rem-calc(10px) rem-calc(40px) rem-calc(10px) rem-calc(18px);

        @media #{$medium-up} {
            margin: rem-calc(12px) rem-calc(3px) 0;
        }
    }

    &__image-cell {
        width: rem-calc(150px);
        flex-shrink: 0;

        @media #{$medium-up} {
            width: auto;
        }
    }

    &__image-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (101 / 150) * 100%;

        @media #{$medium-up} {
            padding-bottom: (98 / 207) * 100%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.has-error &__inner {
        border-color: $color-error;
    }
}