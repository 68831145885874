%h1 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    font-size: rem-calc(40px);
    line-height: rem-calc(40px);
    color: $headings-color;
    margin: 0 0 rem-calc(20px);

    @if ($little-fooby) {
        letter-spacing: rem-calc(2.7px);
    } @else {
        letter-spacing: rem-calc(-1px);
        text-transform: uppercase;
    }

    @media #{$medium-up} {
        font-size: rem-calc(54px);
        line-height: rem-calc(54px);

        @if ($little-fooby) {
            letter-spacing: rem-calc(2px);
        } @else {
            letter-spacing: rem-calc(-1.5px);
        }
    }

    @media #{$large-up} {
        font-size: rem-calc(65px);
        line-height: rem-calc(60px);

        @if ($little-fooby == false) {
            letter-spacing: rem-calc(-2px);
        }
    }
}

%h2 {
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    color: $headings-color;
    margin: 0 0 rem-calc(35px);
    text-indent: rem-calc(-1px);

    @if ($little-fooby) {
        letter-spacing: rem-calc(2.7px);
        font-size: rem-calc(40px);
        line-height: rem-calc(40px);
    } @else {
        letter-spacing: rem-calc(-.5px);
        text-transform: uppercase;
        font-size: rem-calc(36px);
        line-height: rem-calc(36px);
    }

    &.h2--center {
        text-align: center;
    }

    &.h2--dark {
        color: black;
    }

    &.h2--small-bottom {
        color: black;
        margin: 0 0 rem-calc(18px);
    }

    @media #{$medium-up} {
        font-size: rem-calc(40px);
        line-height: rem-calc(40px);
    }

    @media #{$large-up} {
        font-size: rem-calc(45px);
        line-height: rem-calc(45px);
    }

    @media #{$xlarge-up} {
        font-size: rem-calc(55px);
        line-height: rem-calc(55px);
    }
}

%h3 {
    font-weight: 700;
    font-size: rem-calc(20px);
    line-height: 1.25;
    margin: 0 0 rem-calc(10px);
    color: $color-dark-grey;
    text-indent: 0;
    font-family: $font-family-open-sans;
    text-transform: inherit;
    letter-spacing: 0;
    //word-break: break-all;
    hyphens: auto;

    // prevents overflowing text, if one word simple is too long
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

%p {
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    margin: 0 0 rem-calc(24px);
}

%ul {
    line-height: rem-calc(43px);
}

// 5.2 Ordered List
%ol {
    line-height: rem-calc(43px);
}

%a {
    color: $theme-color;

    &:hover,
    &:active,
    &:focus {
        color: $theme-color-hover;
        text-decoration: none;
    }
}
