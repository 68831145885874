/* COMPONENT Wine Teaser
##################################################################################################################### */

$medium-to-midlarge: "only screen and (min-width: #{$screen-md}) and (max-width: 1142px)";
$slarge-to-midlarge: "only screen and (min-width: 1078px) and (max-width: 1152px)";
$medium-to-slarge: "only screen and (min-width: #{$screen-md}) and (max-width: 1068px)";
$midsmall-to-medium: "only screen and (min-width: 878px) and (max-width: #{$screen-md})";
$small-to-smedium: "only screen and (min-width: #{$screen-sm}) and (max-width: 884px)";

.wine-teaser {
    display: block;
    background-color: $color-white;
    width: 100%;
    border: 1px solid $color-dark-offwhite;

    opacity: 0;
    transform: translate3d(-30px, -10px, 0);
    transform-origin: center center;
    transition: opacity .5s $animation-easing-default,
                transform .5s cubic-bezier(.150, .865, .355, .990);
    will-change: opacity, transform;

    &.teaser--loaded {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.wine-teaser--blendout {
    opacity: 0;
    transform: scale(.95);
}

.wine-teaser__innerrow {
    width: 100%;
    display: table;
    table-layout: fixed;

    @media #{$medium-up} {
        table-layout: auto;
    }

    @media #{$small-only} {
        display: block;
    }
}

.wine-teaser__innercol {
    display: table-cell;
    vertical-align: middle;
    height: 100%;

    @media #{$large-up} {
        width: auto;
    }

    @media #{$small-only} {
        width: 100%;
        display: block;
    }

    &:first-child {
        width: 41.666%;

        @media #{$large-up} {
            width: 50%;
        }

        @media #{$small-only} {
            width: 100%;
        }
    }

}

.wine-teaser__img-container {
    position: relative;
    overflow: hidden;
    z-index: 1;

    img {
        height: 100%;
    }
}

.wine-teaser__inner {
    position: relative;
    padding: rem-calc(10px) rem-calc(15px) 0;
    z-index: 3;

    @media #{$medium-up} {
        min-height: rem-calc(218px);
    }

    @media #{$large-up} {
        padding: rem-calc(15px) rem-calc(20px) 0;
    }
}

.wine-teaser__header {
    margin-bottom: rem-calc(8px);
    line-height: 1;
}

.wine-teaser__claim {
    margin-right: rem-calc(2px);
    font-size: rem-calc(10px);
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    color: $headings-teaser-color;
    letter-spacing: .1em;

    @if ($little-fooby) {
        .little-fooby-l1.wine-teaser & {
            color: $headings-teaser-color;
        }

        .little-fooby-l2.wine-teaser & {
            color: $headings-teaser-color-l2;
        }

        .little-fooby-l3.wine-teaser & {
            color: $headings-teaser-color-l3;
        }
    }

    @media #{$medium-up} {
        font-size: rem-calc(12px);
    }
}

.wine-teaser__logo {
    padding-top: rem-calc(24px);
    padding-bottom: rem-calc(17px);

    @media #{$large-up} {
        padding-top: rem-calc(30px);
        padding-bottom: rem-calc(28px);
    }
}

.wine-teaser__centerwrap {
    padding: 0 rem-calc(30px);

    @media #{$large-up} {
        padding: 0 rem-calc(20px);
    }

    @media #{$small-only} {
        padding: 0 .9375rem;
    }
}

.wine-teaser__body {
    position: relative;
    padding-bottom: 1.875rem;
}

.wine-teaser__body-title {
    margin-bottom: rem-calc(12px);
    font-family: $font-family-roboto;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    color: $color-dark-grey;

    @media #{$large-up} {
        margin-bottom: rem-calc(20px);
    }
}


.wine-teaser__body-text {
    margin-bottom: rem-calc(19px);
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);

    @media #{$medium-up} {
        line-height: rem-calc(18px);
    }

    @media #{$large-up} {
        font-size: rem-calc(14px);
    }
}

.wine-teaser__body-totalprice {
    margin-bottom: rem-calc(4px);
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    font-weight: 600;
}

.wine-teaser__body-unitprice {
    margin-bottom: rem-calc(18px);
    font-size: rem-calc(12px);
    line-height: rem-calc(22px);

    @media #{$small-only} {
        margin-bottom: rem-calc(22px);
    }
}

.wine-teaser__body-link {
    text-transform: uppercase;
    color: $color-stone-grey;
    font-family: "Montserrat";
    font-size: rem-calc(11px);
    letter-spacing: rem-calc(1.5px);
    margin-top: rem-calc(-36px);
    text-align: right;
    transition: color $animation-time-default $animation-easing-default;

    @media #{$large-up} {
        margin-top: 0;
        text-align: left;
    }

    @media #{$small-only} {
        margin-top: 0;
        text-align: left;
    }

    > svg {
        transition: transform $animation-time-default $animation-easing-default;
        position: relative;
        top: rem-calc(1px);
        fill: $color-stone-grey;
    }
}

.wine-teaser__img {
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    opacity: 0;
    transition: opacity $animation-time-slow $animation-easing-default;
    will-change: opacity;
    z-index: 2;

    @media #{$small-only} {
        padding-top: 1em;
    }
}

// sass-lint:disable-all
.wine-teaser__click-area {
    color: inherit;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: inherit;

        .wine-teaser__body-link {
            color: $theme-color;

            > svg {
                fill: $theme-color;
                $temp: rem-calc(5px);
                transform: translateX(#{$temp});
            }
        }
    }
}
/* scss-lint:enable all */

.wine-teaser__img--loaded {
    opacity: 1;
}

.wine-teaser__img-placeholder {
    position: relative;
    width: 100%;
    z-index: 1;

    // image ratios
    //padding-top: calc-ratio(277, 531);

    @media #{$small-only} {
        padding-top: calc-ratio(600, 400);
    }

    @media #{$medium-up} {
        padding-top: calc-ratio(358, 407);
    }

    @media #{$large-up} {
        padding-top: calc-ratio(277, 531);
    }
}

/* Differing styles for narrow teasers */

/* overqualifying is necessary */
.teaser.wine-teaser--narrow {
    display: flex;
}

.wine-teaser--narrow {
    .wine-teaser__click-area {
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
    }

    .wine-teaser__img-container {
        margin-top: rem-calc(13px);
        margin-bottom: rem-calc(14px);

        @media #{$medium-only} {
            margin-top: rem-calc(6px);
            margin-bottom: rem-calc(4px);
        }

        @media #{$small-only} {
            margin-top: rem-calc(0px);
        }
    }

    .wine-teaser__logo {
        padding: 0;
        position: absolute;
        top: rem-calc(157px);
        left: rem-calc(20px);

        svg {
            width: rem-calc(70px);
        }

        @media #{$medium-only} {
            left: rem-calc(15px);
            top: rem-calc(97px);

            svg {
                width: rem-calc(60px);
            }
        }

        @media #{$small-only} {
            left: rem-calc(15px);
            top: rem-calc(138px);
        }
    }

    &.teaser--initial-columns-3 .wine-teaser__logo {
        @media #{$large-only} {
            svg {
                width: rem-calc(50px);
            }
        }
    }

    &.teaser--initial-columns-4 .wine-teaser__logo {
        @media #{$xlarge} {
            svg {
                width: rem-calc(90px);
            }
        }
    }

    .wine-teaser__img-placeholder {
        padding-top: rem-calc(200px);

        @media #{$medium-only} {
            padding-top: rem-calc(140px);
        }

        @media #{$small-only} {
            padding-top: rem-calc(180px);
        }
    }

    .wine-teaser__body {
        padding-bottom: 0;
    }

    .wine-teaser__body-title {
        @extend %teaser-body-title;
    }

    &.teaser--initial-columns-3 .wine-teaser__body-title {
        @extend %teaser-body-title--big;
    }

    .wine-teaser__body-text {
        height: rem-calc(110px);
        margin-bottom: rem-calc(13px);
        width: 100%;
        overflow: hidden;

        @media #{$medium-only} {
            font-size: rem-calc(12px);
        }

        @media #{$large-only} {
            height: rem-calc(75px);
        }
    }

    &.teaser--initial-columns-4 .wine-teaser__body-text {
        margin-bottom: rem-calc(4px);

        @media #{$small-only} {
            height: auto;
            margin-bottom: rem-calc(20px);
        }
    }

    &.teaser--initial-columns-3 .wine-teaser__body-text {
        @media #{$small-only} {
            height: auto;
            margin-bottom: rem-calc(20px);
        }
    }

    .wine-teaser__centerwrap {
        @media #{$medium-only} {
            padding: 0 rem-calc(15px);
        }
    }

    .wine-teaser__body-link {
        align-content: flex-end;
        padding: 0 rem-calc(20px) rem-calc(20px) rem-calc(20px);
        text-align: left;
        margin-top: auto;

        @media #{$medium-only} {
            padding: 0 rem-calc(15px) rem-calc(15px) rem-calc(15px);
        }

        @media #{$small-only} {
            position: relative;
            display: block;
            left: rem-calc(15px);
            bottom: rem-calc(15px);
            margin-top: rem-calc(15px);
            padding: 0;
        }
    }

}
