
.theme--bg-pale-orange {
    background-color: $color-pale-orange;
}

.theme--bg-pale-blue {
    background-color: $color-pale-blue;
}

.theme--bg-pale-green {
    background-color: $color-light-pale-green;
}

.theme--bg-pale-beige {
    background-color: $color-bg-pale-beige;
}
