/* MODULE 01 Header
##################################################################################################################### */

.header {
    border-bottom: 1px solid $color-white;
    position: fixed;
    background-color: $color-white;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    // setting z-indexes of child components
    .navigation-hamburger,
    .navigation-desktop,
    .header-logo,
    .navigation-meta,
    .header__touch-bottom-wrapper {
        z-index: 93;
    }

    .header-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        //transform: translate(-50%, -50%);
        //width: rem-calc(80px);
        transform-origin: center;
        transition: transform $animation-time-default $animation-easing-default;

        @if ($little-fooby) {
            transform: translate3d(-50%, -50%, 0);
        } @else {
            transform: translate3d(-50%, -50%, 0) scale(.494);
        }


        @media #{$medium-up} {
            //width: rem-calc(100px);
            transform: translate3d(-50%, -50%, 0) scale(.617);
        }
    }

    /* scss-lint:disable SelectorDepth NestingDepth */
    [data-login-link] {
        &.logged-in {
            color: $theme-color;

            svg * {
                fill: $theme-color;
            }
        }
    }
    /* scss-lint:enable */

    .no-header &,
    .foobyapp & {
        display: none;
    }
}

.header--closing {
    transition: top $animation-time-default $animation-easing-default $animation-time-default;
}

.header--open {
    transition: top $animation-time-default $animation-easing-default;
    top: 0 !important;
}

.header--slim,
.header--always-slim {
    border-bottom-color: $color-light-grey;
}

@media #{$medium-down} {
    .header--grey-border {
        border-bottom-color: $color-light-grey;
    }
}

// Adding a spacer for normal document flow, since header is position fixed
.header__spacer {
    height: rem-calc(50px);

    @media #{$medium-up} {
        height: rem-calc(70px);
    }

    .no-header &,
    .foobyapp & {
        display: none;
    }
}

@media #{$xlarge-up} {
    body:not(.touch) {
        .header__spacer {
            height: rem-calc(101px);
        }
    }
}

.header__inner {

    // header should always be 50px on mobile
    height: rem-calc(50px);
    position: relative;
    width: 100%;
    transition: height $animation-time-default $animation-easing-default;

    @media #{$medium-up} {

        // header should be 70px in height as default
        height: rem-calc(70px);

        // 'large' default height is set below
    }
}

.header__touch-nav-bg {
    z-index: 90;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: 0;
    background-color: $color-white;
}

.header__touch-bottom-wrapper {
    position: fixed;
    //height: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    overflow: hidden;
    opacity: 0;

    .header__touch-bottom-inner {
        position: relative;
        height: rem-calc(91px);

        @media #{$medium-up} {
            height: rem-calc(131px);
        }
    }
}

@media #{$medium-up} {

    .header--slim:not(.header--open) {
        .header__inner {
            height: rem-calc(50px);
            transition: height $animation-time-default $animation-easing-default;
        }

        .header-logo {
            @if ($little-fooby) {
                transform: translate3d(-50%, -50%, 0) scale(.5);
            } @else {
                // delay is needed, since the animation would brake on IOS devices if you animate width and transform at the same time
                //transition: transform $animation-time-default $animation-easing-default;
                transform: translate3d(-50%, -41%, 0) scale(.494);
            }
        }

        .header-logo__link::after {
            opacity: 1;
        }
    }

    .header--open {
        .header__inner {
            height: rem-calc(90px);
            transition: height $animation-time-default $animation-easing-default;
        }

        .header-logo {

            // delay is needed, since the animation would brake on IOS devices if you animate width and transform at the same time
            //transition: transform $animation-time-default $animation-easing-default;
            //transform: translate3d(-50%, -50%, 0);
            //width: rem-calc(120px);
            transform: translate3d(-50%, -50%, 0) scale(.743);
        }

        .header-logo__link::after {

            // overwriting if header is also --slim
            opacity: 0;
        }
    }
}

@media #{$xlarge-up} {
    body:not(.touch) {

        // always hide if not a touch device and >= 992px
        .header__touch-nav-bg {
            display: none;
        }

        .header__inner {

            // header should be 100px in height as default
            height: rem-calc(100px);
            transition-delay: 0ms;
        }

        .header-logo {

            // original logo size
            transition: all $animation-time-default $animation-easing-default;
            transition-delay: 0ms;
            transform: translate3d(-50%, -50%, 0);

            @if ($little-fooby) {
                width: rem-calc(109px);
            } @else {
                width: rem-calc(162px);
            }
        }

        .header--slim {
            .header__inner {
                height: rem-calc(60px);
                transition-delay: 0ms;
            }

            .navigation-desktop__l1-link {
                line-height: rem-calc(60px);
            }

            .header-logo {
                //width: rem-calc(95px);
                transition: all $animation-time-default ease-in-out;
                transition-delay: 0ms;

                @if ($little-fooby) {
                    transform: translate3d(-50%, -50%, 0) scale(.6);
                } @else {
                    transform: translate3d(-50%, -41%, 0) scale(.59);
                }
            }
        }
    }
}
