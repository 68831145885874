/* COMPONENT Service-Card
##################################################################################################################### */

.service-card {
    //display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

.service-card__image {
    width: 100%;
    //flex: 0 1 auto;
}

.service-card__img {
    width: 100%;
}

.service-card__content {
    //flex: 0 1 auto;
    padding: 0 1.25em 1.25em;
    @media #{$medium-up} {
        padding: 0;
    }
}

.service-card__title {
    font-size: rem-calc(20px);
    font-weight: 700;
    line-height: 1.25;
    font-family: $font-family-open-sans;
    padding-top: rem-calc(15px);
    padding-bottom: rem-calc(4px);
    display: block;
}

.service-card__text {
    line-height: 1.325;
    padding-bottom: 0;
    margin-bottom: 1.5rem;
    font-size: rem-calc(14px);

    @media #{$large-up} {
        font-size: rem-calc(16px);
        line-height: 1.125;
    }

    &.mobile-center {
        text-align: center;
        @media #{$medium-up} {
            text-align: left;
        }
    }
}

.service-card__btn {
    text-transform: uppercase;
    font-size: .6875rem;
    cursor: pointer;
    font-family: "Montserrat";
    letter-spacing: .025em;
    background: none;
    border: 0;
    float: none;
    color: $color-green;
    margin-right: 0;
    display: table;
    padding: rem-calc(6px) 0;
    text-decoration: none;

    &:first-child {
        padding-top: 0;
    }

    &:hover {
        color: $color-green;
        text-decoration: none;
    }

    &.secondary {
        color: $color-stone-grey;
    }
}
