/* COMPONENT Wettbewerb
##################################################################################################################### */

.wettbewerb__title {
    text-align: center;
    margin-bottom: rem-calc(38px);
    line-height: 1.2;
}

.wettbewerb__text {
    font-weight: bold;
}

.wettbewerb__answer {
    + .wettbewerb__form {
        padding-top: rem-calc(24px);
        margin-top: rem-calc(26px);
        border-top: 1px solid rgba(#999999, 0.5);
    }

    input[type="text"] {
        width: 100%;
        margin-bottom: rem-calc(10px);
    }

    .form-element__itemwrap {
        &+.form-element__itemwrap {
            margin-top: rem-calc(20px);
        }
    }
}
